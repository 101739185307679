// constants.js

export const UserInfo = {
    userKey: ""
  };
  
  export const BioInfo = {
    heightValue: 0,
    heightUnit: "in",
    weightValue: "",
    weightUnit: "lbs",
    ageValue: 0,
    genderValue: 'M'
  };
  
  export const LifestyleInfo = {
    activity: 1,
    variety: false
  };
  
  export const GoalsInfo = {
    goal: 0, // -3 to 3
    protein: 1, // 1 to 3
    macroBalance: 2 // 0 to 4
  };
  
  export const UserProfile = {
    UserInfo,
    BioInfo,
    LifestyleInfo,
    GoalsInfo
  };


export const ACTIVITY_LEVELS = {
    0: "Sedentary",
    1: "Low-Stress",
    2: "Moderate",
    3: "Active",
    4: "Extremely Active",
};

export const MEAL_PREFERENCES = {
    true: "Cook More",
    false: "Repetitive Meals",
};

export const goalLabels = [
    "Lose weight quickly",
    "Lose some weight",
    "Lose a bit of weight",
    "Maintain my weight",
    "Gain a bit of weight",
    "Gain some weight",
    "Gain weight quickly",
];

export const proteinLevels = {
    1: "Balanced",
    2: "Moderate",
    3: "Athletic",
};

export const macroBalances = {
    0: "Zero carbs",
    1: "Fewer carbs",
    2: "Balanced amount",
    3: "Fewer fats",
};



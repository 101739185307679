import React, {useState} from "react";
import { Form, Col, DropdownButton, Dropdown, Container, Row, Card } from "react-bootstrap";
import './onboard.css';
import Icon from "../tools/Icon";

function SegmentedOptions({ count, select, selected }) {
  return (
    <div style={{ display: 'flex', width: '90%' }} className = "ml-3 mr-3">
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
            height: "20px",
            flex: 1, // Evenly distribute space
            border: '1px solid black', // Optional styling for visibility
            textAlign: 'center', // Optional: Center content within each segment
            padding: '10px', // Optional: Adjust padding
            backgroundColor: index == selected ? "#90C1B0" : "whitesmoke",
            borderRadius: "4px"
          }}
          onClick = {() => select(index)}
        >
        </div>
      ))}
    </div>
  );
}

const secondaryProteinTextOptions = [
  "A reasonable amount of protein. Allows for a wider variety of meals",
  "Our recommended amount",
  "A serious commitment to a high-protein diet"
]

const secondaryActivityTextOptions = [
  "For people with office jobs or other professions that require no physical activity",
  "For people in professions that require minimal physical activity, such as teachers or librarians",
  "For people in professions that require some physical activity, such as chefs, nurses, or physical therapists",
  "For people in professions that require a good amount of physical activity, such as personal trainers, mail carriers, or farmers",
  "For people in challenging professions with strenuous demands on the body, such as athletes, military personnel, or physical laborers"
]

const Onboard_2 = ({ formState, setFormState, next, prev }) => {
  const { activity, variety } = formState.LifestyleInfo;

  let [goalText, setGoalText] = useState("Maintain my weight");
  let [selectedGoal, setSelectedGoal] = useState(3);

  let [selectedProtein, setSelectedProtein] = useState(1);
  let [selectedProteinText, setSelectedProteinText] = useState("Moderate");
  let [secondaryProteinText, setSecondaryProteinText] = useState(secondaryProteinTextOptions[1])

  let [selectedActivityLevel, setSelectedActivityLevel] = useState(0)
  let [selectedActivityLevelText, setSelectedActivityLevelText] = useState("Sedentary");
  let [secondaryActivityText, setSecondaryActivityText] = useState(secondaryActivityTextOptions[0]);

  function selectGoal(count){
    setSelectedGoal(count);
    setFormState({
      ...formState,
      GoalsInfo: { ...formState.GoalsInfo, goal: count - 3},
    });

    switch(count){
      case 0:
        setGoalText("Lose weight quickly");
        break;
      case 1:
        setGoalText("Lose weight");
        break;
      case 2:
        setGoalText("Lose weight slowly");
        break;
      case 3:
        setGoalText("Maintain my weight");
        break;
      case 4:
        setGoalText("Gain weight slowly");
        break;
      case 5:
        setGoalText("Gain weight");
        break;
      case 6:
        setGoalText("Gain weight quickly");
        break;
    }
    return;
  }

  function selectProtein(count){
    setSelectedProtein(count);
    setFormState({
      ...formState,
      GoalsInfo: { ...formState.GoalsInfo, protein: count},
    });

    switch(count){
      case 0: 
        setSelectedProteinText("Balanced")
        break;
      case 1:
        setSelectedProteinText("Moderate")
        break;
      case 2:
        setSelectedProteinText("Athletic")
        break;
    }
    setSecondaryProteinText(secondaryProteinTextOptions[count])
  }

  function selectActivityLevel(count){
    setSelectedActivityLevel(count);
    setFormState({
      ...formState,
      LifestyleInfo: { ...formState.GoalsInfo, activity: count},
    });

    switch(count){
      case 0: 
        setSelectedActivityLevelText("Sedentary")
        break;
      case 1:
        setSelectedActivityLevelText("Low Activity")
        break;
      case 2:
        setSelectedActivityLevelText("Moderately Active")
        break;
      case 3:
        setSelectedActivityLevelText("Active")
        break;
      case 4:
        setSelectedActivityLevelText("Extremely Active");
        break;
    }

    setSecondaryActivityText(secondaryActivityTextOptions[count]);

  }


  return (
    <Container fluid className="onboard-container" style = {{backgroundColor: "#212121", color: "whitesmoke", padding: 0, overflowX: "hidden"}}>
      <h1 className="text-center mb-4" style = {{color: "whitesmoke"}}><u>Your Goals</u></h1>
      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8} lg={6} className = "flex d-flex flex-column align-items-center">
          
          <SegmentedOptions count = {7} select = {(e) => selectGoal(e)} selected = {selectedGoal}/>
          <h1 className = "mt-3">{goalText}</h1>

          <h3 className = "mt-3" style = {{fontFamily: "Koulen", fontWeight: "normal"}}>Protein intake is vital, but can be challenging.</h3>
          <h3 style = {{fontFamily: "Koulen", fontWeight: "normal"}}> High-activity lifestyles require more protein than others do.</h3>
          <h1 className = "mt-4 text-center"><u>How much protein do you require in your diet?</u></h1>
          <SegmentedOptions count = {3} select = {(e) => selectProtein(e)} selected = {selectedProtein}/>
          <h1 className = "mt-3">{selectedProteinText}</h1>
          <h3 className = "text-center" style = {{fontFamily: "Koulen"}}>{secondaryProteinText}</h3>

          <h1 className = "mt-4"><u>Activity Level</u></h1>
          <SegmentedOptions count = {5} select = {(e) => selectActivityLevel(e)} selected = {selectedActivityLevel}/>
          <h1 className = "mt-3">{selectedActivityLevelText}</h1>
          <h3 className = "text-center" style = {{fontFamily: "Koulen", fontWeight: "normal"}}>{secondaryActivityText}</h3>

          <Row>
            <div className = "mt-3 mr-4 flex d-flex align-items-center justify-content-center">
              <Icon image = "next" size = "50px" click = {() => prev()} />
            </div>
            <div style = {{transform: "rotate(180deg)"}} className = "mt-3 ml-4 flex d-flex align-items-center justify-content-center">
              <Icon image = "next"  size = "50px" click = {() => next()} />
            </div>
          </Row>
          
        </Col>
      </Row>
    </Container>
  );
};

export default Onboard_2;

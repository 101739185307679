import { loadToken } from './SessionManager.js';

const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout exceeded')), ms));

export default async function executeCall(url, method, setError, body = null) {
    console.log(`Executing ${url} API Call...`);

    let token = loadToken();

    let response;
    const timeoutDuration = 10000; // 10 seconds timeout

    try {
        let fetchPromise;

        if (method === "GET") {
            fetchPromise = fetch(process.env.REACT_APP_API_URL + url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        } else if (method === "FILE") {
            const headers = {
                'Authorization': `Bearer ${token}`
            };

            fetchPromise = fetch(process.env.REACT_APP_API_URL + url, {
                method: "POST",
                headers,
                body: body
            });
        } else {
            fetchPromise = fetch(process.env.REACT_APP_API_URL + url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: body ? JSON.stringify(body) : null
            });
        }

        // Use Promise.race to race the fetch with a timeout
        if (!url.includes('autoschedule')) {
            response = await Promise.race([fetchPromise, timeout(timeoutDuration)]);
        } else {
            response = await fetchPromise;
        }

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        
        // Check if the response is JSON
        if (contentType && !contentType.includes('application/json')) {
            setError("A JSON Response was not returned from server!");
            return { error: "error" };
        }

        let data = await response.json();
        return data;

    } catch (error) {
        // Handle errors
        console.log("ERROR HANDLER WORKED");
        console.error('Fetch error:', error);  // Log the error for debugging
        setError(error.message || "A server error occurred!");
        return { error: "error" };
    }
}
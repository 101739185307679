import IngredientListView from '../Ingredient/IngredientListView.js'; // Assuming this is where your component is located.
import {Modal, Tabs, Tab, Button, ListGroup} from 'react-bootstrap';
import { useState, useContext, useEffect } from 'react';
import AttributeListView from '../Ingredient/AttributeListView.js';
import executeCall from '../utils/APICall.js';
import ErrorContext from '../utils/ErrorContext.js';

export default function RestrictionsModal({ userKey, show, close }) {
    const [activeTab, setActiveTab] = useState('ingredients'); // Manage the active tab state

    let {errorText, setErrorText} = useContext(ErrorContext);

    const [selectedIngredients, setSelectedIngredients] = useState(null);
    const [selectedAttributes, setSelectedAttributes] = useState(null);

    async function createRestrictions(){
        await executeCall(`users/addRestriction/${userKey}`, "POST", setErrorText, {ingredients: selectedIngredients, attributes: selectedAttributes});
        close();
    }

    async function getRestrictions(){
       let data = await executeCall(`users/getRestrictions/${userKey}`, "GET", setErrorText);
       console.log(data);
       setSelectedAttributes(data.attributes);
       setSelectedIngredients(data.ingredients);
    }

    useEffect(() => {
        getRestrictions();
    }, [show])

    if (show) {
        return (
            <div className = "p-0 ml-3 mr-3">
                        <Tabs
                            activeKey={activeTab}
                            onSelect={(k) => setActiveTab(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="ingredients" title="All Ingredients">
                                {/* You can add content specific to the Ingredients tab here */}
                            </Tab>
                            <Tab eventKey="attributes" title="All Attributes">
                                {/* You can add content specific to the Restrictions tab here */}
                            </Tab>
                            <Tab eventKey="restrictedIngredients" title="Restricted Ingredients">
                                {/* You can add content specific to the Ingredients tab here */}
                            </Tab>
                            <Tab eventKey="restrictedAttributes" title="Restricted Attributes">
                                {/* You can add content specific to the Ingredients tab here */}
                            </Tab>
                        </Tabs>

                        {activeTab === 'ingredients' && (
                            <IngredientListView 
                                changeIngredient={(e) => setSelectedIngredients(e)} 
                                allowChecked={true} 
                                setParentSelected={(e) => setSelectedIngredients(e)}
                                parentSelected={selectedIngredients}
                            />
                        )}
                        {activeTab === 'attributes' && (
                            <AttributeListView 
                                changeAttribute = {(e) => setSelectedAttributes(e)} 
                                allowChecked = {true} 
                                setParentSelected={(e) => setSelectedAttributes(e)}
                                parentSelected={selectedAttributes}
                            />
                        )}
                        {activeTab === 'restrictedIngredients' && (
                            <ListGroup>
                                {selectedIngredients.map((ingredient) => (
                                    <ListGroup.Item>
                                        {ingredient.ingredientName}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        {activeTab === 'restrictedAttributes' && (
                            <ListGroup>
                                {selectedAttributes.map((attribute) => (
                                    <ListGroup.Item>
                                        {attribute.attributeName}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        

            </div>
        );
    } else {
        return <></>;
    }
}
import React, { useEffect, useState, useContext } from "react";
import { Container, Button, ProgressBar } from "react-bootstrap";
import Onboard_BioInfo from "./Onboard_BioInfo";
import Onboard_GoalsInfo from "./Onboard_GoalsInfo";
import { initialFormData } from "./formObject";
import Onboard_Summary from "./Onboard_Summary";
import Onboard_Basics from "./Onboard_Signup";
import ChangeScheduleSettings from "../schedule/ChangeScheduleSettings";

import ErrorContext from "../utils/ErrorContext";
import executeCall from "../utils/APICall";

const Onboard_Main = ({ onComplete }) => {
  const totalSteps = 5;
  const [step, setStep] = useState(1);
  const [formState, setFormState] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState(''); // New state for error messages

  const progress = (step - 1) / (totalSteps - 1) * 100;

  const {errorText, setErrorText} = useContext(ErrorContext);

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      UserInfo: {
        ...prevState.UserInfo,
      }
    }));
  }, []);

  const validateUser = async () => {
    const { email, password } = formState.UserInfo;

    try {
      const response = await executeCall("validation/signupValidation", "POST", setErrorText, { email, password });
      if("error" in response) return;

      if (response.isValid) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage(response.message || 'Invalid email or password.');
        return false;
      }
    } catch (error) {
      console.error("Error validating user:", error);
      setErrorMessage('Error validating user. Please try again.');
      return false;
    }
  };

  const nextStep = async () => {
    if (step === 1) {
      const isValid = await validateUser();
      if (!isValid) return; // Stop if validation fails
    }
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      formState.BioInfo.heightValue = formState.BioInfo.heightValue + 57;
      const response = await executeCall("onboard/onboard", "POST", setErrorText, formState);
      if("error" in response) return;

      console.log("Submission successful", response);
      onComplete(formState);

    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error (e.g., show an error message)
    }
  };

  return (
    <Container fluid className="p-0 m-0" style={{ backgroundColor: "#212121", height: "100vh", overflowX: "hidden" }}>
      {/* Progress Section */}
      <Container fluid className="progress" style={{ backgroundColor: "#212121", padding: 0 }}>
      <div className = "pt-2 pb-2" style={{ position: "fixed", left: 0, right: 0, top: 0, textAlign: "center", zIndex: 1000, backgroundColor: "#212121" }}>
        <ProgressBar
          now={progress}
          label={`${Math.round(progress)}%`}
          variant="success"
          style={{ backgroundColor: "#212121", boxShadow: "none", border: "none" }}
          className="progress-bar-custom"
        />
      </div>
      </Container>

      {/* Display current step component */}
      <div className = "mt-4" style={{overflowY: "hidden" }}>
        {step === 1 && <Onboard_Basics setFormState={setFormState} formState={formState} errorMessage={errorMessage} next={nextStep} />}
        {step === 2 && <Onboard_BioInfo setFormState={setFormState} formState={formState} next={nextStep} prev={prevStep} />}
        {step === 3 && <Onboard_GoalsInfo setFormState={setFormState} formState={formState} next={nextStep} prev={prevStep} />}
        {(process.env.FEATURE_SCHEDULE && step == 4) && <ChangeScheduleSettings signup={true} next={nextStep} prev={prevStep} formState={formState} setFormState={setFormState} />}
        {(!process.env.FEATURE_SCHEDULE && step == 4) && <Onboard_Summary formState={formState} onSubmit={handleSubmit} prev={prevStep} />}
        {(process.env.FEATURE_SCHEDULE && step == 5) && <Onboard_Summary formState={formState} onSubmit={handleSubmit} prev={prevStep} />}
      </div>
    </Container>
      );
};

export default Onboard_Main;

import Icon from "../../tools/Icon";
import DayMealModel from "./DayMealModel";

export default function ManualScheduleSelector({dayCount, optState, optSet, close}){
    return (
        <div className = "flex d-flex flex-column align-items-center">
            <h1>Set Up a Custom Schedule</h1>
            <h3 style ={{fontFamily: "Koulen", fontWeight: "normal"}}>(You can change this later)</h3>
            <DayMealModel dayCount={dayCount} optState={optState} optSet = {(e) => optSet(e)}/>
            <Icon className = "mt-4 mb-4" image = "fillcheck" click = {close} />
        </div>
    )
}
import React, {useState, useEffect, useContext} from 'react';
import {Container} from 'react-bootstrap';

import SingleIngredient from './SingleIngredient';
import IngredientListView from './IngredientListView';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import { CustomModal } from '../Components/CustomModal';

// Ingredients Tab, lists ingreditents and searches

export default function EditIngredients({source, ingredientSelect = null}){
    const {errorText, setErrorText} = useContext(ErrorContext);


    let [selectedIngredientKey, setSelectedIngredientKey] = useState(null);
    let [ingredients, setIngredients] = useState(null);
    let [allAttributes, setAllAttributes] = useState(null);
   
    const width = (source === "homepage") ? 6 : 12;
    const select = (source === "homepage") ? setSelectedIngredientKey : ingredientSelect; 

    const loadIngredients = async (reload = false) => {
        const response = await executeCall("foods/ingredientList", "GET", setErrorText)
        let allIngredients = response.ingredients;
        allIngredients.sort((a, b) => {
            // Convert both names to lowercase for case-insensitive comparison
            const nameA = a.ingredientName.toLowerCase();
            const nameB = b.ingredientName.toLowerCase();
        
            if (nameA < nameB) {
                return -1; // Name A comes before Name B
            } else if (nameA > nameB) {
                return 1; // Name B comes before Name A
            } else {
                return 0; // Names are equal
            }
        });
        setIngredients(allIngredients);

        setAllAttributes(response.attributes);

        if(reload){
            setSelectedIngredientKey(null)
        }
    }

    useEffect(() => {
        loadIngredients();
    }, [source]);    

    return (
        <Container style={{background:"#212121", height: '90vh'}} fluid>
            {/*Filter*/}
            <IngredientListView ingredients={ingredients} changeIngredient={select}/>
            {(source == "homepage" && selectedIngredientKey != null) && (
                <CustomModal modalBody = { 
                    <SingleIngredient 
                        ingredientKey = {selectedIngredientKey} 
                        loadIngredients={() => loadIngredients(true)} 
                        attributes = {allAttributes} />} 
                    close = {() => setSelectedIngredientKey(null)}>
                </CustomModal>
            )}
        </Container>
    )
}
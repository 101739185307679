export default function Icon({image, click, className = "", size = "20px"}){
    return (
        <img 
        src = {process.env.PUBLIC_URL + `/images/${image}.png`} 
        alt = {image}
        style = {{width: size, height: size, color: "whitesmoke"}} 
        onClick = {(e) => click(e)}
        className = {className}></img>
    )
}

import { useState } from "react";
import { CloseButton } from "react-bootstrap";

export default function ListPortion({title, click, selected, removePortion, editing = false}){

    if(selected){
        return (
            <div style = {{height: "35px",  borderRadius: "50px", backgroundColor: "#000000", border: "2px solid white", color: "whitesmoke", fontFamily: "Koulen", position: "relative"}}
                className = "p-3 m-3 flex d-flex align-items-center justify-content-center"
                onClick = {() => click()}>
                <u>{title}</u>
                {editing && (
                   <div
                   style={{
                     position: "absolute",
                     top: "-5px",
                     right: "-5px",
                     width: "20px",
                     height: "20px",
                     borderRadius: "50%",
                     backgroundColor: "red",
                     color: "white",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     fontSize: "12px",
                     fontWeight: "bold",
                     cursor: "pointer",
                   }}
                   onClick={(e) => {e.stopPropagation(); removePortion()}}>
                   ×
                 </div>
                )}
            </div>
        )
    } else {
        return (
            <div
                style={{
                height: "35px",
                borderRadius: "50px",
                backgroundColor: "whitesmoke",
                fontFamily: "Koulen",
                position: "relative",
                }}
                className="p-3 m-3 d-flex align-items-center justify-content-center"
                onClick={() => click()}
            >
            <u>{title}</u>
            {editing && (
              <div
              style={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={(e) => {e.stopPropagation(); removePortion()}}>
              ×
            </div>
            )}
          </div>
        )
    }

}
import { useEffect } from "react";
import { Row } from "react-bootstrap";

export default function DayOptions({dayCount, type, question, optState, optSet, template = null, beginDay = null}){

    useEffect(() => {
        console.log(optState);
    }, [optState]);

    function select(i){
        if(type === "cheat"){
            let mealArray = optState.split("|") ?? []
            if(mealArray[i]==="X")
                mealArray[i] = template;
            else
                mealArray[i] = "X";
            optSet(mealArray.join('|'));
        } else if (type === "exception"){
            let newState = "";
            console.log(optState);
            if(optState[i] === '0')
                newState = optState.slice(0, i) + '1' + optState.slice(i + 1);
            else
                newState = optState.slice(0, i) + '0' + optState.slice(i + 1);
            optSet(newState);
        }

    }

    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return (
        <div className = "d-flex flex-column align-items-center">
            <h1 className = "mt-4" style = {{fontFamily: "Koulen"}}>{question}</h1>
            {optState != null && (
                <Row className = "d-flex align-items-center">
                    {Array.from({ length: dayCount }, (_, i) => {
                        let day = ""
                        if(type === "cheat")
                            day = (optState.split("|") ?? [])[i];
                        else if (type = "exception")
                            day = optState[i] === "1" ? "X" : 0;

                        let border = "2px solid whitesmoke"
                        if(day === "X"){
                            border = "2px solid #90C1B0";
                        }
                        return (
                            <div key={i} 
                            className = "ml-3 mr-3 d-flex align-items-center justify-content-center p-3" 
                            onClick = {() => select(i)}
                            style = {{height: "50px", border: border, borderRadius: "5px"}}>
                                {beginDay == null ? `Day ${i + 1}` : allDays[(allDays.indexOf(beginDay) + i + 1) % allDays.length]}
                            </div>
                        );
                    })}
                </Row>
            )}

        </div>

    )
}

import { ListGroup, Container, Spinner } from "react-bootstrap";
import executeCall from "../utils/APICall";
import {useEffect, useState, useContext} from 'react';
import ErrorContext from '../utils/ErrorContext';
import Icon from "../tools/Icon";
import moment from "moment";
import { Link } from "react-router-dom";
import PathConstants from "../tools/PathConstants";
import ScheduleList from '../schedule/ScheduleList';

export default function ShoppingList({userKey, shoppingListKey = null}){
    const [list, setList] = useState([]);
    const {errorText, setErrorText} = useContext(ErrorContext);
    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(false);

    const [currentScheduleKey, setCurrentScheduleKey] = useState(null);

    async function getList(){
        setLoading(true);
        let response;
        if(currentScheduleKey == null)
            response = await executeCall(`shopping/latest/${userKey}`, "GET", setErrorText);
        else
            response = await executeCall(`shopping/list/${currentScheduleKey}`, "GET", setErrorText);
        console.log(response.date);
        let newDate = response.date;
        console.log(newDate);
        setDate(newDate);
        console.log(response.ingredients);
        setList(response.ingredients);
        setLoading(false);
    }

    useEffect(() => {
        getList();
    }, [userKey])

    useEffect(() => {
        getList();
    }, [currentScheduleKey]);
    
    return (
        <Container className = "flex d-flex align-items-center justify-content-center flex-column" style = {{minHeight: "400px"}}>
            {loading && <Spinner variant="light"/>}
            {!loading && (
                <>
                    <h1 className = "mt-3" style = {{color: "whitesmoke"}}>Shopping List - {moment(date).format("MMMM D, yyyy")}</h1>
                    <ListGroup className = "mb-4">
                        {(list != null && list.length > 0) && 
                            list.map((item) => {
                                return (
                                    <ListGroup.Item className = "mt-3" style = {{border: '1px solid black', boxShadow: '1px 1px 1px 1px'}}>
                                        <div><b>{item.portionAmount} {item.Portion.amountTitle}</b>: {item.Ingredient.ingredientName}</div>
                                    </ListGroup.Item>
                                )
                            })

                        }
                    </ListGroup>
                {/*<ScheduleList userKey = {userKey} setSchedule = {(e) => setCurrentScheduleKey(e)} />*/}
                </>

            )}

        </Container>
    );
}
export const saveSession = (userKey, token) => {
    localStorage.setItem('userKey', userKey);
    localStorage.setItem('userToken', token);
};

export const loadSession = () => {
    const userKey = localStorage.getItem('userKey');
    const userToken = localStorage.getItem('userToken');
    return { userKey, userToken };
};

export const loadUserKey = () => {
    const userKey = localStorage.getItem('userKey');
    return { userKey };
};


export const loadToken = () => {
    const userToken = localStorage.getItem('userToken');
    return userToken;
}

export const clearSession = () => {
    localStorage.removeItem('userKey');
    localStorage.removeItem('userToken');
};

import { useState, useEffect, useContext } from 'react';
import {Row, Form, ListGroup} from 'react-bootstrap'
import Icon from '../tools/Icon';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';

async function getIngredient(ingredientKey, setErrorText){
    const response = await executeCall(`foods/ingredientByKey/${ingredientKey}`, "GET", setErrorText)
    if("error" in response){ return; }

    return {"name": response.ingredient.ingredientName, "allPortions": response.portions, "nutrients": response.nutrients, "portion": null, "ingredientKey": ingredientKey};
}

function roundToTwoDecimals(num) {
    if (Number.isInteger(num)) {
        return num; // Return the number as is if it's already a whole number
    } else {
        return Math.round(num * 100) / 100; // Round to two decimal places otherwise
    }
}

export default function Ingredient({selected, ingredient, updateIngredient, removeIngredient, setSelectedIngredient, viewThisIngredient, editing}){

    const {errorText, setErrorText} = useContext(ErrorContext);

    const [ingredientAmount, setIngredientAmount] = useState(ingredient.amount);
    const [ingredientPortion, setIngredientPortion] = useState(ingredient.portion ?? "");
    const [allIngredientPortions, setAllIngredientPortions] = useState(null);

    const select = async (ingredientKey, event = null) => {
        setSelectedIngredient(ingredientKey);
        const response = await getIngredient(ingredientKey, setErrorText);
        setAllIngredientPortions(response.allPortions);
        viewIngredient();
    };

    const viewIngredient = (foodNutrients = null) => {
          const floatObject = {}; 
          for (const [key, value] of Object.entries(foodNutrients ?? ingredient.nutrients)) {
            floatObject[key] = parseFloat(value);
          }
        //setViewingFood(floatObject);
    }

    useEffect(() => {
        if(selected)
            select(ingredient.ingredientKey);
    }, [ingredient]);
    
    useEffect(() => {
        if(ingredientAmount != null && ingredientAmount != 0 && ingredientPortion != null && ingredientPortion != "" && allIngredientPortions != null){
            const floatObject = {}; 
            let thisPortion = allIngredientPortions.find(item => item.amountTitle == ingredientPortion)
            for (const [key, value] of Object.entries(ingredient.nutrients)) {
                floatObject[key] = parseFloat(value) * (thisPortion.grams / (ingredient.portionGrams ?? 100)) * (ingredientAmount / (ingredient.amount ?? 1));
            }
            //setViewingFood(floatObject);
        }
    }, [ingredientAmount, ingredientPortion]);

    const calculateNutrients = () => {
        let floatObject = {};
        if(ingredientAmount != null && ingredientAmount != 0 && ingredientPortion != null && ingredientPortion != "" && allIngredientPortions != null){
            let thisPortion = allIngredientPortions.find(item => item.amountTitle == ingredientPortion)
            for (const [key, value] of Object.entries(ingredient.nutrients)) {
                floatObject[key] = parseFloat(value) * (thisPortion.grams / (ingredient.portionGrams ?? 100)) * (ingredientAmount / (ingredient.amount ?? 1));
            }
            //setViewingFood(floatObject);
        }
        return floatObject;
    }

    const prepareIngredient = (event) => {
        event.stopPropagation();
        let currentPortion = allIngredientPortions?.find(item => item.amountTitle === ingredientPortion);
        let update = {
            "name": ingredient.name,
            "ingredientKey": ingredient.ingredientKey,
            "ingredientName": ingredient.name,
            "amount": ingredientAmount,
            "portion": currentPortion?.name,
            "portionKey": currentPortion?.portionKey,
            "portionGrams": currentPortion?.grams,
            "nutrients": calculateNutrients()
        }
        updateIngredient(update);
        setSelectedIngredient(null);
    }

    const formatNumber = (number) => {
        let formattedNumber = number.toFixed(2); // Format with two decimal places

        // Remove decimal point and trailing zeros if the number is an integer
        if (Number.isInteger(number)) {
            formattedNumber = formattedNumber.replace(/\.0{1,2}$/, '');
        }
        return formattedNumber;
    }

    return (
        <Row className = "d-flex">
            <ListGroup.Item className = "flex-grow-1 d-flex align-items-center" 
            style = {{border: "2px solid black", marginBottom: "5px", boxShadow: "0px 4px 4px 2px rgba(255, 255, 255, 0.3)", backgroundColor: selected ? "#e0d59d" : "whitesmoke", fontFamily: "Koulen", position: "relative"}}
            onClick={() => {if(!selected) viewThisIngredient()}}
            >
                {!selected && (
                    <Row>
                        <p style={{fontSize: "20px", fontFamily: "Koulen"}}>
                            {roundToTwoDecimals(ingredient.amount) + " " + ingredientPortion.toLowerCase() + "\t " + ingredient.name.toLowerCase() + (ingredient.amount != null ? (" (" + formatNumber(ingredient.amount * ingredient.portionGrams) + "g)") : "")}
                        </p>
                    </Row>
                    
                )}
                {selected && (
                    <div style={{fontSize: "20px", marginLeft: "10px"}} className = "flex d-flex flex-direction-row align-items center">
                        <Form.Control
                            type="text"
                            style={{ width: "20%", marginRight: "10px" }}
                            value={ingredientAmount}
                            onChange={(e) => setIngredientAmount(e.target.value)}
                            onClick={(e) => e.stopPropagation()} // Stop propagation on click
                            autoComplete="off"
                        />
                        <Form.Control
                            as="select"
                            onChange= {(e) => setIngredientPortion(e.target.value, e.target.selectedOptions[0].getAttribute('data-grams'))}
                            value = {ingredientPortion}
                            style={{ marginRight: "10px", width: '40%' }}
                            onClick={(e) => e.stopPropagation()}
                            >
                                <option value = ""></option>
                                {/* Assuming availableIngredients is an array of available attributes */}
                                {(allIngredientPortions != null) && allIngredientPortions.map((portion, index) => (
                                    <option key = {portion.portionKey} data-grams={portion.grams}>{portion.amountTitle}</option>
                                ))}
                        </Form.Control>
                        {ingredient?.name.toLowerCase()}
                    </div>
                )}
                <div style={{position: "absolute", bottom: 0, left: 0, right: 0, height: "3px", backgroundColor: "#3779BB"}}></div>
                {editing && 
                    <div style = {{marginLeft: "auto", marginRight: "0px"}} className = "flex d-flex flex-direction-row">
                        {!selected && 
                            <Icon image = "edit" className = "mt-0 ml-1" click = {(event) => {event.stopPropagation(); select(ingredient.ingredientKey)}} />
                        }
                        {selected && <Icon image = "check" className = "mt-0 ml-1" click = {(event) => prepareIngredient(event)} />}
                        <Icon image = "remove" className = "mt-0 ml-1" click = {(event) => {event.stopPropagation(); removeIngredient(ingredient.ingredientKey)}} />
                    </div>
                }
                </ListGroup.Item>

        </Row>
    )
}
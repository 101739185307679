import {Container, Row, Form, Button, Col, Image} from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';

import Icon from '../tools/Icon';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import { heicTo } from "heic-to"

export default function Recipe({mealKey, reset = null, recipeKey = null}){
    const {errorText, setErrorText} = useContext(ErrorContext);

    const [photoData, setPhotoData] = useState(null);
    const [photoDataURL, setPhotoDataURL] = useState(null);
    const [photoAdded, setPhotoAdded] = useState(false);  


    const [recipe, setRecipe] = useState("");

    const [editing, setEditing] = useState(false);
    const [updatedRecipeKey, setUpdatedRecipeKey] = useState(null);

    const loadRecipe = async(inputRecipeKey = null) => {
        const response = await executeCall(`foods/recipe/${inputRecipeKey ?? recipeKey}`, "GET", setErrorText)
        if("error" in response) { return; }
        setRecipe(response.recipe.recipeText);
        if("recipeImage" in response){
            setPhotoData(response.recipeImage);
            console.log("got photo data");
        }
        setUpdatedRecipeKey(response.recipe.recipeKey);
    }

    useEffect(() => {
        if(recipeKey != null)
            loadRecipe();
    }, [recipeKey])


    const saveRecipe = async (event) => {
        setPhotoDataURL(null);
        event.preventDefault();
        if(recipe == "" && photoData == null){
            setErrorText("Please enter some text for your recipe!");
            return;
        }
        const response = await executeCall(`foods/updateRecipe/${mealKey}/${recipeKey ?? "new"}`, "POST", setErrorText, {"recipeText": recipe})
        if("error" in response) { return; }

        if(response.recipe != null && photoData != null && photoAdded){
            const formData = new FormData();
            formData.append('recipeImage', photoData);
    
            await executeCall(`foods/addRecipeImage/${response.recipe}`, "FILE", setErrorText, formData);
        }

        await loadRecipe(response.recipe);
        setUpdatedRecipeKey(response.recipe);
        setEditing(false);
        setPhotoAdded(false);
    }

    const deleteRecipe = async () => {
        const response = await executeCall(`foods/deleteRecipe/${recipeKey}`, "DELETE", setErrorText);
        if("error" in response) { return; }
        setRecipe("");
        reset();
    }


    const handlePhotoUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        let jpeg = file;
        // Check if the file is a HEIC image
        if (file.type === "image/heic" || file.name.endsWith(".HEIC")) {
            try {
                jpeg = await heicTo({
                    blob: file,
                    type: "image/jpeg",
                    quality: 0.5
                  });
            } catch (error) {
                console.error("HEIC conversion error:", error);
                return;
            }
        }
    
        const fileURL = URL.createObjectURL(jpeg);
        console.log(fileURL);
        setPhotoDataURL(fileURL);
        setPhotoData(jpeg);
        setPhotoAdded(true);
    };

    const triggerFileInput = () => {
        document.getElementById('recipePictureUpload').click();
    };

    return (
        <Container style={{ minHeight: "400px", width: "100%" }} className = "flex d-flex justify-content-center flex-column" fluid>
            <Row>
                {(editing && recipeKey != null) && <Button variant="danger" className="ml-auto mt-2 mb-2" onClick={() => deleteRecipe()}>Delete Recipe</Button>}
            </Row>
            <input
                type="file"
                id="recipePictureUpload"
                className="hidden-file-input"
                onChange={handlePhotoUpload}
                accept="image/*"
            />

            {photoData != null && (
                <>
                    <div className="d-flex justify-content-end">
                        <Icon size={50} image="lightedit" className="mb-2 mt-2" click={() => setEditing(!editing)} />
                    </div>
                    <div>
                        <Image src={photoDataURL ?? photoData}
                            style={{ maxWidth: "80%", marginLeft: "10%", maxHeight: "400px", objectFit: "contain", margin: 0 }}
                            className="img-circle mb-4 mt-4"
                        />
                    </div>
                </>
            )}
            {(recipeKey != null && !editing && photoData == null) && (
                <div className="d-flex justify-content-end">
                    <Icon size={50} image="lightedit" className="mb-2 mt-2" click={() => setEditing(!editing)} />
                </div>
            )}

            {(!editing && mealKey != null && recipe != "") && (
                <p style = {{fontFamily: "Koulen", color: "whitesmoke", whiteSpace: "pre-wrap"}}className = "text-center">{recipe}</p>
            )}
            {
                ((recipeKey != null && !editing && mealKey != null && recipe == "" && updatedRecipeKey == null) || (recipeKey == null && updatedRecipeKey == null)) && photoData == null && (
                    <Row className="flex d-flex align-items-center justify-content-center" style={{ fontFamily: "Koulen", color: "whitesmoke" }}>
                        <Col className="flex d-flex align-items-center justify-content-center flex-column">
                            <h1 style={{ fontFamily: "Koulen", color: "whitesmoke" }}>No recipe has been created for this meal yet.</h1>
                            <Row style={{ marginTop: "90px" }}>
                                <Col className="d-flex flex-column align-items-center">
                                <div className="box d-flex flex-column align-items-center">
                                    <Icon size={50} image="lightedit" className="mb-2" click={() => setEditing(true)} />
                                    <h1 className = "text-center">Type or copy/paste a recipe</h1>
                                </div>
                            </Col>
                            <Col className="d-flex flex-column align-items-center">
                                <div className= "d-flex flex-column justify-content-center align-items-center">
                                    <Icon size={50} image="photo" className="mb-2" click = {() => triggerFileInput()}/>
                                    <h1 className = "text-center">Upload a photo of your recipe</h1>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) 
            }
            {(editing || mealKey == null || mealKey == false) && (
                <Form onSubmit = {(e) => saveRecipe(e)}>

                    <Form.Control
                        as="textarea"
                        placeholder=""
                        value = {recipe}
                        autoComplete="off"
                        rows={4}
                        onChange = {(e) => setRecipe(e.target.value)}
                    />
                </Form>
            )}



            {/* {photoSelected && (
                <Form.Control className = "mb-4"  type="file" onChange={(e) => handlePhotoUpload(e)} />
            )} */}

            {(photoAdded || editing) && (
                <Button onClick = {(e) => saveRecipe(e)}>Save Recipe</Button>
            )}

        </Container>
    )

}
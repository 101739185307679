import { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loadUserKey } from '../utils/SessionManager.js';
import ErrorContext from "../utils/ErrorContext";
import executeCall from "../utils/APICall";
import PathConstants from "../tools/PathConstants.js";
import WelcomeScreenDonutChart, { DonutChart } from "../dashboard/WelcomeScreenDonutChart.js";
import SessionContext from "../utils/SessionContext.js";
import PrimaryButton from "../Components/PrimaryButton.js";
import { ListMeal } from "../Components/ListMeal.js";
import { CustomModal } from "../Components/CustomModal.js";
import SingleMealDetail from "../meal/SingleMealDetail.js";
import Icon from "../tools/Icon.js";
import RecommendedMeals from "./RecommendedMeals.js";
import WelcomePFPModal from "./WelcomePFPModal.js";
import ShoppingList from "../shopping/ShoppingList.js";

const NutritionalText = ({ goals, donutChartValues }) => {
    return (
        <div style = {{color: "whitesmoke"}}>
            <WelcomeScreenDonutChart donutChart={donutChartValues} />
            <p className="mt-5">{goals.Nutrient.carbohydrates}g of carbohydrates, {goals.Nutrient.totalfats}g of fat, and {goals.Nutrient.protein}g of protein</p>
            <h2 >{goals.Nutrient.calories} Calories</h2>
        </div>
    );
}

export default function Welcome({ userKey, logout, setTopLevelProfilePicture }) {
    const {goals, setGoals, updateProfilePicture, displayWelcomeMessage, setDisplayWelcomeMessage} = useContext(SessionContext);
    const [donutChartValues, setDonutChartValues] = useState(DonutChart);
    const [username, setUsername] = useState('');
    const { errorText, setErrorText } = useContext(ErrorContext);

    const [PFPModalOpen, setPFPModalOpen] = useState(displayWelcomeMessage)

    const [dayMeals, setDayMeals] = useState(null);
    const [currentDayKey, setCurrentDayKey] = useState(null);
    const [dayDisplayText, setDayDisplayText] = useState(null);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [selectedMealKey, setSelectedMealKey] = useState(null);

    const [shoppingListKey, setShoppingListKey] = useState(null);
    const [shoppingModal, setShoppingModal] = useState(false);

    const [recommendedMealsSelected, setRecommendedMealsSelected] = useState(false);

    async function loadDayMeals(){
        const response = await executeCall(`plans/currentSchedule/${userKey}`, "GET", setErrorText);
        if(response.message == "NOSCHEDULE"){
            if(response.shoppingList != null)
                setShoppingListKey(response.shoppingList)
        } else {
            setDayMeals(response.currentDayMeals);
            setCurrentDayKey(response.currentDayKey);
            setCurrentSchedule(response.days);
            setShoppingListKey(response.shoppingList);
        }
    }

    async function planSchedule() {
        const response = await executeCall(`plans/autoSchedule/${userKey}`, "GET", setErrorText);
        if(response.message == "Success"){
            loadDayMeals();
        }
    }

    useEffect(() => {
        if(currentSchedule == null) return;
        let newDay = currentSchedule?.find(day => day.dayKey == currentDayKey);
        setDayMeals(newDay?.DayMeals);
        if (newDay?.date) {
            const date = new Date(newDay.date);
            const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            setDayDisplayText(formattedDate);
        }        
        console.log(newDay);
    }, [currentDayKey])

    useEffect(() => {
        const getGoal = async () => {
            const response = await executeCall(`users/currentGoal/${userKey}`, "GET", setErrorText);

            if (response.status == 403 || "error" in response) {
                logout();
                return;
            }

            await loadDayMeals();

            setGoals(response.goals);

            // Fetch profile data to get profile picture
            const profileResponse = await executeCall(`users/profile/${userKey}`, "GET", setErrorText);
            if ("error" in profileResponse) return;

            const profile = profileResponse.profile[0];

            if (profile && profile.ProfileImage && profile.ProfileImage.cloudinaryUrl) {
                updateProfilePicture(profile.ProfileImage.cloudinaryUrl);
            } else {
                updateProfilePicture(process.env.PUBLIC_URL + '/images/placeholder_profile.png');
            }
        };

        const getDonutValues = async () => {

            const response = await executeCall(`users/getDonutValues/${userKey}`, "GET", setErrorText);
            if (response == null || response.status == 403 || "error" in response) {
                return;
            }
            setDonutChartValues(response);
        };



        if (userKey) {
            getGoal();
            getDonutValues();
        }
    }, [userKey]);

    return (
        <Container className="flex d-flex flex-column" style={{backgroundColor: "#212121", paddingBottom: "70px"}} fluid scrollable>
            <Row className="flex d-flex align-items-center justify-content-center mt-3" style={{backgroundColor: "#212121"}}>
                {currentSchedule && currentSchedule.findIndex(day => day.dayKey == currentDayKey) > 0 ? (
                    <Icon image="next" size={40} className="ml-3"
                    click={() => setCurrentDayKey(currentSchedule[currentSchedule.findIndex(day => day.dayKey == currentDayKey) - 1].dayKey)}/>
                ) : (
                    <div className = "ml-3" style={{ width: 40, height: 40 }}></div>
                )}
                <h1 className="mx-auto" style={{color: "whitesmoke"}}>{dayDisplayText}</h1>
                <div style={{ transform: "rotate(180deg)" }}>
                    {currentSchedule && currentSchedule.findIndex(day => day.dayKey == currentDayKey) < currentSchedule.length - 1 ? (
                        <Icon
                            image="next"
                            size={40}
                            className="ml-3"
                            style={{ transform: "rotate(180deg)" }}
                            click={() => setCurrentDayKey(currentSchedule[currentSchedule.findIndex(day => day.dayKey == currentDayKey) + 1].dayKey)}
                        />
                    ) : (
                        <div className = "mr-3" style={{ width: 40, height: 40 }}></div>
                    )}
                </div>
            </Row>
            <Row style = {{backgroundColor :"212121", height: "90%" }}>
                <Col style={{ textAlign: 'center', backgroundColor: "#212121"}} xs={12}>

                    <h1 className="mt-4 mt-4" style = {{color: "whitesmoke"}}>Goals</h1>
                    <div className="d-grid gap-3">
                        {(goals != null && "Nutrient" in goals && goals.Nutrient != null) && <NutritionalText goals={goals} donutChartValues={donutChartValues} />}
                    </div>
                    <Row className = "flex d-flex justify-content-center mt-4">
                        <Col>
                            {dayMeals == null && process.env.FEATURE_SCHEDULE && (
                                <div>
                                    <h3 className = "mt-4" style = {{color: "whitesmoke", fontFamily: "Koulen", fontWeight: "normal"}}>You do not have today planned in a schedule.</h3>
                                    <PrimaryButton className = "mt-4" text="PLAN A SCHEDULE" onClick = {() => planSchedule()} />           
                                </div>
                            )}
                            {(dayMeals == null && !process.env.FEATURE_SCHEDULE) && (
                                <PrimaryButton className = "mt-4" text="SEE YOUR RECOMMENDED MEALS" onClick = {() => setRecommendedMealsSelected(true)}/>                     
                            )}
                            {shoppingListKey != null && (
                                <PrimaryButton className = "mt-4" text="SHOPPING LISTS" onClick = {() => setShoppingModal(true)}/>                     
                            )}
                        </Col>
                    </Row>


                    {dayMeals != null && (
                        <Row>
                            <Col xs = {0} md = {3}>
                            
                            </Col>
                            <Col xs = {12} md = {6} className = "mt-4 mb-4">
                                {dayMeals.map((meal, index) => ( 
                                    <ListMeal meal = {meal.Meal} index = {index} setActive={(e) => setSelectedMealKey(e)} alternateServings={meal.servings}/>
                                ))}
                            </Col>

                        </Row>
                    )}

                    {selectedMealKey != null && 
                        <CustomModal title = {selectedMealKey} close = {() => setSelectedMealKey(null)} modalBody = {
                            <SingleMealDetail userKey = {userKey} mealKey = {selectedMealKey} goals = {goals}/>
                        } />
                    }
                    {recommendedMealsSelected && (
                        <CustomModal title = {selectedMealKey} close = {() => setRecommendedMealsSelected(false)} modalBody = {
                            <RecommendedMeals userKey = {userKey} close = {() => setRecommendedMealsSelected(false)} reload = {loadDayMeals} />
                        } />
                    )}
                    {PFPModalOpen &&
                        <CustomModal close = {() => {setPFPModalOpen(false); setDisplayWelcomeMessage(false)}} modalBody = {
                            <WelcomePFPModal userKey = {userKey} close = {() => {setPFPModalOpen(false); setDisplayWelcomeMessage(false)}}/>
                        }/>
                    }
                    {(shoppingListKey != null && shoppingModal) &&
                        <CustomModal close = {() => setShoppingModal(false)}
                            modalBody = {<ShoppingList userKey = {userKey} shoppingListKey={shoppingListKey}/>}
                        />
                    }



                </Col>
            </Row>
        </Container>
    );
}

import { Doughnut } from 'react-chartjs-2';
import "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import { fontString } from 'chart.js/helpers';

// Register the plugin
Chart.register(ChartDataLabels);

const WelcomeScreenDonutChart = ({ donutChart }) => {
    const data = {
        labels: ['Carbs', 'Fats', 'Protein'],
        datasets: [
            {
                data: donutChart
                    ? [donutChart.carbohydrates, donutChart.totalfats, donutChart.protein]
                    : [33, 33, 33], // Placeholder values if no data is available
                backgroundColor: ['#373737', '#373737', '#373737'],
                borderColor: ['#AF7C59', '#FFD300', '#229622'],
                borderWidth: 3, // Adjust the border width as needed
                color: "whitesmoke"
            },
        ],
    };

    const options = {
        animation: {
            animateScale: true,
            duration: 2000,
            easing: "easeOutCubic",
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top', // or 'left', 'right', 'bottom'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.raw + 'g';
                        return label;
                    },
                },
            },
            datalabels: {
                color: 'whitesmoke',
                font: {
                    family: "Koulen",
                    size: 14,
                },
                formatter: (value, context) => {
                    return context.chart.data.labels[context.dataIndex];
                }
            }
        },
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px', width: '300px', margin: 'auto' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export const DonutChart = {
    carbs: 33,
    fats: 33,
    protein: 33
};

export default WelcomeScreenDonutChart;
import {Modal, Button} from 'react-bootstrap';
import MealList from '../../meal/MealList';

export default function MealSelectModal({ token, userKey, close, addMeal }) {
    return (
        <div className="modal show" style={{ display: 'block' }}>
            <Modal.Dialog style={{ maxWidth: '80vw' }}>
                <Modal.Header>
                    <Modal.Title>Add a Meal</Modal.Title>
                    <img style = {{height: "20px", marginTop: "10px", marginLeft: "10px", float: "right"}}
                        alt = "x" 
                        src={process.env.PUBLIC_URL + "/images/x.png"} onClick={() => close()}/>
                </Modal.Header>

                <Modal.Body>
                    <MealList token = {token} 
                        userKey = {userKey} 
                        mode = "add" 
                        select = {(key, servings, name) => {addMeal(key, servings, name); close()}}
                        evaluate = {true}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => close()}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
}
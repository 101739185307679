import React, { useState, useEffect, useRef } from 'react';

const LandingAnimation = () => {
  // Sections with adjusted speeds and initial positions
  // We can use these values to adjust speeds and finetune the animation
  const [sections, setSections] = useState([
    { id: 1, startAngle: 0, color: '#90C1B0', speed: 1.6 },     // Section 1
    { id: 2, startAngle: 160, color: '#383838', speed: 1.4 },     // Section 2
    { id: 3, startAngle: 280, color: '#3C97F2', speed: 1.2},   // Section 3
  ]);

  const requestRef = useRef();

  useEffect(() => {
    const animate = () => {
      setSections((prevSections) => {
        const updatedSections = prevSections.map((section) => ({
          ...section,
          startAngle: (section.startAngle + section.speed) % 360,
        }));

        const sortedSections = [...updatedSections].sort(
          (a, b) => a.startAngle - b.startAngle
        );

        // We can change this value to set the angle in which the transition (speed swap) occurs
        const minAngleDiff = 60; // Increased minimum angular difference.
        
        let speedsRotated = false;

        for (let i = 0; i < sortedSections.length; i++) {
          const currentSection = sortedSections[i];
          const nextSection = sortedSections[(i + 1) % sortedSections.length];

          let angleDiff = (nextSection.startAngle - currentSection.startAngle + 360) % 360;

          if (angleDiff < minAngleDiff) {
            nextSection.startAngle = (currentSection.startAngle + minAngleDiff) % 360;

            // Rotate speeds
            const speeds = sortedSections.map((s) => s.speed);
            const newSpeeds = [speeds[2], speeds[0], speeds[1]];

            for (let j = 0; j < sortedSections.length; j++) {
              sortedSections[j].speed = newSpeeds[j];
            }

            speedsRotated = true;
            break; // Only need to rotate once per frame
          }
        }

        return speedsRotated ? sortedSections : updatedSections;
      });

      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const sortedSections = [...sections].sort(
    (a, b) => a.startAngle - b.startAngle
  );

  const sectors = sortedSections.map((section, index) => {
    const startAngle = section.startAngle % 360;
    const nextSection = sortedSections[(index + 1) % sortedSections.length];
    let endAngle = nextSection.startAngle % 360;

    if (endAngle <= startAngle) {
      endAngle += 360;
    }

    return {
      startAngle,
      endAngle,
      color: section.color,
    };
  });

  // Function to create donut sector paths
  const describeDonutSector = (x, y, innerRadius, outerRadius, startAngle, endAngle) => {
    const startOuter = polarToCartesian(x, y, outerRadius, endAngle);
    const endOuter = polarToCartesian(x, y, outerRadius, startAngle);
    const startInner = polarToCartesian(x, y, innerRadius, endAngle);
    const endInner = polarToCartesian(x, y, innerRadius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M', startOuter.x, startOuter.y,
      'A', outerRadius, outerRadius, 0, largeArcFlag, 0, endOuter.x, endOuter.y,
      'L', endInner.x, endInner.y,
      'A', innerRadius, innerRadius, 0, largeArcFlag, 1, startInner.x, startInner.y,
      'Z'
    ].join(' ');

    return d;
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const centerX = 100;
  const centerY = 100;
  const outerRadius = 90;
  const innerRadius = 50; // Adjust this to change the hole size


  return (
    <svg width="200" height="200" viewBox="0 0 200 200">
      {sectors.map((sector, index) => (
        <path
          key={index}
          d={describeDonutSector(
            centerX,
            centerY,
            innerRadius,
            outerRadius,
            sector.startAngle,
            sector.endAngle
          )}
          fill={sector.color}
        />
      ))}
    </svg>
  );
};

export default LandingAnimation;

import {Row, Col, Container, Button} from 'react-bootstrap'
import { useEffect, useState, useContext } from 'react';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import Icon from '../tools/Icon';

export default function ScheduleSettingsDisplay({userKey, scheduleSettingsKey, setShowSettingsModal}){

    const [settingsKey, setSettingsKey] = useState(scheduleSettingsKey);

    const [dayCount, setDayCount] = useState(null);
    const [typicalMeals, setTypicalMeals] = useState(null);
    const [typicalSnacks, setTypicalSnacks] = useState(null);

    const {errorText, setErrorText} = useContext(ErrorContext);

    const load = async() => {
        const response = await executeCall(`plans/scheduleSettings/${userKey}`, "GET", setErrorText);
        if("error" in response) return;

        setSettingsKey(response.scheduleSettingsKey);

        setDayCount(response.daysPlanned)
        let days = response.scheduleSetup.split('|');
        let firstDayWithoutQuestionMark = days.find(day => !day.includes('?'));
        setTypicalMeals(firstDayWithoutQuestionMark.split('M').length - 1);
        setTypicalSnacks(firstDayWithoutQuestionMark.split('S').length - 1);
    }

    useEffect(() => {
        //if(scheduleSettingsKey != null && scheduleSettingsKey != "")
            load(scheduleSettingsKey);
    }, [scheduleSettingsKey])

    if(settingsKey == null){
        return (
            <Container fluid style={{marginTop: "40px", marginBottom: "40px"}}>
                <Row style = {{height: "100px", backgroundColor: "#eeeeee", border: "2px solid #cccccc"}}
                >
                    <Col xs ={6}  className = "d-flex align-items-left flex-column justify-content-center">
                        <h4 className = "ml-3">No schedule settings configured</h4>
                        <h6 className = "ml-3">More detailed settings about your schedule will appear here</h6>
                    </Col>
                    <Col xs = {6} className = "d-flex align-items-center">
                        <Button onClick = {() => setShowSettingsModal(true)}style = {{height: "80%", width: "100%", border: "1px solid black", fontWeight: "bolder", boxShadow: "-1px 1px 2px 3px #aaaaaa"}} variant = "light">Enter your settings now -></Button>
                    </Col>
        
                </Row>
            </Container>
        )
    } else {
        return (
            <Container fluid style={{marginTop: "40px", marginBottom: "40px"}}>
                <Row style = {{backgroundColor: "whitesmoke", border: "2px solid #cccccc", borderRadius: "5px", position: "relative"}} onClick = {() => setShowSettingsModal(true)}>
                    <div
                        style={{
                        position: "absolute",
                        right: "10px", // Adjust as needed
                        margin: 0
                        }}
                    >
                        <Icon size={50} className = {"mt-2"} image={"edit"} />
                    </div>
                    <Col className = "d-flex align-items-left flex-column justify-content-center">
                        <h4 className = "montserrat-font mt-4">{typicalMeals} MEALS PER DAY</h4>
                        <h4 className = "montserrat-font mt-4">{typicalSnacks} SNACKS PER DAY</h4>
                        <h4 className = "montserrat-font mt-4 mb-4">{dayCount} DAYS PER MEAL PLAN</h4>
                    </Col>

                </Row>
            </Container>
        )
    }
}


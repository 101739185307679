import { Button } from "react-bootstrap";
import {useState} from 'react';

export default function SecondaryButton({className, text, active = false}) {
    const [hover, setHover] = useState(false);
    
    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    return (
        <Button className = {className + " montserrat-font mt-3 mb-3"} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} 
            style = {{backgroundColor: '#212121',
                color: "#f5f5f5", 
                borderRadius: "10px", 
                border: "5px solid #7DB6A3",
                boxShadow: !hover ? "" : "0px 4px 10px 4px #F5F5F5"}}>
            {text}
        </Button>
    )
}
import React, { useState, useContext } from 'react';
import { Container, Col, Form, Button } from 'react-bootstrap';
import ErrorContext from '../utils/ErrorContext';
import executeCall from '../utils/APICall';
import PrimaryButton from '../Components/PrimaryButton';

export default function Login({ login }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {errorText, setErrorText} = useContext(ErrorContext);

    const handleLogin = async (event) => {
        event.preventDefault();
        const response = await executeCall(`users/login`, 'POST', setErrorText, { "email": email, "password": password });
        if("error" in response) { return; };
        if (response.message === "Success")
            login(response.userKey, response.token);
    };

    return (
        <Container className = "mb-4">
            <Col>
                <Form onSubmit={(e) => handleLogin(e)}>
                    <h1>Log In</h1>
                    <Form.Group>
                        <Form.Label className = "text-left">Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className = "text-left">Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder=""
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <PrimaryButton text = "SUBMIT" className = "mt-3" onClick={handleLogin} />
                </Form>
            </Col>
        </Container>
    );
}

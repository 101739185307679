import {Container, Row, Form, Col, Button, Modal} from 'react-bootstrap';
import NutritionLabel from '../tools/NutritionLabel';
import {useState, useEffect, useContext} from 'react';
import executeCall from '../utils/APICall.js'

import ErrorContext from '../utils/ErrorContext.js';
import ListPortion from '../Components/ListPortion';
import Icon from '../tools/Icon';
import ListAttribute from '../Components/ListAttribute.js';
import PrimaryButton from '../Components/PrimaryButton.js';

const attributeExclusions = [
    {
        primary: "alcoholic beverages", 
        secondary: ["contains_alcohol"]
    },
    {
        primary: "vegan",
        secondary: ["vegetarian"]
    },
    {
        primary: "meat",
        secondary: ["contains_meat"]
    },
    {
        primary: "egg",
        secondary: ["contains_eggs"]
    },
    {
        primary: "eggs",
        secondary: ["egg", "contains_eggs"]
    },
    {
        primary: "poultry",
        secondary: ["contains_meat"]
    },
    {
        primary: "contains_wheat",
        secondary: ["contains_gluten"]
    },
    {
        primary: "grains and cereals",
        secondary: ["contains_wheat, contains_gluten"]
    }
]

//loadIngredients reloads the list of ingredients in the parent container
export default function SingleIngredient({ingredientKey, loadIngredients}){
    const {errorText, setErrorText} = useContext(ErrorContext);

    let [portionsData, setPortionsData] = useState(null);
    let [selectedIngredientName, setSelectedIngredientName] = useState(null);
    let [ingredientNameInError, setIngredientNameInError] = useState(false);

    let [selectedIngredient, setSelectedIngredient] = useState(null);

    let [openPortion, setOpenPortion] = useState(false);
    let [openPortionName, setOpenPortionName] = useState(false);
    let [openPortionGrams, setOpenPortionGrams] = useState(false);
    
    let [selectedPortionKey, setSelectedPortionKey] = useState(null);
    let [selectedPortionName, setSelectedPortionName] = useState(null);
    let [selectedPortionGrams, setSelectedPortionGrams] = useState(false);

    let [allAttributes, setAllAttributes] = useState(null);
    let [attributeData, setAttributeData] = useState(null);
    let [openAttribute, setOpenAttribute] = useState(false);
    let [selectedAttribute, setSelectedAttribute] = useState(false);

    let [editing, setEditing] = useState(false);
    

    const changeIngredientName = (event) => {
        let newValue = event.target.value;
        setSelectedIngredientName(newValue);
    }

    useEffect(() => {
        if(selectedIngredientName != "" && selectedIngredientName != null && ingredientNameInError){
            setIngredientNameInError(false);
        }
    }, [selectedIngredientName])

    useEffect(() => {
        if(portionsData != null && selectedPortionKey != null){
            console.log(selectedPortionKey);
            let newPortion = portionsData.find(item => item.portionKey == selectedPortionKey);
            console.log(newPortion);
            setSelectedPortionName(newPortion.amountTitle);
            setSelectedPortionGrams(newPortion.grams);
        }

    }, [selectedPortionKey])

    //Used for editing ingredient
    const removePortion = (portionKey) => {
        console.log(portionKey)
        let updatedPortions = portionsData.filter(item => item.portionKey !== portionKey)
        setPortionsData(updatedPortions);
    }

    //Used for editing ingredient
    const removeAttribute = (attributeKey) => {
        let updatedAttributes = attributeData.filter(item => item.attributeKey !== attributeKey)
        setAttributeData(updatedAttributes);
    }

    //On load and when selected ingredient key change, load new ingredeint portions
    useEffect(() => {
        if(ingredientKey !== null){
            loadIngredientPortions();
            loadAllAttributes();
        }
        
    }, [ingredientKey])

    //Execute the foods/IngredientByKey call
    const loadIngredientPortions = async () => {

        console.log("Executing foods/IngredientByKey API Call with key " + ingredientKey + "...");
        let x = await executeCall(`foods/ingredientByKey/${ingredientKey}`, 'GET', setErrorText);
        if("error" in x){
            return;
        }

        if(!"nutrients" in x){
            console.log("error!")
        } else {
            console.log(x);

            setSelectedIngredient(x);
            setSelectedIngredientName(x.ingredient.ingredientName);
            
            setPortionsData(x.portions);
            setSelectedPortionKey(x.portions[0].portionKey)

            setAttributeData(x.ingredient.IngredientAttributes);

            setOpenPortionGrams("");
            setOpenPortionName("");
            setOpenPortion(false);
        }

    }

    const loadAllAttributes = async () => {
        let response = await executeCall(`foods/attributeList`, 'GET', setErrorText);
        setAllAttributes(response);

    }

    const portionChange = (e) => {
        const { name, value } = e.target;
        const updatedPortionsData = portionsData.map(item => {
            if (item.portionKey === name) {
                return { ...item, name: value };
            }
            return item;
        });
        setPortionsData(updatedPortionsData);
    };

    const updateIngredient = async (event) => {

        if(selectedIngredientName == "" || selectedIngredientName == null){
            setIngredientNameInError(true)
            return;
        }
        event.preventDefault();
        //let newPortions = portionsData.filter(item => item.name != oldPortions.find(x => x.portionKey === item.portionKey)?.name); such a pretty line but I couldn't make it work

        await executeCall(`foods/updateIngredient`, 'POST', setErrorText, {
            "portions": portionsData, 
            "updatedName": selectedIngredientName, 
            "ingredientKey": ingredientKey, 
            "attributes": attributeData
        });

        setEditing(false)
    }

    const handleChange = (event, setStateFunction) => {
        setStateFunction(event.target.value);
    }

    const addPortionData = () => {
        if(openPortionName === "" || openPortionGrams === 0)
            return;
        
        const updatedPortions = portionsData;
        updatedPortions.push({"amountTitle": openPortionName, "grams": parseFloat(openPortionGrams)});
        console.log("updatedPortions", updatedPortions)
        setPortionsData(updatedPortions);
        setOpenPortion(false);
    }

    const addAttribute = () => {
        if(selectedAttribute === "")
            return;

        let attribute = allAttributes.find(item => item.attributeName === selectedAttribute);
        console.log("attribute", attribute)
        attribute = {attributeKey: attribute.attributeKey, Attribute: {
            attributeName: attribute.attributeName,
            attributeKey: attribute.attributeKey,
            color: attribute.color
        }}
        const updatedAttributes = [...attributeData] ?? [];
        updatedAttributes.push(attribute);
        console.log("updatedAttributes", updatedAttributes);
        setAttributeData(updatedAttributes);
        setOpenAttribute(false);
        setSelectedAttribute("");
    }

    const deleteIngredient = async () => {
        await executeCall(`foods/deleteIngredient/${ingredientKey}`, 'DELETE', setErrorText);
        loadIngredients();
    }



    if(selectedIngredient != null){
        return (
            <Container fluid >
                <Form onSubmit = {updateIngredient}>
                    <Row>
                        {editing && 
                            <>
                                <Form.Control 
                                    onChange = {changeIngredientName} 
                                    value = {selectedIngredientName} 
                                    style = {{width: "90%", backgroundColor: ingredientNameInError ? "#e09e9d" : "whitesmoke"}} 
                                    className = "ml-3"
                                />
                                <Icon image = "x" click = {() => setEditing(false)} className = "mr-3 ml-auto mt-2"/>
                            </>

                        }
                        {!editing &&
                            <>
                                <h1 className = "ml-3" style = {{color: "whitesmoke"}}>{selectedIngredientName}</h1>
                                <Icon image = "lightEdit" click = {() => setEditing(true)} className = "mr-3 ml-auto mt-2"/>
                            </>
                        }
                        
                    </Row>
                    <Row className = "ml-3">
                        <h1 className ="mt-3" style={{color: "whitesmoke"}}>Attributes</h1>
                    </Row>
                    <Row className = "ml-3">
                        {attributeData != null && attributeData.map((attribute, index) => {
                            const attributeName = attribute.Attribute.attributeName;

                            // Find the exclusion where attributeName matches a secondary
                            const matchedExclusion = attributeExclusions.find(exclusion =>
                                exclusion.secondary.includes(attributeName)
                            );

                            // Check if the corresponding primary is present in attributeData
                            const hasMatchingPrimary = matchedExclusion
                                ? attributeData.some(attr => attr.Attribute.attributeName === matchedExclusion.primary)
                                : false;

                            // Render only if both the secondary match and the corresponding primary exist
                            if (matchedExclusion && hasMatchingPrimary) {
                                return;
                            } else {
                                return (
                                    <ListAttribute
                                        key={index}
                                        image={attributeName}
                                        color={attribute.Attribute.color}
                                        editing = {editing}
                                        removeAttribute={() => removeAttribute(attribute.attributeKey)}
                                    />
                                );
                            }
                        })}
                    </Row>
                    
                    <Row className = "ml-2 mr-2 justify-content-center align-items-center flex d-flex">
                        {(!openAttribute && editing) && (
                            <div className = "mt-3">
                                <Button onClick = {() => setOpenAttribute(true)}>Add New Attribute</Button>
                            </div>
                        )}
                        {openAttribute && (
                            <div className={" mt-3 d-flex align-items-center justify-content-center flex-direction-row"}>
                                <Form.Control
                                    as="select"
                                    onChange= {(e) => handleChange(e, setSelectedAttribute)}
                                    value = {selectedAttribute}
                                    style={{margin: 0}}
                                    >
                                        <option value = ""></option>
                                        {/* Assuming availableIngredients is an array of available attributes */}
                                        {(allAttributes != null) && allAttributes.map((attribute, index) => (
                                            <option key = {index}>{attribute.attributeName}</option>
                                        ))}
                                    </Form.Control>
                                <Icon image = "lightRemove" className = "ml-3" click = {(e) => setOpenAttribute(false)}/>
                                <Icon image = "lightPlus" className = "ml-3" click = {() => addAttribute()}/>
                            </div>
                        )}
                    </Row>
                    
                                

                    {/* Nutrition Lable and Portioning */}
                    <Row className = "flex d-flex align-items-center justify-content-center">
                            <NutritionLabel 
                                nutrients = {selectedIngredient.nutrients}
                                amount = {1} 
                                amountUnit = {selectedPortionName == null  ? "serving" : selectedPortionName} 
                                grams = {selectedPortionGrams == null  ? 100 : selectedPortionGrams}
                                gramsDisplay = {selectedPortionGrams == null  ? 100 : selectedPortionGrams}
                                />
                    </Row>
                     
                    <Row className = "flex d-flex align-items-center justify-content-center">
                        <h1 className ="mb5" style = {{color: 'whitesmoke'}}>Portions</h1>
                    </Row>
                    <Row className = "flex d-flex align-items-center justify-content-center">
                        {portionsData.map((portion, index) => (
                            <>
                                <ListPortion title = {portion.amountTitle} click = {() => setSelectedPortionKey(portion.portionKey)} selected = {selectedPortionKey == portion.portionKey} removePortion = {() => removePortion(portion.portionKey)} editing = {editing}/>
                                {/*editing && (
                                    <div key = {index} className={"d-flex align-items-center flex-direction-row"}>
                                        <Form.Control name = {portion.portionKey} value = {portionsData[index].name}placeholder={portion.amountTitle} onChange={portionChange} style = {{width: "50%"}}/>
                                        <p  style={{marginLeft: "10px"}}>({portion.grams}g)</p>
                                        <img style = {{width: "5%", marginLeft: "10px"}} src={process.env.PUBLIC_URL + "/images/remove.png"} onClick = {(e) => removePortion(portion.portionKey)}/>
                                    </div>
                                )*/}
                            </>
                        ))}
                    </Row>
                    <Row className = "flex d-flex justify-content-center align-items-center">
                        {(!openPortion && editing) && <Button className = "mt-3" onClick = {() => setOpenPortion(true)}>Add New Portion</Button>}
                        {(openPortion && editing) && (
                            <div className={"d-flex align-items-center justify-content-center flex-direction-row"}>
                                <Form.Control placeholder={"Add new portion..."} onChange={(e) => handleChange(e, setOpenPortionName)} style = {{width: "50%"}}/>
                                <Form.Control placeholder = {"g"} onChange={(e) => handleChange(e, setOpenPortionGrams)} style = {{width: "10%"}} />
                                <Icon image = "lightRemove" className = "ml-2" click = {(e) => setOpenPortion(false)}/>
                                <Icon image = "lightPlus" className = "ml-2" click = {() => addPortionData()}/>
                            </div>
                        )}
                    </Row>
                        <Row className = "mt-5">
                            <Col style = {{paddingLeft: 0}}>

                            
                            </Col>  
                        </Row>
                        {editing && <Row className="mt-5 d-flex align-items-center justify-content-center">
                            <Col
                                className="d-flex flex-column align-items-center"
                                style={{ width: "auto" }}
                            >
                                <PrimaryButton
                                onClick={(event) => updateIngredient(event)}
                                text="Submit"
                                style={{ width: "100px" }}
                                />
                                <Button variant="danger" className = "mt-4 mb-4" style={{ width: "100px"}} onClick={() => deleteIngredient()}>
                                    Delete
                                </Button>
                            </Col>
                        </Row>}
                </Form>
            </Container>
        )   
    } else {
        return <></>
    }


}


import {Container, Button, Row, Col, ListGroup, Alert, Form, Modal, Tabs, Tab, Spinner, Image} from 'react-bootstrap';

import NutritionLabel from '../tools/NutritionLabel.js';
import MealIngredient from './MealIngredient.js';
import { useState, useEffect, useContext } from 'react';
import { addNutrients } from '../sharedFunctions/addNutrients.js';
import GoalComparison from '../tools/GoalComparison.js';
import Icon from '../tools/Icon.js';
import IngredientsModal from './Modals/IngredientsModal.js';
import Recipe from './Recipe.js';
import executeCall from '../utils/APICall.js';
import ErrorContext from '../utils/ErrorContext.js';
import {CustomModal} from '../Components/CustomModal.js';
import SingleIngredient from '../Ingredient/SingleIngredient.js';
import PrimaryButton from '../Components/PrimaryButton.js';
import SessionContext from "../utils/SessionContext.js";


import EditIngredients from '../Ingredient/Ingredients.js';
import WelcomeScreenDonutChart from '../dashboard/WelcomeScreenDonutChart.js';
import GoalComparisonBarChart from '../tools/GoalComparisonBarChart.js';

async function getIngredient(ingredientKey, setErrorText){
    const response = await executeCall(`foods/ingredientByKey/${ingredientKey}`, "GET", setErrorText);
    if("error" in response) { return; }

    return {"name": response.ingredient.ingredientName, "allPortions": response.portions, "nutrients": response.nutrients, "portion": null, "ingredientKey": ingredientKey};
}

export function indexNutrients(nutrients){
  let totalNutrientGrams = nutrients.carbohydrates + nutrients.protein + nutrients.totalfats;
  return {
    carbsToTotal: nutrients.carbohydrates / totalNutrientGrams,
    fatsToTotal: (nutrients.totalfats) / totalNutrientGrams,
    proteinToTotal: nutrients.protein / totalNutrientGrams
  }
}


/*** 
 * PARAMS
 * source - temporarily used to differentiate between admin users and typical users when setting up default meals
 * userKey - used when adding a meal to tie back to user
 * mealKey - key of the meal. either passed in or created on update
 * reload - reloads meal list
 * ***/
export default function SingleMealDetail({source, userKey, mealKey, reload, edit = true}){
    let [editing, setEditing] = useState(false);
    let [mealName, setMealName] = useState(null);
    let [mealNameInError, setMealNameInError] = useState(false);
    
    let [defaultServings, setDefaultServings] = useState(null);
    let [currentServings, setCurrentServings] = useState(null);
    let [previousServings, setPreviousServings] = useState(null);
    let [previousDefaultServings, setPreviousDefaultServings] = useState(null);
    
    let [ingredients, setIngredients] = useState(null);
    let [nutrients, setNutrients] = useState(null); 
    let [ingredientKey, setIngredientKey] = useState(null);

    const [selectedTab, setSelectedTab] = useState("Ingredients")
    let [selectedAmount, setSelectedAmount] = useState(null);
    let [selectedPortion, setSelectedPortion] = useState(null);

    const [photoData, setPhotoData] = useState(null);
    const [photoDataURL, setPhotoDataURL] = useState(null);
    const [photoAdded, setPhotoAdded] = useState(false); 

    let [mealType, setMealType] = useState("Meal");

    let [loading, setLoading] = useState(mealKey != false);

    const [recipeKey, setRecipeKey] = useState(null)

    const {errorText, setErrorText} = useContext(ErrorContext);
    const {goals} = useContext(SessionContext);

    const [viewingIngredientKey, setViewingIngredientKey] = useState(null);

    const [indexedNutrient, setIndexedNutrient] = useState(null);


    useEffect(() => {
      console.log(ingredientKey)
        if(ingredients  !== null && ingredientKey  !== false && ingredientKey  !== true && ingredientKey  !== null){
          console.log(ingredientKey);
            let ingredient = ingredients.find(item => item.ingredientKey == ingredientKey);
            console.log(ingredient);
            setSelectedPortion(ingredient.portion);
            setSelectedAmount(ingredient.amount);
            setNutrients(ingredient.nutrients);
        }   
    }, [ingredientKey])

    useEffect(() => {
        if(mealNameInError && (mealName != null && mealName != " ")){
          setMealNameInError(false);
        }
    }, [mealName])
    
    const displayMeal = async(mealKey) => {
        setLoading(true);
        const response = await executeCall(`foods/mealByKeyDetail/${mealKey}`, "GET", setErrorText);
        if("error" in response) { return; }

        if(response.ingredients == null){
            console.log("Error - no ingredients included in ")
            return;
        }

        console.log("response", response);

        setMealType(response.mealType)
        setMealName(response.name);
        
        setDefaultServings(response.defaultServings);
        setCurrentServings(response.defaultServings);
        setPreviousServings(response.defaultServings);

        setPhotoData(response.mealImage);

        setPreviousDefaultServings(response.defaultServings);
        setIngredients(response.ingredients);
        setPhotoData(response.mealImage);
        let newNutrients = addNutrients(response.ingredients)
        setNutrients(newNutrients);
        setIndexedNutrient(response.indexedNutrients);

        if(response.recipe != null && response.recipe.length != 0){
          console.log(response.recipe[0].recipeKey);
          setRecipeKey(response.recipe[0].recipeKey)
        } else {
          setRecipeKey(null);
        }
        setIngredientKey(null);
        setLoading(false);
    };

    useEffect(() => {
        //If this is a pre-existing meal, clear ingredients
        if(mealKey  !== null && mealKey  !== false){
            setIngredients([]);
            setMealName(null);
            setCurrentServings(null);
            setEditing(false);
            displayMeal(mealKey);
        } else if(mealKey == false) {
            setIngredients([]);
            setDefaultServings(1);
            setMealName(null);
            setCurrentServings(1);
            setEditing(true);
        }
    }, [mealKey])

    useEffect(() => {
        if(ingredients  !== null && ingredients.length  !== 0){
            setNutrients(addNutrients(ingredients));
        }
            
    }, [ingredients]);

    const multiplyObjectProperties = (obj, multiplier) => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            obj[key] *= multiplier;
          }
        }
        return obj;
      }



    useEffect(() => {
        if(ingredients  !== null && ingredients.length  !== 0){
            let updatedIngredients = [];
            for(let x of ingredients){
                x.amount = x.amount * currentServings / previousServings;
                x.nutrients = multiplyObjectProperties(x.nutrients, currentServings / previousServings);
                updatedIngredients.push(x);
            }
            setIngredients(updatedIngredients);
            setPreviousServings(currentServings);
        }
    }, [currentServings])

    const updateMeal = async () => {
        let newUserKey = userKey;

        if(mealName == "" || mealName === " " || mealName == null){
          setMealNameInError(true);
          return;
        }

        if(source == "Default")
            newUserKey = "00000000-0000-0000-0000-000000000000";
        const response = await executeCall(`foods/updateMeal/${mealKey}`, "POST", setErrorText, {"updatedIngredients": ingredients, "newName": mealName, "userKey": newUserKey, "newDefaultServings": defaultServings, "mealType": mealType});
        console.log("response")
        if("error" in response) return;
        
        if("mealKey" in response){
          const formData = new FormData();
          formData.append('mealImage', photoData);
          await executeCall(`foods/addMealImage/${response.mealKey}`, "FILE", setErrorText, formData);
        }
        setEditing(false);
    }

    const deleteMeal = async () => {
      const response = await executeCall(`foods/deleteMeal/${mealKey}`, "DELETE", setErrorText);
      if("error" in response) return;
      reload();
    }

    const addIngredient = async (ingredientKey) => {

        let ingredientInfo = await getIngredient(ingredientKey, setErrorText);
        let updatedIngredients = [...ingredients];
        updatedIngredients.push(ingredientInfo);
    

        console.log(ingredients);

        setNutrients(addNutrients(updatedIngredients));
        setIngredients(updatedIngredients);
        setIngredientKey(ingredientKey);
    }

    const removeIngredient = (ingredientKey) => {
        let updatedIngredients = [...ingredients];
        updatedIngredients = updatedIngredients.filter(item => item.ingredientKey  !== ingredientKey);
        setIngredients(updatedIngredients);
    }

    const updateIngredient = (update) => {
        let updatedIngredients = [...ingredients];
        updatedIngredients = updatedIngredients.filter(item => item.ingredientKey  !== update.ingredientKey);
        updatedIngredients.push(update);
        setIngredients(updatedIngredients);
        let newNutrients = addNutrients(updatedIngredients)
        console.log(newNutrients);
        setNutrients(newNutrients);
    }

    const triggerFileInput = () => {
      document.getElementById('mealPictureUpload').click();
    };

    const handlePhotoUpload = async (event) => {
      const file = event.target.files[0];
      const fileURL = URL.createObjectURL(file);
      setPhotoDataURL(fileURL);
      setPhotoData(file);
      setPhotoAdded(true);
  };

    return (
      
      <Container fluid>
        <Row>
          <Col xs={9}>
            {!editing && 
              <h1 style={{ fontFamily: "Koulen", color: "whitesmoke", textAlign: "left" }} >
                <u>{mealName}</u>
              </h1>
            }
            {editing && (
                  <Form.Control onChange={(e) => setMealName(e.target.value)} value={mealName ?? ""} placeholder={mealName} 
                  style={{width: "100%", fontSize: "20px", backgroundColor: mealNameInError ? "#e09e9d" : "whitesmoke"}}/>
                )}

          </Col>
          
          <Col xs={3} className="text-end d-flex justify-content-end">
            {!editing && <h1 style={{ fontFamily: "Koulen", color: "whitesmoke" }}>
              {mealType}
            </h1>}
            {editing && (
                <Form.Group controlId="colorDropdown">
                  <Form.Control as="select" value={mealType} onChange={(e) => setMealType(e.target.value)}>
                    <option value = "meal">Meal</option>
                    <option value = "snack">Snack</option>
                    <option value = "breakfast">Breakfast</option>
                  </Form.Control>
              </Form.Group>
              )}
          </Col>
        </Row>
        
        <input
                type="file"
                id="mealPictureUpload"
                className="hidden-file-input"
                onChange={handlePhotoUpload}
                accept="image/*"
            />

        {!loading && (<Tabs onSelect={(tab) => setSelectedTab(tab)}>
          <Tab eventKey="Ingredients" title="Ingredients">
            {!loading && (
              <Col>
              {/* NAME CONTROL - TOP ROW */}
              <Row className="flex d-flex flex-direction-row align-items-center justify-content-center" style={{ width: "100%" }}>
                  <h1 style = {{color: "whitesmoke", marginTop: "30px"}}>Recipe makes {defaultServings} {defaultServings == 1 ? "serving" : "servings"} by default</h1>
                  
                {editing && <Icon image="minus" className="mt-3 ml-4" size="15px" click={() => {defaultServings != 1 && setDefaultServings(defaultServings - 1);}}/>}
                  
                  <h1 className = "ml-3" style = {{color: "whitesmoke", marginTop: "30px"}}>{defaultServings}</h1>
                  <Icon image = "lightServingIcon" click = {() => {return;}} className = "ml-3 mt-3"  size = "30px"/>
                {editing && <Icon image="lightplus" className="mt-3 ml-3 mr-2" size="15px" click={() => {setDefaultServings(defaultServings + 1);}}/>}
                {!editing && edit && (
                  <Icon image="lightEdit" size="30px" className="ml-auto" click={() => setEditing(true)} />
                )}

                {editing && (
                  <Icon image="x" click={() => {
                      setEditing(false);
                      setIngredientKey(null);
                    }} size="30px" className="ml-auto"
                  />
                )}
              </Row>

              {(editing && photoData == null && photoDataURL == null) && (
                <div className = "flex d-flex align-items-center justify-content-center flex-column mt-4 mb-4">
                  <Icon size = {50} image = "photo" click = {() => triggerFileInput()}/>
                  <h1 style = {{color: "whitesmoke"}}>This meal does not have a picture.</h1>
                </div>
              )}
              {(photoData != null || photoDataURL != null) && (
                  <Image src={photoDataURL ?? photoData}
                      style={{ maxWidth: "100%", marginLeft: "10%", maxHeight: "400px", objectFit: "contain", margin: 0 }}
                      className="img-circle mb-4 mt-4"
                  />
              )}

              {(userKey != null && !loading && !editing) && (
                <Row>
                  <Col className = "mt-4 mb-4" >
                    {indexedNutrient != null && (
                      <WelcomeScreenDonutChart donutChart = {{carbohydrates: indexedNutrient.carbsToTotal, totalfats: indexedNutrient.fatsToTotal, protein: indexedNutrient.proteinToTotal}} />
                    )}
                    <h1 className = "mt-2" style = {{color: "whitesmoke"}}>Nutrients</h1>
                  </Col>
                  <Col className = "mt-4 mb-4">
                  {indexedNutrient != null && (
                    <GoalComparisonBarChart goalNutrientIndices={indexNutrients(goals.Nutrient)} foodNutrientIndices={indexedNutrient} />
                  )}
                  </Col>
                </Row>

              )}


              {/* EDITABLE INGREDIENTS - ROW 3 */}
              <ListGroup>
                {ingredients !== null &&
                  ingredients
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((ingredient) => {
                      return (
                        <MealIngredient
                        selected={ingredient.ingredientKey == ingredientKey}
                        ingredient={ingredient}
                        updateIngredient={(e) => updateIngredient(e)}
                        removeIngredient={(e) => removeIngredient(e)}
                        setSelectedIngredient={(e) => setIngredientKey(e)}
                        viewThisIngredient={() => setViewingIngredientKey(ingredient.ingredientKey)}
                        currentServings={defaultServings}
                        defaultServings={defaultServings}
                        editing={editing}
                      />
                      )
                    })}
              </ListGroup>

              {(editing || mealKey == false) &&
                <Row className = "flex d-flex justify-content-center align-items-center mt-4 mb-4">
                    <Icon image = "lightPlus" click = {() => setIngredientKey(true)} />
                </Row>
              }

              {(nutrients != null && mealKey != false && !editing) && 
                <GoalComparison goalNutrients={goals.Nutrient} nutrients={nutrients} />
              }
              {editing && 
                <Col className = "flex d-flex align-items-center justify-content-center flex-column">
                  {mealKey != false && <PrimaryButton className = "mt-3 mb-4" onClick = {() => displayMeal(mealKey)} text = "RESTORE" />}
                  <PrimaryButton className = "mt-2 mb-4" onClick={() => updateMeal()} text = {"SUBMIT"} />
                  {mealKey != false && <PrimaryButton className = "mt-3 mb-4" onClick={() => deleteMeal()} text = "DELETE"/>}
                </Col>
              }


            </Col>
            )}

          </Tab>
          <Tab eventKey="Nutrition" title="Nutrition">
            {nutrients != null && (
              <Row>
                <Col className = "flex d-flex align-items-center flex-column">
                  <h1 className = "mt-3" style = {{color: "whitesmoke"}}>Nutrition for {currentServings} servings</h1>
                  <div className = "flex d-flex flex-direction-row align-items-center justify-content-center">
                      <Icon image="minus" className="ml-4" size="15px" click={() => {currentServings != 1 && setCurrentServings(currentServings - 1);}}/>
                      <h1 className = "ml-3 mt-2" style = {{color: "whitesmoke"}}>{currentServings}</h1>
                      <Icon image = "lightServingIcon" click = {() => {return;}} className = "ml-3"  size = "30px"/>
                      <Icon image="lightplus" className="ml-3 mr-2" size="15px" click={() => {setCurrentServings(currentServings + 1);}}/>
                  </div>
                  <NutritionLabel
                    nutrients={nutrients}
                    amount={currentServings}
                    amountUnit={"serving"}
                    grams={100}
                  />
                </Col>
              </Row>

            )}
            {(
              nutrients == null && (
                <div className = "flex flex-column align-items-center justify-content-center text-align-center">
                  <h1 className = "mt-3" style = {{color: "whitesmoke", textAlign: 'center'}}>Please add ingredients to this meal to see its nutrients!</h1>
                  <NutritionLabel
                    nutrients={nutrients}
                    amount={currentServings}
                    amountUnit={"serving"}
                    grams={100}
                    blank = {true}
                  />
                </div>
                
              )
            )}
          </Tab>
          <Tab eventKey="Recipe" title="📚 Recipe">
            <Recipe mealKey = {mealKey} recipeKey = {recipeKey} reset = {() => displayMeal(mealKey)} mealExists = {(mealKey != null) ? true : false}/>
           
          </Tab>
        </Tabs>
        )}
        {(loading && mealKey != null && mealKey != false) && (
              <div className="flex d-flex align-items-center justify-content-center" style={{ width: "100%", height: "600px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Spinner style={{ color: "whitesmoke" }} />
              </div>
        )}
        {ingredientKey == true && (
          <CustomModal 
            modalBody = {<EditIngredients source="meals" ingredientSelect={(key) => addIngredient(key)} />} 
            close = {() => setIngredientKey(false)}
          />
        )}
        {viewingIngredientKey != null && (
          <CustomModal modalBody={<SingleIngredient ingredientKey={viewingIngredientKey}/>} close = {() => setViewingIngredientKey(null)} />
        )}
      </Container>
    );
}
import React, { useState, useEffect } from 'react';
import Options from './Options';
import DayOptions from './DayOptions';
import DayMealModel from './DayMealModel';
import Icon from '../../tools/Icon';
import { Row } from 'react-bootstrap';

const options = [
    'Cheat Meals',
    "Cheat Days"
];

export default function CheatDaysSelector({dayCount, optState, optSet, close}){
    const [cheatTypeSelection, setCheatTypeSelection] = useState('');
    const [cheatDays, setCheatDays] = useState(dayCount ? '0'.repeat(dayCount) : "");

    useEffect(() => {
        let days = [...optState.split('|')];        
        let i = 0;
        for (let day of days) {
            if (cheatDays[i] === '0') {
                days[i] = day.replace(/X/g, '');
            } else if (cheatDays[i] === '1') {
                if(!day.includes('X'))
                    days[i] = day + 'X';
            }
            i++;
        }
        optSet(days.join('|'));
        console.log(days.join('|'));
    }, [cheatDays]);

    return (
        <div>
            <Options
                text="Select Cheat Option"
                opts={options}
                optState={cheatTypeSelection}
                optSet={(e) => setCheatTypeSelection(e)} />

            {cheatTypeSelection === 'Cheat Days' && (
                <div className = "pb-4">
                    <DayOptions dayCount={dayCount} question="Select Cheat Days" optState={cheatDays} optSet={(e) => setCheatDays(e)} />
                </div>
            )}
            {cheatTypeSelection == "Cheat Meals" && (
                <div className = "pb-4">
                    <DayMealModel dayCount = {dayCount} optState = {optState} optSet={optSet}/>
                </div>
            )}
            <Row className = "d-flex justify-content-center">
                <Icon image = "lightcheck" className = "mb-4 mt-4" click = {() => close()} size = {"50px"}/>
            </Row>
        </div>
    );
};
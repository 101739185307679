import {Button} from 'react-bootstrap';
import {useState, useEffect, useContext, useRef} from 'react';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';

import {CustomModal} from '../Components/CustomModal.js'
import Icon from '../tools/Icon';
import Options from './ScheduleSettingsComponents/Options.js';

import ManualScheduleSelector from './ScheduleSettingsComponents/ManualScheduleSelector.js';
import TypicalScheduleSelector from './ScheduleSettingsComponents/TypicalScheduleSelector.js';
import CheatDaysSelector from './ScheduleSettingsComponents/CheatDaysSelector.js';
import PrimaryButton from '../Components/PrimaryButton.js';

const allObjectsAreSame = (arr) => {
    if (arr.length === 0) return true;
    const firstObject = JSON.stringify(arr[0]);
    return arr.every(obj => JSON.stringify(obj) === firstObject);
};

export default function ChangeScheduleSettings({
        signup, //Whether this is part of the signup. Affects display
        userKey = null, 
        setSettingsKey = null, 
        scheduleSettingsKey = 0, 
        next = null, //For navigation within signup
        prev = null, //For navigation within signup
        formState = null, //To complete signup
        setFormState = null, //To complete signup
        reload = null //To reload schedule settings display in the Schedules.js component
    }){

    const {setErrorText} = useContext(ErrorContext);

    const [updScheduleSettingsKey, setUpdScheduleSettingsKey] = useState(scheduleSettingsKey);

    const daysToPlanFor = [2, 3, 4, 5, 6, "1 week"];
    const [selectedDays, setSelectedDays] = useState(null);
    
    const countOptions = ["YES", "MOST DAYS", "NO"]
    const [selectedCount, setSelectedCount] = useState(null);

    const [customMeals, setCustomMeals] = useState("");

    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "I don't care"];
    const [selectedWeekDay, setSelectedWeekDay] = useState(null);

    const varietyOptions = ["Spend more time cooking, have more varied meals", "Spend less time cooking, have less varied meals"]
    const [selectedVariety, setSelectedVariety] = useState(null);
    
    
    const [cheating, setCheating] = useState(false);
    const [cheatingModalOpen, setCheatingModalOpen] = useState(false);
    const [cheatingComplete, setCheatingComplete] = useState(false);
    const cheatOptions = ["No", "Yes"];

    const [customSetupModalOpen, setCustomSetupModalOpen] = useState(false);
    const [customSetupComplete, setCustomSetupComplete] = useState(false);

    const [typicalSetupModalOpen, setTypicalSetupModalOpen] = useState(false);
    const [typicalSetupComplete, setTypicalSetupComplete] = useState(false);
    const prevScheduleInfoRef = useRef(formState != null ? formState.ScheduleInfo : null);

    const save = async () => {
        let varietyNum = 0;
        if(selectedVariety === varietyOptions[1])
            varietyNum = 1;
        if(selectedVariety === varietyOptions[2])
            varietyNum = 2;
        const response = await executeCall(`plans/updateScheduleSettings/${userKey}/${updScheduleSettingsKey}`, "POST", setErrorText, {
            "daysPlanned": selectedDays,
            "scheduleSetup": customMeals,
            "cheatingBoolean": false,
            "dailyMealCountVariation": selectedCount,
            "breakfast": false,
            "variety":  varietyNum,
            "microwaveDays": '0'.repeat(selectedDays)
        })

        const newKey = await response.key;
        setUpdScheduleSettingsKey(newKey);
        setSettingsKey(newKey);
        await load(newKey);
    }

    useEffect(() => { 
        if(cheating == cheatOptions[1] && !customMeals.includes('X')) {
            setCheatingModalOpen(true);
        } else {
            setCustomMeals(customMeals.replace(/X/g, ''));
        }
    },
    [cheating]);

    const load = async() => {
        console.log("load executing")
        const response = await executeCall(`plans/scheduleSettings/${userKey}`, "GET", setErrorText);
        if("error" in response) return;

        console.log(response);

        if(response.hasOwnProperty('message') && response.message === "NOSETTINGS"){
            return;
        }
        setSelectedDays(response.daysPlanned);
        loadFormFromString(response.scheduleSetup);
        setSelectedVariety(varietyOptions[response.variety]);
        if(response.scheduleSetup.includes('X')){
            setCheating(cheatOptions[1]);
        }

    }

    function loadFormFromString(customMealsString){
        setCustomMeals(customMealsString);
        setSelectedDays(customMealsString.split('|').length);
        
        let days = customMealsString.replace(/X/g, '').split('|');
        console.log(days);
        if(customMealsString.includes("?")){
            setSelectedCount(countOptions[1]);
            setTypicalSetupComplete(true);
        } else if (customMealsString.includes("!")){
            setSelectedCount(countOptions[2]);
            setCustomSetupComplete(true);
        } else if(allObjectsAreSame(days)){
            setTypicalSetupComplete(true);
            setTypicalSetupModalOpen(false);
            setSelectedCount(countOptions[0]);
        }

        if(customMealsString.includes("X")){
            setCheating(cheatOptions[1]);
            setCheatingComplete(true);
            setCheatingModalOpen(false);
        } else {
            setCheating(cheatOptions[0]);
            setCheatingComplete(true);
            setCheatingModalOpen(false);
        }

        
    }

    useEffect(() => {
        if(!signup)
            load(scheduleSettingsKey);
        else {
            let scheduleInfo = formState.ScheduleInfo;

            if(scheduleInfo != null){
                loadFormFromString(scheduleInfo.customMeals);
                setSelectedVariety(scheduleInfo.variety);
            }
        }
    }, [scheduleSettingsKey])


    useEffect(() => {
        if(selectedCount === countOptions[2] && !customSetupComplete){
            if(customMeals == "" || /^[|]*$/.test(customMeals)){
                setCustomMeals(Array(selectedDays).fill("!MMMS").join("|"));
            } else {
                let days = customMeals.split('|');
                for(let i = 0; i < days.length; i++){
                    if(!days[i].includes("!"))
                        days[i] = days[i] + "!";
                }
                setCustomMeals(days.join("|"));
                console.log(days.join("|"));
            }
            setCustomSetupModalOpen(true);
        } else if ((selectedCount == countOptions[1] || selectedCount == countOptions[0]) && !typicalSetupComplete){
            setCustomMeals(customMeals.replace(/!/g, ''));
            setTypicalSetupModalOpen(true);
        }
    }, [selectedCount])

    useEffect(() => {
        if(customMeals != null && selectedDays != null){
            const currentDays = (customMeals.match(/\|/g) || []).length + 1;
            if(selectedDays > currentDays){
                let newString = customMeals + ('|'.repeat(selectedDays - currentDays))
                setCustomMeals(newString);
                setTypicalSetupComplete(false);
                setCustomSetupComplete(false);
            } else if (selectedDays < currentDays){
                let count = 0, index = null;
                for (let i = 0; i < customMeals.length; i++) {
                    if (customMeals[i] === '|') {
                        count++;
                        if (count === selectedDays)
                            index = i;
                    }
                }
                let newString = customMeals.slice(0, index);
                setCustomMeals(newString);
                
            }
        } 
    }, [selectedDays])

    function validateThenNext(){
        console.log("validating with ", customMeals)
        if(selectedDays == null){
            setErrorText("Please select how many days you would like between your meal prep days.");
            return;
        }
        if(selectedCount == null){
            setErrorText("Please select whether you eat the same number of meals every day.");
            return;
        } else if(selectedCount == countOptions[0] && !typicalSetupComplete){
            setErrorText("Make sure to select how many meals you eat on a typical day.");
            return;
        } else if (selectedCount == countOptions[1] && !typicalSetupComplete){
            setErrorText("Please make sure to select many meals you eat per day.");
            return;
        } else if (selectedCount == countOptions[2] && !customSetupComplete){
            setErrorText("Please make sure to select many meals you eat per day.");
            return;
        }
        if(cheating == null){
            setErrorText("Please select whether you would like to include cheat days.");
            return;
        }
        if(selectedVariety == null){
            setErrorText("Please  specify how much variety you would like in your meals.");
            return;
        }
        setFormState(prevState => ({
            ...prevState,
            ScheduleInfo: { customMeals: customMeals, variety: selectedVariety },
        }));
    }

    useEffect(() => {

        if (formState != null && formState.ScheduleInfo && formState.ScheduleInfo !== prevScheduleInfoRef.current) {
            next();
            prevScheduleInfoRef.current = formState.ScheduleInfo;
        }
    }, [formState]);

    return (
        <div className = "flex d-flex align-items-center flex-column" style = {{backgroundColor: "#212121", color: "whitesmoke", padding: 0}}>
            <h1 className = "mt-4"><u>Your Schedule Preferences</u></h1>
            <h3 style = {{fontFamily: "Koulen", fontWeight: "normal"}}>On a meal prep day, you shop for ingredients and cook your meals.</h3>
            <h3 style = {{fontFamily: "Koulen", fontWeight: "normal"}}>Your meal plan begins the following day.</h3>

            <Options text = "How many days would you like between meal prep days?" 
                opts = {daysToPlanFor} 
                optState = {selectedDays}
                optSet = {(e) => setSelectedDays(e)}
                spaceLastOption = {true}/>

            <Options text = "Do you want your next meal prep day to be on a specific day?"
                opts = {allDays}
                optState = {selectedWeekDay}
                optSet = {(e) => setSelectedWeekDay(e)}
                />

            <Options text = "Do you eat the same number of meals every day?" 
                opts = {countOptions}
                optState = {selectedCount}
                optSet = {(e) => setSelectedCount(e)}
                completionIndicator = {[
                    {position: 0,
                        var: typicalSetupComplete,
                        open: () => setTypicalSetupModalOpen(true),
                        icon : typicalSetupComplete ? "lightcheck" : "x"
                    },
                    {
                    position: 1,
                    var: typicalSetupComplete,
                    open: () => setTypicalSetupModalOpen(true),
                    icon: typicalSetupComplete ? "lightcheck" : "x"
                }, {
                    position: 2,
                    var: customSetupComplete,
                    open: () => setCustomSetupModalOpen(true),
                    icon: customSetupComplete ? "lightcheck" : "x"
                }]}
                placeholder = {selectedDays == 0 || selectedDays == null}/>

            {selectedDays != 1 && (
                <Options text = "Should your schedule include cheat days?" 
                    opts = {cheatOptions}
                    optState = {cheating}
                    optSet = {(e) => setCheating(e)} 
                    placeholder = {selectedDays == 0 || selectedDays == null}
                    completionIndicator = {[
                        {position: 1,
                            var: typicalSetupComplete,
                            open: () => setCheatingModalOpen(true),
                            icon : cheatingComplete ? "lightCheck" : "x"
                        }
                    ]}/>
            )}

            <Options text = "Which option would you prefer?" 
                opts = {varietyOptions} 
                optState = {selectedVariety}
                optSet = {(e) => setSelectedVariety(e)}/>

            {/*
            <Options text = "Do you want breakfast foods / meals in this plan?" 
                opts = {breakfastOptions}
                optState = {breakfast}
                optSet = {(e) => setBreakfast(e)} />

            {selectedMicrowave === microwaveOptions[2] && 
                <DayOptions dayCount={selectedDays}
                    question = "Which days do you have access?"
                    optState = {microwaveDays}
                    optSet = {(e) => setMicrowaveDays(e)} />
            }

            */}    

            {signup == false && <PrimaryButton className = "mt-4" text = "Save" onClick = {() => save()}/>}
            {cheating == cheatOptions[1] && cheatingModalOpen && 
                <CustomModal modalBody = {<CheatDaysSelector dayCount = {selectedDays} optState = {customMeals} optSet = {(e) => setCustomMeals(e)} close = {() => {setCheatingModalOpen(false); setCheatingComplete(true)}}/>} close = {() => setCheatingModalOpen(false)}/>
            }
            {selectedCount == countOptions[0] && typicalSetupModalOpen &&
                <CustomModal modalBody = {
                    <TypicalScheduleSelector dayCount={selectedDays} optState = {customMeals}  optSet = {(e) => setCustomMeals(e)} close = {() => {setTypicalSetupModalOpen(false); setTypicalSetupComplete(true)}} />
                } close = {() => setTypicalSetupModalOpen(false)}/>}
            {selectedCount == countOptions[1] && typicalSetupModalOpen && 
                <CustomModal modalBody = {
                        <TypicalScheduleSelector dayCount={selectedDays} optState = {customMeals}  optSet = {(e) => setCustomMeals(e)} exceptions = {true} beginDay = {selectedWeekDay} close = {() => {setTypicalSetupModalOpen(false); setTypicalSetupComplete(true)}}/>
                    } close = {() => setTypicalSetupModalOpen(false)}/>}
            {selectedCount == countOptions[2] && customSetupModalOpen && 
                <CustomModal modalBody = {
                    <ManualScheduleSelector dayCount = {selectedDays} optState = {customMeals} optSet = {(e) => setCustomMeals(e)} close = {() => {setCustomSetupModalOpen(false); setCustomSetupComplete(true)}}/>
                    } close = {() => setCustomSetupModalOpen(false)}/>}

            <div className = "flex d-flex flex-direction-row mt-4 mb-4 pb-4">
                {signup && <Icon className = "mr-4" image="next" size = {"50px"} click={() => prev()} />}
                <div className = "ml-4" style = {{transform:"rotate(180deg)"}}>
                    {signup && <Icon  image="next" size = {"50px"} click={() => validateThenNext()} />}
                </div>
               
            </div>
        </div>
    )
}
import {Container, Row, Col} from 'react-bootstrap';
import {useEffect, useContext, useState} from 'react';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import SessionContext from '../utils/SessionContext';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'

  const CircularDial = ({ value, goalVal }) => {
    // Define a function to calculate colors
    const getGradientColor = (percentage) => {
      if (percentage <= 50) {
        // Red to yellow
        const redToYellow = percentage * 2; // Scale to 0-100
        return `rgb(255, ${Math.round((redToYellow / 100) * 255)}, 0)`;
      } else {
        // Yellow to green to yellow
        const yellowToGreen = (percentage - 50) * 2; // Scale to 0-100
        return `rgb(${Math.round((1 - yellowToGreen / 100) * 255)}, 255, 0)`;
      }
    };
  
    return (
      <div style={{ width: '100px' }}>
        <CircularProgressbar
          value={value}
          circleRatio = {0.5}
          text={`${((value / goalVal)* 100).toFixed(2)}%`}
          styles={buildStyles({
            textColor: 'whitesmoke',
            pathColor: getGradientColor(value),
            trailColor: '#ddd',
            rotation: 0.75
          })}
        />
      </div>
    );
  };

function SingleNutrient({val, goalVal, name, mealPercentage}){

    let percentage = ((val / goalVal) * 100).toFixed(2);
    let diff = (mealPercentage - percentage).toFixed(2);

    return (
        <Col xs = {3} className = "flex d-flex align-items-center justify-content-center flex-column mt-3 mr-0 ml-0 p-0">
            <CircularDial value = {val} goalVal = {goalVal}/>
            <h3 style = {{color: "whitesmoke", fontFamily: "Koulen", marginTop: "-20px"}}>{name}</h3>
        </Col>
    )
}

function findModeOrAverageMs(arr, string) {
    // Step 1: Count the "M"s in each string
    let letterCounts;
    if(string == "M")
        letterCounts = arr.map(str => (str.match(/M/g) || []).length);
    else if(string === "S")
        letterCounts = arr.map(str => (str.match(/S/g) || []).length);

    // Step 2: Calculate the frequency of each count
    const frequencyMap = {};
    letterCounts.forEach(count => {
      frequencyMap[count] = (frequencyMap[count] || 0) + 1;
    });
  
    // Step 3: Find the mode if there is one
    let mode = null;
    let maxFrequency = 0;
    let multipleModes = false;
    for (const [count, frequency] of Object.entries(frequencyMap)) {
      if (frequency > maxFrequency) {
        maxFrequency = frequency;
        mode = parseInt(count, 10);
        multipleModes = false;
      } else if (frequency === maxFrequency) {
        multipleModes = true;  // More than one mode, so no unique mode
      }
    }
  
    // Step 4: Return the mode if unique, otherwise the rounded-down average
    if (!multipleModes) {
      return mode;
    } else {
      const average = Math.floor(letterCounts.reduce((a, b) => a + b, 0) / letterCounts.length);
      return average;
    }
  }

export default function GoalComparison({goalNutrients, nutrients}){
    const {errorText, setErrorText} = useContext(ErrorContext);
    const {userKey} = useContext(SessionContext);
    const [mealPercentage, setMealPercentage] = useState(null);

    async function load(){
        const response = await executeCall(`plans/scheduleSettings/${userKey}`, "GET", setErrorText);
        if("error" in response) return;

        let days = response.scheduleSetup.split('|');
        let averageMealCount = findModeOrAverageMs(days, "M");
        let averageSnackCount = findModeOrAverageMs(days, "S");
        let calculatedMealPercentage = ((100 - (averageSnackCount * 10)) / averageMealCount).toFixed(2)
        setMealPercentage(calculatedMealPercentage);
    }

    useEffect(() => {
        load();
    }, [goalNutrients]);

    return (
        <Container fluid>
        {(nutrients != null && mealPercentage != null) &&(
            <Row>
                <SingleNutrient val = {nutrients.calories} goalVal={goalNutrients.calories} name = "Calories" mealPercentage = {mealPercentage}/>
                <SingleNutrient val = {nutrients.carbohydrates} goalVal={goalNutrients.carbohydrates} name = "Carbs" mealPercentage = {mealPercentage}/>
                <SingleNutrient val = {nutrients.totalfats} goalVal={goalNutrients.totalfats} name = "Total Fats" mealPercentage = {mealPercentage}/>
                <SingleNutrient val = {nutrients.protein} goalVal={goalNutrients.protein} name = "Protein" mealPercentage = {mealPercentage}/>
            </Row>
        )}
        </Container>
    )
}
import './App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Login from './dashboard/Login';
import Onboard_Main from './onboard/Onboard_Main';
import {Layout} from './dashboard/Layout.js';
import { saveSession, loadSession, clearSession } from './utils/SessionManager';

import ErrorContext from './utils/ErrorContext.js';
import SessionContext from './utils/SessionContext.js';

import { ErrorModal } from './tools/ErrorModal.js';
import TitleText from './dashboard/TitleText.js';
import LandingAnimation from './Animations/LandingAnimation.js';
import PrimaryButton from './Components/PrimaryButton.js';

function App() {
    const [mode, setMode] = useState("nonLoggedIn");
    const [userKey, setUserKey] = useState("");
    const [userToken, setToken] = useState(null);
    const [showSignup, setShowSignup] = useState(false);
    const [previousState, setPreviousState] = useState("");
    const [profilePicture, setProfilePicture] = useState(process.env.PUBLIC_URL + '/images/placeholder_profile.png');
    const [goals, setGoals] = useState(null);

    const [displayWelcomeMessage, setDisplayWelcomeMessage] = useState(false);
    
    const [errorText, setErrorText] = useState("");

    const login = async (newUserKey, token) => {
        setUserKey(newUserKey);
        setToken(token);
        setMode("admin");
        setPreviousState("UserDashboard");
        saveSession(newUserKey, token);
    };

    const logout = () => {
        setUserKey("");
        setToken(null);
        setMode("nonLoggedIn");
        window.location.href = "/";
        clearSession();
    };

    const handleSignupComplete = async (formData) => {
        try {
            setShowSignup(false);
            const response = await fetch(process.env.REACT_APP_API_URL + "users/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "email": formData.UserInfo.email,
                    "password": formData.UserInfo.password
                })
            });

            setDisplayWelcomeMessage(true);

            const loginData = await response.json();
            if (loginData.message === "Success") {
                login(loginData.userKey, loginData.token);
                setPreviousState("OnboardSchedule");
            } else {
                console.error("Login failed after signup");
            }
        } catch (error) {
            console.error("Error during signup completion:", error);
        }
    };

    const updateProfilePicture = (newProfilePictureUrl) => {
        setProfilePicture(newProfilePictureUrl);
    };

    return (
        <ErrorContext.Provider value = {{errorText, setErrorText}}>

            {mode === "nonLoggedIn" && !showSignup && (
                <div className="landing-page" style = {{backgroundColor: "#212121"}}>
                    <TitleText />
                    <LandingAnimation />
                    <div className="landing-container">
                        <Login login={login} />
                        <PrimaryButton text = "SIGN UP" onClick={() => setMode("signup")} />
                    </div>
                </div>
            )}
            {mode == "admin" && (
                <SessionContext.Provider value = {{goals, setGoals, profilePicture, updateProfilePicture, userKey, displayWelcomeMessage, setDisplayWelcomeMessage}}>
                    <Layout userKey = {userKey} logout = {logout} />
                </SessionContext.Provider>
            )}
            {mode == "signup" && (
                <Onboard_Main onComplete={handleSignupComplete} />
            )}

            <ErrorModal /> 
        </ErrorContext.Provider>


    );
}

export default App;

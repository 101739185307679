import executeCall from "../utils/APICall.js";
import { useState, useContext } from "react";
import { Container, Form } from "react-bootstrap";
import PrimaryButton from "../Components/PrimaryButton.js";
import ErrorContext from "../utils/ErrorContext.js";

export default function WelcomePFPModal({userKey, close}){

    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { errorText, setErrorText } = useContext(ErrorContext);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setMessage('');
    };
      
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setMessage('Please select a file first.');
            return;
        }
      
        const formData = new FormData();
        formData.append('profilePhoto', file);

        const response = await executeCall(`users/createProfileImage/${userKey}`, 'FILE', setErrorText, formData);
        close();

      };

    return (
        <Container style = {{color: "whitesmoke"}}>
            <h1>Welcome to Plus One Meals.</h1>
            <p>Please enjoy the beta version of this website.</p>
            <p>Plus One Meals will <u>always</u> be 100% free.</p>

            <h1 style = {{marginTop: "100px"}}>Add a profile image</h1>
            <Form.Control className = "mb-4"  type="file" onChange={handleFileChange} />
            <div style = {{marginBottom: "100px"}}></div>

            {file != null && <PrimaryButton className = "mb-4" text = {"SAVE"} onClick = {(e) => handleSubmit(e)}/>}
        </Container>
    );
}
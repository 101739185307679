import { ListGroup } from "react-bootstrap";
import Icon from "../tools/Icon";

export function ListMeal({meal, index, setActive, inverse = false, alternateServings = null}){

    let listGroupItemStyle = inverse ? {
        height: "75px", border: "2px solid black", marginTop: "10px", marginBottom: "5px", boxShadow: "0px 4px 4px 2px rgba(255, 255, 255, 0.3)", backgroundColor: "#157115", fontFamily: "Koulen", color: "whitesmoke", position: "relative"
    } : {
        height: "75px", border: "2px solid black", marginBottom: "5px", boxShadow: "0px 4px 4px 2px rgba(255, 255, 255, 0.3)", backgroundColor: "whitesmoke", fontFamily: "Koulen", position: "relative"
    };

    return (
        <ListGroup.Item key={index} className="flex d-flex align-items-center ml-1 mr-1" 
        style={listGroupItemStyle} onClick={() => setActive(meal.mealKey, meal.mealName)}>
        <div className="flex d-flex flex-direction-row align-items-center justify-content-between w-100">
          <h2 style={{fontFamily: "Koulen", fontStyle: "normal", margin: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 50px)'}}>{meal.mealName}</h2>
          {meal.hasOwnProperty("evaluation") && (
            <div className="ml-auto flex d-flex flex-direction-row">
              <p>Nutritional Score: {meal.evaluation.overall}</p>
            </div>
          )}
          {meal.hasOwnProperty("defaultServings") && (
            <div className="ml-auto d-flex align-items-center">
                <Icon image="servingIcon" size={40} className="m-0" />
                <h1 className="mb-0 ml-3" style={{marginLeft: "auto"}}>{alternateServings ?? meal.defaultServings}</h1>
            </div>
          )}

        </div>
        <div style={{position: "absolute", bottom: 0, left: 0, right: 0, height: "3px", backgroundColor: "green"}}></div>
      </ListGroup.Item>
    )
}
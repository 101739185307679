import React from 'react';
import './NutritionLabel.css';
import pluralize from 'pluralize';

export default function NutritionLabel({nutrients, amount, amountUnit, grams, gramsDisplay = null, blank = null}) {


    let newNutrients = null;
    let nutrientsInfo = {
        calories: null,
        totalfats: null,
        transfat: null,
        saturatedfat: null,
        cholesterol: null,
        sodium: null,
        carbohydrates: null,
        dietaryfiber: null,
        sugars: null,
        protein: null,
      };

    if(blank){
        nutrientsInfo = {
            calories: 0,
            totalfats: 0,
            transfat: 0,
            saturatedfat: 0,
            cholesterol: 0,
            sodium: 0,
            carbohydrates: 0,
            dietaryfiber: 0,
            sugars: 0,
            protein: 0,
          };
    } else {
        if (nutrients == null) {
            return (
              <div>Nutrients could not be found!</div>
            );
          } else {
            newNutrients = Object.entries(nutrients)
            .filter(([key, value]) => typeof value === 'number') // Filter out non-numeric values
            .map(([name, amount]) => ({ name, amount }));
          }
          
          if (newNutrients && Array.isArray(newNutrients)) {
            const nutrientNames = ["calories", "totalfats", "transfat", "saturatedfat", "cholesterol", "sodium", "carbohydrates", "dietaryfiber", "sugars", "protein"];
            nutrientNames.forEach((nutrientName) => {
                let x = newNutrients.find(element => element.name === nutrientName)?.amount ?? 0;
                if(x !== null && x !== undefined){
                    if(grams != null)
                        x = grams * (x / 100);
                    x = x.toFixed(2);
                    nutrientsInfo[nutrientName] = x;
                }
    
            });
          }
    }
      
      

      function removeFirstTwoCharacters(str) {
        if (str.startsWith('1 ')) {
            let newString = str.substring(2);
            if(amount !== 1)
                return pluralize(newString);
            else
                return newString; 
        } else {
            return pluralize(str);
        }
    }

    return (
        <div style = {{backgroundColor: "#212121", width: "95%"}} className = "mt-3 flex d-flex flex-column align-items-center mb-4">
            <section className="performance-facts" style = {{padding: 0, margin: "5px"}}>
            <header className="performance-facts__header" style = {{backgroundColor: "whitesmoke", margin: 0}}>
                <h1 className="performance-facts__title pl-2 m-0 pt-2" style = {{bacgkroundColor: "whitesmoke", color: "#212121", fontWeight: "light"}}>Nutrition Facts</h1>
                {/*<p>Serving Size 1/2 cup (about 82g)*/}
            </header>
            <table className="performance-facts__table" style = {{backgroundColor: "whitesmoke", maxWidth: "100%"}}>
                <thead>
                <tr>
                    <th colSpan="3" className="small-info pl-2">
                    {(amountUnit != null && grams != null) && "Amount Per Serving: " + amount + " " + removeFirstTwoCharacters(amountUnit) + " (" + (gramsDisplay != null ? gramsDisplay : grams) + "g)"}
                    {(amountUnit != null && grams == null) && "Amount Per Serving: " + amount + " " + removeFirstTwoCharacters(amountUnit)}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th colSpan="2" className = "pl-2">
                    <b>Calories </b>{nutrientsInfo.calories != null && nutrientsInfo.calories}
                    </th>
                    <td className = "pr-2">
                    Calories from Fat {(nutrientsInfo.totalfats * 9).toFixed(2)}
                    </td>
                </tr>
                <tr className="thick-row">
                    <td colSpan="3" className="small-info pr-2">
                    <b>% Daily Value*</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan="2" className = "pl-2">
                        <b>Total Fat</b> {nutrientsInfo.totalfats != null && nutrientsInfo.totalfats}g
                    </th>
                    <td>
                    { //TODO - display fat daily value (USDA recommended)
                        //<b>22%</b>
                    }
                    </td>
                </tr>
                <tr>
                    <th colSpan="2" className = "pl-4">
                    Saturated Fat {nutrientsInfo.saturatedfat != null && nutrientsInfo.saturatedfat}g
                    </th>
                    <td className = "pr-2">
                    <b>22%</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan = "2" className = "pl-4">
                    Trans Fat
                    0g
                    </th>
                    <td></td>
                </tr>
                <tr>
                    <th colSpan="2" className = "pl-2">
                    <b>Cholesterol</b> {nutrientsInfo.cholesterol != null && nutrientsInfo.cholesterol}mg
                    </th>
                    <td className = "pr-2">
                    <b>18%</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan="2" className = "pl-2">
                    <b>Sodium</b> {nutrientsInfo.sodium != null && nutrientsInfo.sodium}mg
                    </th>
                    <td className = "pr-2">
                    <b>2%</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan="2" className = "pl-2">
                    <b>Total Carbohydrate</b> {nutrientsInfo.carbohydrates != null && nutrientsInfo.carbohydrates}g
                    </th>
                    <td className = "pr-2">
                    <b>6%</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan = "2" className = "pl-4">
                    Dietary Fiber {nutrientsInfo.dietaryfiber != null && nutrientsInfo.dietaryfiber}g
                    </th>
                    <td className = "pr-2">
                    <b>4%</b>
                    </td>
                </tr>
                <tr>
                    <th colSpan = "2" className = "pl-4">
                    Sugars {nutrientsInfo.sugars != null && nutrientsInfo.sugars}g
                    </th>
                    <td>
                    </td>
                </tr>
                <tr className="thick-end">
                    <th colSpan="2" className = "pl-2">
                    <b>Protein</b> {nutrientsInfo.protein != null && nutrientsInfo.protein}g
                    </th>
                    <td>
                    </td>
                </tr>
                </tbody>
            </table>
            <div style = {{backgroundColor: "whitesmoke"}}>
                <p className="small-info">
                    Calories per gram:
                </p>
                <p className="small-info text-center">
                    Fat 9
                    &bull;
                    Carbohydrate 4
                    &bull;
                    Protein 4
                </p>
            </div>

            </section>
        </div>
    )
}
import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

export default function GoalComparisonBarChart({goalNutrientIndices, foodNutrientIndices}){
    const renameNutrients = (nutrientIndices) => {
        const renamedIndices = {};
        for (const [key, value] of Object.entries(nutrientIndices)) {
            switch(key){
                case 'carbsToTotal':
                    renamedIndices['Carbohydrates'] = (value * 100).toFixed(0);
                    break;
                case 'fatsToTotal':
                    renamedIndices['Fats'] = (value * 100).toFixed(0);
                    break;
                case 'proteinToTotal':
                    renamedIndices['Protein'] = (value * 100).toFixed(0);
                    break;
            }
        }
        return renamedIndices;
    };

    const renamedGoalNutrientIndices = renameNutrients(goalNutrientIndices);
    const renamedFoodNutrientIndices = renameNutrients(foodNutrientIndices);

    const GoalComparisonBarChart = ({ renamedGoalNutrientIndices, renamedFoodNutrientIndices }) => {
        const data = {
            labels: Object.keys(renamedGoalNutrientIndices),
            datasets: [
                {
                    label: 'Your Goal',
                    backgroundColor: '#212121',
                    borderColor: 'rgba(255,255,255,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,255,255,0.3)',
                    hoverBorderColor: 'rgba(255,255,255,1)',
                    data: Object.values(renamedGoalNutrientIndices)
                },
                {
                    label: 'This Meal',
                    backgroundColor: Object.keys(renamedFoodNutrientIndices).map(key => {
                        const goalValue = renamedGoalNutrientIndices[key];
                        const foodValue = renamedFoodNutrientIndices[key];
                        if (Math.abs(goalValue - foodValue) < 7) {
                            return 'rgba(75,192,192,0.4)'; // Close to goal
                        } else {
                            return 'rgba(255,99,132,0.4)'; // Far from goal
                        }
                    }),
                    borderColor: 'rgba(255,255,255,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(153,102,255,0.6)',
                    hoverBorderColor: 'rgba(153,102,255,1)',
                    data: Object.values(renamedFoodNutrientIndices)
                }
            ]
        };

        const options = {
            scales: {
                x: {
                    beginAtZero: true
                },
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                datalabels: {
                    color: '#ffffff', // Change the color of data labels
                    font: {
                        size: 14, // Change the font size of data labels
                        family: 'Arial', // Change the font family of data labels
                        weight: 'bold' // Change the font weight of data labels
                    },
                    anchor: 'end',
                    align: 'top'
                }
            }
            
        };

        return <Bar data={data} options={options} height={150}/>;
    };

    return (<GoalComparisonBarChart renamedGoalNutrientIndices={renamedGoalNutrientIndices} renamedFoodNutrientIndices={renamedFoodNutrientIndices} />);

}
import {useState, useContext, useEffect} from 'react'
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import { ListGroup, Container, Button } from 'react-bootstrap';

export default function AllShoppingLists({userKey, changeShoppingKey}){
    const [shoppingLists, setShoppingLists] = useState([]);
    const {errorText, setErrorText} = useContext(ErrorContext);

    const getShoppingLists = async () => {
        const response = await executeCall(`shopping/allLists/${userKey}`, "GET", setErrorText);
        setShoppingLists(response.shoppingLists);
    }

    useEffect(() => {
        getShoppingLists();
    }, [userKey]);

    return (
        <Container>
            <h1>Shopping Lists</h1>
            <ListGroup>
                {shoppingLists != null && shoppingLists.map((list) => {
                    return (
                        <ListGroup.Item onClick = {() => changeShoppingKey(list.shoppingListKey)} style = {{backgroundColor: "whitesmoke", marginBottom: "10px"}}>
                            <div>Created { new Date(list.dateCreated).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true})}</div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </Container>
    )
}
import {ListGroup, Form, Pagination} from 'react-bootstrap'
import {useState, useEffect, useContext} from 'react'
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import Icon from '../tools/Icon';

export default function AttributeListView({changeAttribute, allowChecked = true,  setParentSelected = null, parentSelected = null}){
    
    const [attributes, setAttributes] = useState(null);
    let [attributeFilter, setAttributeFilter] = useState("");
    
    const itemsPerPage = 10; // Number of items per page
    let [totalPages, setTotalPages] = useState(10);
    const [selected, setSelected] = useState(parentSelected ?? []);

    const {errorText, setErrorText} = useContext(ErrorContext);

     useEffect(() => {
         loadAttributes();
     }, [])

    useEffect(() => {
        setParentSelected(selected);
    }, [selected])


    const loadAttributes = async (reload = false) => {
        const response = await executeCall("foods/attributeList", "GET", setErrorText)
        if("error" in response){
            return;
         }

        let allAttributes = response;
        allAttributes.sort((a, b) => {
            // Convert both names to lowercase for case-insensitive comparison
            const nameA = a.attributeName.toLowerCase();
            const nameB = b.attributeName.toLowerCase();
        
            if (nameA < nameB) {
                return -1; // Name A comes before Name B
            } else if (nameA > nameB) {
                return 1; // Name B comes before Name A
            } else {
                return 0; // Names are equal
            }
        });
        
        setAttributes(allAttributes);
        setTotalPages(10)
    }

    
    const [currentPage, setCurrentPage] = useState(1);

    function setAttribute(attributeKey, attributeName, restrict){
        console.log("executing")
        if(!allowChecked)
            changeAttribute(attributeKey)
        else {
            if(restrict == null){
                let remainingAttributes = selected.filter(key => key.attributeKey != attributeKey);
                setSelected([...remainingAttributes]);
            } else {
                let singleAttribute = selected.filter(key => key.attributeKey == attributeKey);
                
                if(singleAttribute == null || singleAttribute.length == 0){
                    singleAttribute = {attributeName: attributeName, attributeKey: attributeKey, restrict: restrict};
                    let newArray = [...selected];
                    newArray.push(singleAttribute);
                    setSelected(newArray);
                } else {
                    let remainingAttributes = [...selected.filter(key => key.attributeKey != attributeKey)];
                    singleAttribute[0].restrict = restrict;
                    singleAttribute = singleAttribute[0];
                    remainingAttributes.push(singleAttribute);
                    setSelected(remainingAttributes);
                }
            }
        }
    }

    return (
        <>
            <Form.Group controlId="filterInput">
                <Form.Control
                type="text"
                placeholder="Begin typing to see results..."
                value={attributeFilter ?? ""}
                onChange={(e) => setAttributeFilter(e.target.value)}
                />
            </Form.Group>

            {/*List of ingredients*/}
            <ListGroup>
                {(attributeFilter != null && attributes) && attributes
                    //.filter(ingredient => ingredient.ingredientName.toLowerCase().includes(ingredientFilter.toLowerCase()))
                    .slice(((currentPage - 1) * itemsPerPage), (currentPage * itemsPerPage))
                    .map((attribute) => (
                        <div>

                            <ListGroup.Item key = {attribute.attributeKey} style = {{height: "50px"}}>
                                <div className = "flex d-flex flex-direction-row">
                                    {(allowChecked && !selected.some(item => 
                                            item.attributeKey === attribute.attributeKey && item.restrict === false
                                        )) && 
                                        <Icon image = "check" className = "ml-0 mt-0 mr-2" click = {() => setAttribute(attribute.attributeKey, attribute.attributeName, false)} />}
                                    {(allowChecked && selected.some(item => 
                                            (item.attributeKey === attribute.attributeKey && item.restrict === false)
                                        )) &&  <Icon image = "fillcheck" className = "ml-0 mt-0 mr-2" click = {() => setAttribute(attribute.attributeKey, attribute.attributeName, null)} />}
                                    {(allowChecked && !selected.some(item => 
                                            (item.attributeKey === attribute.attributeKey && item.restrict === true)
                                        )) && 
                                        <Icon image = "x" className = "ml-0 mt-0 mr-2"  click = {() => setAttribute(attribute.attributeKey, attribute.attributeName, true)} />}
                                    
                                    {(allowChecked && selected.some(item => 
                                            (item.attributeKey === attribute.attributeKey && item.restrict === true)
                                        )) && 
                                        <Icon image = "redx" className = "ml-0 mt-0 mr-2" click = {() => setAttribute(attribute.attributeKey, attribute.attributeName, null)} />}
                                    {attribute.attributeName}
                                </div>

                            </ListGroup.Item>
                        </div>

                ))}
            </ListGroup>

            {attributes !== null && (
            <Pagination>
                    <Pagination.Prev
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === currentPage}
                            onClick={() => setCurrentPage(i + 1)}
                        >
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            )}
        </>
    )
}
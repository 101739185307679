const addFoodNutrients = (result, food) => {
    let resultCopy = result;
    for(let key in food.nutrients){
        let value;
        value = parseFloat(food.nutrients[key]);
        // If the property doesn't exist in the result, create it
        if (!result.hasOwnProperty(key)) {
            resultCopy[key] = value;
        } else {
            // Otherwise, add the value to the existing property
            resultCopy[key] += value;
        }
    }
    return resultCopy;
}

export function addNutrients(foods){
    let result = {};
    for(let x of foods){
        if(x != null){
            result = addFoodNutrients(result, x)
            if(x.nutrients == null){
                console.log("Error - nutrients does not exist!")
                return;
            }
        }
    }
    return result;
    
}

export function multiplyFoodNutrients(original, factor) {

    let results = {};
    for(let key in original){
        if(typeof original[key] === 'number' && !Number.isInteger(original[key])){
            let value;
            value = parseFloat(original[key]);
            // If the property doesn't exist in the result, create it
            results[key] = parseFloat((value * factor).toFixed(2));
        }
    }
    return results;
}
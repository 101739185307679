import React from 'react';
import { Navbar, Container, Button, Col, Image, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Icon from '../tools/Icon';

function BannerNavItem({location, select, changeLocation, image}){
    return (
        <Nav.Item className = "flex-grow-1 d-flex justify-content-center align-items-center" fluid>
            <div style = {{backgroundColor: location == select && '#7DB6A3', width: "70px", height: "70px", borderRadius: "10px"}}
             className = "d-flex justify-content-center align-items-center">
                <Icon image = {image} click = {changeLocation} size = {50} className = {"mt-0"}></Icon>
            </div>
        </Nav.Item>
    )
}

const Banner = ({location, changeLocation }) => {

    return (
        <Navbar bg="light" variant="dark" style = {{height: "90px", minHeight: "90px", padding: 0}} className = "fixed-bottom">
            <BannerNavItem location = {location} select = {"ingredients"} changeLocation = {() => changeLocation("ingredients")} image = {"ingredient"} />
            <BannerNavItem location = {location} select = {"meals"} changeLocation = {() => changeLocation("meals")} image = {"meal"} />
            <BannerNavItem location = {location} select = {""} changeLocation = {() => changeLocation("")} image = {"today"} />
            {process.env.FEATURE_SCHEDULE && <BannerNavItem location = {location} select = {"schedules"} changeLocation = {() => changeLocation("schedules")} image = {"calendar"} />}
            <BannerNavItem location = {location} select = {"profile"} changeLocation = {() => changeLocation("profile")} image = {"profile"} />
        </Navbar>
    );
};

export default Banner;
import {React, useState, useEffect} from 'react';
import {Container, Row, Nav, Col} from 'react-bootstrap'
import { Outlet } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Banner from './Banner.js';  // Assuming Banner is here
import EditIngredients from '../Ingredient/Ingredients.js';
import Welcome from './Welcome.js';
import { motion, AnimatePresence } from 'framer-motion';
import Meals from '../meal/Meals.js';
import Schedules from '../schedule/Schedules.js'
import Profile from '../Profile/Profile.js';

const RealLayout = ({navigateToProfile, userKey, logout}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [currentPos, setCurrentPos] = useState("");
  let [newPos, setNewPos] = useState("");
  let [transitionDirection, setTransitionDirection] = useState("left");

  useEffect(() => {
    setCurrentPos(newPos);
    navigate(`/${newPos}`);
  }, [transitionDirection])

  useEffect(() => {
    if(currentPos != newPos){
      if (determinePos(newPos) < determinePos(currentPos)) {
        setTransitionDirection("left" + newPos);
      } else {
        setTransitionDirection("right" + newPos);
      }
    }
  }, [newPos]);


  function determinePos(key){
    switch(key){
      case "ingredients":
        return 1;
      case "meals":
        return 2;
      case "":
        return 3;
      case "schedules":
        return 4;
      case "profile":
        return 5;
    }
  }
 
  const handleSelect = (selectedKey) => {
    setNewPos(selectedKey || "");
  };

  return (
    <div style = {{height: "100vh", display: "flex", flexDirection: "column"}}>
      
      {/* The Outlet will render the component for the current route */}
      <Container fluid style={{margin: 0, padding: 0, flexGrow: 1}}>
        <Row style = {{height: "100%"}}>
          <Col>
            {/* AnimatePresence enables animations when components are removed */}
            <AnimatePresence mode={"wait"}>
              <motion.div
                key={location.pathname}
                initial={{ opacity: 0, x: transitionDirection.includes("left") ? -100 : 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: transitionDirection.includes("left") ? 100 : -100 }}
                transition={{ duration: 0.4 }}
                style = {{height: "100%"}}>
                <Routes location={location} key={location.pathname}>
                  <Route path="/" element={<Welcome userKey = {userKey} logout = {logout}/>} />
                  <Route path="/ingredients" element={<EditIngredients source = "homepage"/>} />
                  <Route path="/meals" element={ <Meals />} />
                  <Route path="/schedules" element={<Schedules userKey={userKey}/>} />
                  <Route path="/profile" element={<Profile userKey={userKey} logout = {logout}/>} />
                </Routes>
              </motion.div>
            </AnimatePresence>
          </Col>
        </Row>    
      </Container>

      <Banner location = {newPos} changeLocation = {handleSelect} />

    </div>
  );
};

export const Layout = ({userKey, logout}) => {
  return (
    <Router>
      <RealLayout userKey = {userKey} logout = {logout} />
    </Router>
  )

}
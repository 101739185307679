import {ListGroup} from 'react-bootstrap';
import {useState, useEffect, useContext} from 'react';
import moment from 'moment';
import ErrorContext from '../utils/ErrorContext';
import executeCall from '../utils/APICall';

export default function ScheduleList({userKey, username, setSchedule, current = null}) {
    const [schedules, setSchedules] = useState(null);

    const {errorText, setErrorText} = useContext(ErrorContext)

    const loadSchedules = async() => {
        const response = await executeCall(`plans/schedules/${userKey}`, "GET", setErrorText);
        if("error" in response) return;
        setSchedules(response.schedules);
    }

    useEffect(() => {
        loadSchedules();
    }, []);

    function changeKey(key){
        setSchedule(key);
    }

    return (
        <div style = {{paddingLeft: "15px", width: "100%"}}>
            <h3>Meal Schedules - Other Weeks</h3>
            <ListGroup fluid>
                {(schedules == null || schedules.length === 0) && (
                    <ListGroup.Item style = {{marginRight: "15px", marginTop: "10px"}}><h5>No schedules configured.</h5></ListGroup.Item>
                )}
                {schedules != null && schedules.map((item) => (
                    <ListGroup.Item key = {item.scheduleKey} onClick = {() => changeKey(item.scheduleKey)} fluid className = "mt-3">
                        {moment(item.beginDate).format('dddd, MMMM DD')} - {moment(item.endDate).format('dddd, MMMM DD')}<br></br>
                        {item.scheduleKey} {(current != null && item.scheduleKey == current) && "x"}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button, Form, Image, Placeholder } from 'react-bootstrap';
import { ACTIVITY_LEVELS, MEAL_PREFERENCES, goalLabels, proteinLevels, macroBalances } from './constants';
import './styles.css';
import ErrorContext from '../utils/ErrorContext';
import executeCall from '../utils/APICall';
import RestrictionsModal from './RestrictionsModal';
import PathConstants from '../tools/PathConstants';
import { Link } from 'react-router-dom';
import Icon from '../tools/Icon';
import { CustomModal } from '../Components/CustomModal';
import PrimaryButton from '../Components/PrimaryButton';
import AllShoppingLists from '../shopping/AllShoppingLists';
import ShoppingList from '../shopping/ShoppingList';

export default function Profile({ userKey, updateProfilePicture, logout }) {
    const [profileData, setProfileData] = useState(null);
    const [goalNutrients, setGoalNutrients] = useState({});
    const [username, setUsername] = useState("");

    const [editMode, setEditMode] = useState({ bio: false, lifestyle: false, goals: false, restrictions: false });
    const [manualMacros, setManualMacros] = useState(false);
    const [profilePicture, setProfilePicture] = useState(process.env.PUBLIC_URL + '/images/goofyaaaaahhh.jpg'); // Initial placeholder
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [restrictionCount, setRestrictionCount] = useState(0);

    const [viewBioInfo, setViewBioInfo] = useState(false);

    const [shopping, setShopping] = useState(null);

    const {errorText, setErrorText} = useContext(ErrorContext)

    const loadProfileData = async () => {
        try {
            const response = await executeCall(`users/profile/${userKey}`, "GET", setErrorText);
            if("error" in response) return;
            setUsername(response.profile[0].User.username);
            const profile = response.profile[0];
            setProfileData({
                UserInfo: { userKey: profile.userKey },
                BioInfo: {
                    heightValue: profile.heightValue,
                    heightUnit: profile.heightUnit,
                    weightValue: profile.weightValue,
                    weightUnit: profile.weightUnit,
                    ageValue: profile.ageValue,
                    genderValue: profile.genderValue
                },
                LifestyleInfo: {
                    activity: profile.activity,
                    variety: profile.variety
                },
                GoalsInfo: {
                    goal: profile.goal,
                    protein: profile.protein,
                    macroBalance: profile.macroBalance
                }
            });

            console.log("restriction count", profile.User);
            setRestrictionCount(profile.User.Restrictions.length);

             // Set the profile picture if it exists
            if (response.imageData) {
                setProfilePicture(response.imageData);
            } else {
                // Optionally reset to default placeholder if no image exists
                setProfilePicture(process.env.PUBLIC_URL + '/images/goofyaaaaahhh.jpg');
            }

            const dbGoalNutrients = response.goal.Nutrient;
            if(response.goal.manual){
                setManualMacros(true);
            }
            setGoalNutrients({
                "carbohydrates": dbGoalNutrients.carbohydrates,
                "fats": dbGoalNutrients.totalfats,
                "protein": dbGoalNutrients.protein,
                "calories": dbGoalNutrients.calories
            });
        } catch (error) {
            console.error('Error loading profile data:', error);
        }
    };

    useEffect(() => {
        loadProfileData();
    }, [userKey]);

    const handleEditClick = (section) => {
        setEditMode((prevState) => {
            console.log('Toggling edit mode for', section, 'from', prevState[section], 'to', !prevState[section]);
            return { ...prevState, [section]: !prevState[section] };
        });
    };

    const handleInputChange = (e, section) => {
        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        setProfileData((prevState) => ({
            ...prevState,
            [section]: { ...prevState[section], [name]: value },
        }));
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        handleProfilePictureUpload(e.target.files[0]);
    };

    const handleProfilePictureUpload = async (file) => {
        console.log("Starting profile picture upload");
        setIsUploading(true);

        const formData = new FormData();
        formData.append('profilePhoto', file);

        try {
            const response = await executeCall(`users/createProfileImage/${userKey}`, "FILE", setErrorText, formData);
            console.log(response.newProfileImage);
            if(response.newProfileImage)
                setProfilePicture(response.newProfileImage)
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        } finally {
            setIsUploading(false)
        }
    };

    const triggerFileInput = () => {
        document.getElementById('profilePictureUpload').click();
    };

    const handleSave = async (section, newGoals = false) => {
        try {
            let newProfileData = { ...profileData, manualMacros }
            const response = await executeCall(`users/profile/${userKey}`, "PUT", setErrorText, newProfileData);
            if("error" in response) return;

            if(manualMacros){
                let response2 = await executeCall(`users/manualGoal/${userKey}`, "POST", setErrorText, goalNutrients);
                if("error" in response2) return;
            }

            await loadProfileData();

            setEditMode((prevState) => {
                console.log('Saving data for', section, 'and setting editMode to false');
                return { ...prevState, [section]: false };
            });
            
        } catch (error) {
            console.error('Error saving profile data:', error);
        }
    };

    const handleGoalNutrients = async(type, value) => {
        let nutrientsCopy = {...goalNutrients};
        switch(type){
            case "carbohydrates":
                nutrientsCopy.carbohydrates = value;
                break;
            case "fats":
                nutrientsCopy.fats = value;
                break;
            case "protein":
                nutrientsCopy.protein = value;
                break;
            case "calories":
                nutrientsCopy.calories = value;
                break;
        }
        nutrientsCopy.calories = (nutrientsCopy.carbohydrates * 4) + (nutrientsCopy.protein * 4) + (nutrientsCopy.fats * 9)
        if(manualMacros)
            setGoalNutrients(nutrientsCopy);
    }

    if (!profileData) {
        return <div>Loading...</div>;
    }

    

    


    return (
        <Container fluid className="p-4" style={{ backgroundColor: "#212121" }}>
            <div className="d-flex justify-content-end">
                <Button className="ml-auto mr-2" variant="danger" onClick={logout}>
                    Logout
                </Button>
            </div>
            
            <Row className="justify-content-center">
                <Col md="auto" className="text-center">
                    <div className="profile-picture-container">
                        <Image src={profilePicture} roundedCircle className="img-circle mb-4" />
                        <div className="profile-picture-overlay" onClick={triggerFileInput}>
                            Change photo
                        </div>
                    </div>
                    {/* Hidden file input */}
                    <input
                        type="file"
                        id="profilePictureUpload"
                        className="hidden-file-input"
                        onChange={handleFileChange}
                        accept="image/*"
                        disabled={isUploading}
                    />
                    <h1 className = "mt-3" style = {{color: "whitesmoke"}}>{username}</h1>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <PrimaryButton text = {"ALL SHOPPING LISTS"} onClick = {() => setShopping(true)}/>
            </Row>
            <Row className="justify-content-center mt-4">
                <Col md={8} lg={6}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 style = {{fontFamily: "Koulen"}} className="mb-0"><u>What's the goal?</u></h5>
                            {!editMode.goals && <Icon image = "edit" click = {() => handleEditClick('goals')}/>}
                        </Card.Header>
                        <Card.Body>
                            {editMode.goals ? (
                                <Form>
                                    {!manualMacros && (
                                        <Form.Group controlId="formGoal">
                                            <Form.Label>Goal</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="goal"
                                                value={profileData.GoalsInfo.goal}
                                                onChange={(e) => handleInputChange(e, 'GoalsInfo')}
                                            >
                                                {goalLabels.map((label, index) => (
                                                    <option key={index} value={index - 3}>
                                                        {label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    {!manualMacros && (
                                        <Form.Group controlId="formProtein">
                                            <Form.Label>Protein Level</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="protein"
                                                value={profileData.GoalsInfo.protein}
                                                onChange={(e) => handleInputChange(e, 'GoalsInfo')}
                                            >
                                                {Object.entries(proteinLevels).map(([key, label]) => (
                                                    <option key={key} value={key}>
                                                        {label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    
                                    <Form.Group controlId="formMacroBalance" className = "flex d-flex align-items-center justify-content-center">
                                        <Form.Check
                                        className = "mt-3"
                                            type="checkbox"
                                            label="Input Macronutrients Manually"
                                            checked={manualMacros}
                                            onChange={(e) => setManualMacros(!manualMacros)}
                                        />
                                    </Form.Group>
                                    {manualMacros && (
                                        <div>
                                            <Form.Group>
                                                <Form.Label>Carbohydrates</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value = {goalNutrients.carbohydrates}
                                                    onChange = {(e) => handleGoalNutrients("carbohydrates", e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Fats</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value = {goalNutrients.fats}
                                                    onChange = {(e) => handleGoalNutrients("fats", e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Protein</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value = {goalNutrients.protein}
                                                    onChange = {(e) => handleGoalNutrients("protein", e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Calories</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value = {goalNutrients.calories}
                                                    disabled />
                                            </Form.Group>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button variant="success" onClick={() => handleSave('goals', true)}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            ) : (
                                <>
                                    {!manualMacros && (
                                        <>
                                            <p>Goal: {goalLabels[profileData.GoalsInfo.goal + 3]}</p>
                                            <p>Protein Level: {proteinLevels[profileData.GoalsInfo.protein]}</p>
                                            <p>Macro Balance: {macroBalances[profileData.GoalsInfo.macroBalance]}</p>
                                        </>

                                    )}

                                    {(goalNutrients != null && goalNutrients != {} && manualMacros) && (
                                        <div>
                                            <p>Carbs: {goalNutrients.carbohydrates}</p>
                                            <p>Fats: {goalNutrients.fats}</p>
                                            <p>Protein: {goalNutrients.protein}</p>
                                            <p>Calories: {goalNutrients.calories}</p>
                                        </div>

                                    )}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={8} lg={6}>

                    <Card className="mb-4 shadow-sm">
                        <Card.Header className="d-flex align-items-center">
                            <h5 style = {{fontFamily: "Koulen"}} className="mb-0"><u>Biological Information</u></h5>
                            {!editMode.bio &&  <Icon className = "ml-auto mr-3" click = {() => setViewBioInfo(!viewBioInfo)} image = "eye" />}
                            {!editMode.bio && <Icon className = {`${editMode.bio && "ml-auto"}`} click = {() => handleEditClick('bio')} image = "edit"/>}
                        </Card.Header>
                        <Card.Body style = {(viewBioInfo || editMode.bio) ? {} : { padding: 0 }}>
                            {editMode.bio ? (
                                <Form>
                                    <Form.Group controlId="formHeightValue">
                                        <Form.Label>Height</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="heightValue"
                                            value={profileData.BioInfo.heightValue}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formHeightUnit">
                                        <Form.Label>Height Unit</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="heightUnit"
                                            value={profileData.BioInfo.heightUnit}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        >
                                            <option>in</option>
                                            <option>cm</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="formWeightValue">
                                        <Form.Label>Weight</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="weightValue"
                                            value={profileData.BioInfo.weightValue}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formWeightUnit">
                                        <Form.Label>Weight Unit</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="weightUnit"
                                            value={profileData.BioInfo.weightUnit}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        >
                                            <option>lbs</option>
                                            <option>kg</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="formAgeValue">
                                        <Form.Label>Age</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="ageValue"
                                            value={profileData.BioInfo.ageValue}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formGenderValue">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="genderValue"
                                            value={profileData.BioInfo.genderValue}
                                            onChange={(e) => handleInputChange(e, 'BioInfo')}
                                        >
                                            <option>M</option>
                                            <option>F</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button variant="success" onClick={() => handleSave('bio')}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            ) : (
                                viewBioInfo ? (
                                    <>
                                        <p>Height: {profileData.BioInfo.heightValue} {profileData.BioInfo.heightUnit}</p>
                                        <p>Weight: {profileData.BioInfo.weightValue} {profileData.BioInfo.weightUnit}</p>
                                        <p>Age: {profileData.BioInfo.ageValue}</p>
                                        <p>Gender: {profileData.BioInfo.genderValue}</p>
                                    </>
                                ) : (
                                    <div className = "dill-placeholder" style = {{margin: 0, paddingTop: "150px"}}>
                                    </div>
                                )
                               
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md={8} lg={6}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0" style = {{fontFamily: "Koulen"}}><u>Lifestyle Information</u></h5>
                            {!editMode.lifestyle && <Icon className = {`${editMode.bio && "ml-auto"}`} click = {() => handleEditClick('lifestyle')} image = "edit"/>}
                        </Card.Header>
                        <Card.Body>
                            {editMode.lifestyle ? (
                                <Form>
                                    <Form.Group controlId="formActivity">
                                        <Form.Label>Activity Level</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="activity"
                                            value={profileData.LifestyleInfo.activity}
                                            onChange={(e) => handleInputChange(e, 'LifestyleInfo')}
                                        >
                                            {Object.entries(ACTIVITY_LEVELS).map(([key, label]) => (
                                                <option key={key} value={key}>
                                                    {label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="formVariety">
                                        <Form.Label>Meal Preferences</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="variety"
                                            value={profileData.LifestyleInfo.variety}
                                            onChange={(e) => handleInputChange(e, 'LifestyleInfo')}
                                        >
                                            {Object.entries(MEAL_PREFERENCES).map(([key, label]) => (
                                                <option key={key} value={key}>
                                                    {label}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button variant="success" onClick={() => handleSave('lifestyle')}>
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            ) : (
                                <>
                                    <p>Activity Level: {ACTIVITY_LEVELS[profileData.LifestyleInfo.activity]}</p>
                                    <p>Meal Preferences: {MEAL_PREFERENCES[profileData.LifestyleInfo.variety]}</p>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            <Row className="justify-content-center" style = {{marginBottom: "100px"}}>
                <Col md={8} lg={6}>
                    <Card className="mb-4 shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5>
                                Dietary Restrictions
                            </h5>
                            <Button variant="outline-primary" onClick={() => handleEditClick('restrictions')}>
                                {editMode.restrictions ? 'Cancel' : 'Edit'}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <h6>You have {restrictionCount} dietary restrictions.</h6>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {shopping === true && (
                <CustomModal modalBody = {
                    <AllShoppingLists userKey = {userKey} changeShoppingKey={setShopping}/>
                } close = {() => setShopping(null)}/>
            )}
            {shopping != null && shopping !== true && (
                <CustomModal modalBody = {
                    <ShoppingList userKey={userKey} shoppingListKey={shopping} />
                } close = {() => setShopping(null)}/>
            )}

            {editMode.restrictions && <CustomModal modalBody = {
                <RestrictionsModal userKey = {userKey} show = {editMode.restrictions} close = {() => handleEditClick('restrictions')}/>
            }
            close = {() => setEditMode({...editMode, restrictions: false})}
            />}
        </Container>
    );
}

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Card, Col, Button } from 'react-bootstrap';
import executeCall from "../utils/APICall";
import ErrorContext from "../utils/ErrorContext";
import ListIngredient from "../Components/ListIngredient";
import SingleMealDetail from "../meal/SingleMealDetail";
import Icon from "../tools/Icon";
import PrimaryButton from "../Components/PrimaryButton";

export default function RecommendedMeals({userKey, close, reload}){
    const { errorText, setErrorText } = useContext(ErrorContext);
    const [recommendedMeals, setRecommendedMeals] = useState(null);
    const [currentMeal, setCurrentMeal] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [introMessageDisplaying, setIntroMessageDisplaying] = useState(true);

    const [mealCount, setMealCount] = useState(2);
    const [mealsFound, setMealsFound] = useState([]);

    const [cart, setCart] = useState([]);

    const cardsData = [
        { id: 1, title: "Card 1", text: "This is the first card." },
        { id: 2, title: "Card 2", text: "This is the second card." },
        { id: 3, title: "Card 3", text: "This is the third card." },
      ];

    async function loadRecommendedMeals(){
        const response = await executeCall(`plans/recommendedMeals/${userKey}`, "GET", setErrorText);
        console.log(response.results);
        setRecommendedMeals(response.results);
    }
    

    useEffect(() => {
        loadRecommendedMeals();
    }, [userKey]);

    useEffect(() => {
        if(recommendedMeals != null){
            setCurrentMeal(recommendedMeals[0]);
        }
    }, [recommendedMeals]);

    useEffect(() => {
        if(recommendedMeals != null){
            setCurrentMeal(recommendedMeals[currentIndex]);
        }
    }, [currentIndex]);

    
    const addExclusion = async (mealKey) => {
        let response = await executeCall(`plans/handleExclusion/${userKey}/${mealKey}`, "POST", setErrorText);
        if(response.message == "SUCCESS")
            setCurrentIndex((prevIndex) => prevIndex + 1);
    }

    const handleSwipe = async (direction) => {
        if(direction == "Like"){
            let allMeals = [...mealsFound];
            allMeals.push(currentMeal.mealkey);
            setMealsFound(allMeals)
        } else if (direction == "Meh"){
            setCurrentIndex((prevIndex) => prevIndex + 1);
        } else if (direction == "Definitely Not"){
            addExclusion(currentMeal.mealkey);

        }
    };

    useEffect(() => {
        if(mealsFound != [] && mealCount != null && currentMeal != null){
            let newCart = cart == null ? [] : [...cart];
            console.log("currentMeal", currentMeal)
            for(let x of currentMeal.mealIngredients){
                newCart.push(x);
            }
            setCart(newCart);
        }
    }, [mealsFound])

    useEffect(() => {
        if(cart != null){
            if(mealsFound.length == mealCount){
                saveShoppingList();
            } else {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }
        }
    }, [cart])

    const saveShoppingList = async () => {
        const response = await executeCall(`shopping/create/${userKey}`, "POST", setErrorText, {list: cart});
        close();
    }
  

    return (
        <Container className="d-flex justify-content-center align-items-center" fluid >
            {introMessageDisplaying && (
                <div>
                    <h1 style = {{marginBottom: "25px", marginTop: "25px", color: "whitesmoke"}}>
                        Like a meal to put it in your shopping cart
                    </h1>
                    <h1 style = {{marginBottom: "25px", marginTop: "25px", color: "whitesmoke"}}>
                        Select "Meh" if you want to see the meal again, but you're not feeling it right now.
                    </h1>
                    <h1 style = {{marginBottom: "25px", marginTop: "25px", color: "whitesmoke"}}>
                        Choose "Definitely not" if you never want to see the meal again.
                    </h1>
                    <h1 style = {{marginBottom: "25px", marginTop: "75px", color: "whitesmoke"}}>
                        How many meals do you want to pick out today?
                    </h1>
                    <Row className = "flex d-flex justify-content-center align-items-center p-0">
                        {mealCount > 1 && <Icon image = "minus" click = {() => setMealCount(mealCount - 1)}/>}
                        <h1 className = "ml-3 mr-3" style = {{color: "whitesmoke", fontSize: "70px", marginTop: "10px"}}>{mealCount}</h1>
                        {mealCount < 7 && <Icon image = "lightplus" click = {() => setMealCount(mealCount + 1)}/>}
                    </Row>
                    <PrimaryButton className = "mb-4" text = {"GET STARTED"} onClick = {() => setIntroMessageDisplaying(false)}/>
                </div>
            )}
            {(currentMeal && !introMessageDisplaying) ? (
                <Card style={{ width: "100%", margin: 0, backgroundColor: "#212121"}}>

                    <SingleMealDetail mealKey={currentMeal.mealkey} userKey = {userKey} edit = {false}/>
                    <Row>
                        <Col>
                        <Button
                            variant="danger"
                            onClick={() => handleSwipe("Definitely Not")}
                            className="w-100"
                        >
                            Definitely Not
                        </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="success"
                                style = {{backgroundColor: "#FFD300", color: "black", fontFamily: "Koulen"}}
                                onClick={() => handleSwipe("Meh")}
                                className="w-100"
                            >
                                Meh
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="success"
                                onClick={() => handleSwipe("Like")}
                                className="w-100"
                            >
                                Like
                            </Button>
                        </Col>
                    </Row>
                </Card>
            ) : (
                <div>
                    {!introMessageDisplaying && (
                        <h3>No more cards!</h3>
                    )}
                </div>
            )}
        </Container>
    );
}
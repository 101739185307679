import {ListGroup, Row, Badge} from 'react-bootstrap';
import { multiplyFoodNutrients } from '../sharedFunctions/addNutrients.js';


function NutrientBadge({nutrient, name, badgetype}){

    if(nutrient )

    return (
        <Badge bg={badgetype} style={{height: "30px", marginLeft: "15px", color: 'white', display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px"}}>
            {(nutrient != null && typeof nutrient == 'number') && nutrient.toFixed(2)} {name}
        </Badge>
    )
}

export function ScheduledMeal({meal, servings, setOpenMeal}){

    let newNutrients = null;
    if(meal != null){
        let nutrient = meal.Nutrient;
        newNutrients = multiplyFoodNutrients(nutrient, (servings / meal.defaultServings))
    }


    return (
            <div className='d-flex flex-directipon-row' onClick = {() => setOpenMeal()}> 
                <ListGroup.Item  
                    style = {{width: "100%", marginTop: "20px"}}
                    className = "layered-box">
                        <Row className = "align-items-center">
                            <h5 style = {{paddingLeft: "15px", fontWeight: "bold", marginBottom: "0px"}}>{meal.mealName}</h5>
                            <NutrientBadge nutrient = {newNutrients.calories} name = "Calories" badgetype="primary" />
                            <NutrientBadge nutrient = {newNutrients.carbohydrates} name = "g Carbs" badgetype="secondary" />
                            <NutrientBadge nutrient = {newNutrients.protein} name = "g Protein" badgetype="success" />
                            <NutrientBadge nutrient = {newNutrients.totalfats} name = "g Fats" badgetype="dark" />
                            <p className = "ml-auto" style = {{fontWeight: "bolder", fontSize: "16px", marginTop: "0px", marginBottom: "0px"}}>{servings} Servings</p>                                              
                        </Row>
                </ListGroup.Item>
        </div>
    );
}


import Icon from "../tools/Icon";

export default function ListAttribute({image, color, size = "large", editing = false, removeAttribute = null}){

    if(image == "contains_gluten" || image == "contains_wheat"){
        image = "wheat";
        color = "#DAA520"
    } else if(image == "contains_alcohol"){
        image = "alcoholic beverages"
        color = "#800000"
    }

    let height, width, iconSize, borderRadius;
    if(size == "large"){
        height = "50px"
        width = "50px"
        iconSize = "30px"
        borderRadius = "10px"
    } else {
        height = "30px"
        width = "30px"
        iconSize = "20px"
        borderRadius = "6px"
    }
        


    return (
        <div style = {{height: height, width: width, backgroundColor: color, border: "2px solid black", borderRadius: borderRadius, position: "relative"}}
        className = "flex d-flex align-items-center justify-content-center mr-3">
            <Icon size = {iconSize} click = {() => {return;}} image = {"attributes/" + image}/>
            {editing && (
              <div
              style={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={(e) => {e.stopPropagation(); removeAttribute()}}>
              ×
            </div>
            )}
        </div>
    )
}
export const activityLevels = {
    1: "Sedentary",
    2: "Low-Stress",
    3: "Moderate",
    4: "Active",
    5: "Extremely Active",
  };
  
  export const mealPreferences = {
    true: "Cook More",
    false: "Repetitive Meals",
  };
  
  export const goalLabels = [
    "Lose weight quickly",
    "Lose some weight",
    "Lose a bit of weight",
    "Maintain my weight",
    "Gain a bit of weight",
    "Gain some weight",
    "Gain weight quickly",
  ];
  
  export const proteinLevels = {
    1: "Balanced",
    2: "Moderate",
    3: "Athletic",
  };
  
  export const macroBalances = {
    0: "Zero carbs",
    1: "Fewer carbs",
    2: "Balanced amount",
    3: "Fewer fats",
  };

  export const decodeActivityLevel = (level) => {
    return activityLevels[level] || "";
  };
  
  export const decodeMealPreference = (preference) => {
    return mealPreferences[preference] || "Choose Meal Preference";
  };
  

  
  export const decodeGoal = (value) => {
    return goalLabels[value + 3] || "";
  };
  
  export const decodeProtein = (value) => {
    return proteinLevels[value] || "";
  };
  
  export const decodeMacroBalance = (value) => {
    return macroBalances[value] || "";
  };
  
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';

export default function AlertModal({alertType, text, confirm, close}){
    const [backgroundColor, setBackgroundColor] = useState("");
    useEffect(() => {
        
        if(alertType != null && typeof alertType == "string"){
            console.log(alertType)
            switch(alertType.toLowerCase()){
                case "warning":
                    console.log("works");
                    setBackgroundColor("#D5B60A");
                    break;
                default:
                    break;
            }
    
        }
    }, [alertType])

    return (
        <div className="modal show" style={{ display: 'block' }}>
            <Modal.Dialog style={{ marginTop: "10%", maxWidth: '50vw' }}>
                <Modal.Header style = {{backgroundColor: backgroundColor}}>
                    <Modal.Title>{alertType}</Modal.Title>
                    <img style = {{height: "20px", marginTop: "10px", marginLeft: "10px", float: "right"}}
                        alt = "x" 
                        src={process.env.PUBLIC_URL + "/images/x.png"} 
                        onClick={() => close()}/>
                </Modal.Header>

                <Modal.Body>
                    <h3>{text}</h3>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => confirm()}>Confirm</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}
import React from 'react';

const TitleText = () => {
  return (
    <div className="title-text-wrapper" style = {{color: "whitesmoke"}}>
      <div className="fullcircle-text" style = {{color: "whitesmoke"}}>Plus One Meals</div>
    </div>
  );
};

export default TitleText;

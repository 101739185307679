import { Container, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import Icon from "../../tools/Icon";

function MealGroup({day, mode, type, groupType, count, add, remove}){
    return (
        <div style = {{width: "100%"}}>
            <Row className = "flex d-flex align-items-center justify-content-center">
                <Icon image = "minus" click = {() => remove(day)} className = "mr-3"/>
                <h1 className = "pt-2">{count}</h1>
                <Icon image = "lightplus" click = {() => add(day)} className = "ml-3"/>
            </Row>
            <Row className="flex d-flex align-items-center justify-content-center">
                <h1>{type}</h1>
            </Row>
        </div>
    )

}

export default function DayMealModel({dayCount, optState, optSet, exceptionString = null}){

    useEffect(() => {
        console.log(optState);

    }, [optState])

    const addMeal = (i, type) => {        
        let newDayMeals = optState.split('|');
        newDayMeals[i] = newDayMeals[i] + type;
        newDayMeals = newDayMeals.join("|")

        optSet(newDayMeals);
    }

    const deleteMeal = (i, type) => {
        let newDayMeals = optState.split('|')
        if (i >= 0 && i < newDayMeals.length) {
            const index = newDayMeals[i].lastIndexOf(type);
            if (index !== -1) {
                newDayMeals[i] = newDayMeals[i].slice(0, index) + newDayMeals[i].slice(index + 1);
            }
        }
        newDayMeals = newDayMeals.join('|');
        optSet(newDayMeals);
    }


    if(optState != null) {
        return (
            <Container fluid>
                <Row className = "flex d-flex flex-column align-items-center justify-content-center mb-3">
                    {Array.from({ length: dayCount }, (_, i) => {
                        const displayMeals = optState.split('|')[i] ?? "";
                        console.log(displayMeals);
                        const mealCountMatches = displayMeals.match(/M/g);
                        const mealCount = mealCountMatches ? mealCountMatches.length : 0

                        const snackCountMatches = displayMeals.match(/S/g);
                        const snackCount = snackCountMatches ? snackCountMatches.length : 0;
                        console.log(snackCount);

                        const cheatCountMatches = displayMeals.match(/X/g);
                        const cheatCount = cheatCountMatches ? cheatCountMatches.length : 0
                        return (
                                <div className = "d-flex flex-column mt-3" key = {i} style = {{border: "1px solid black", width: "90%"}}>
                                    <h4 style = {{margin: "10px"}}>Day {i + 1}</h4>
                                    <MealGroup day = {i} 
                                        type = "Meals"
                                        count = {mealCount} 
                                        add = {() => addMeal(i, "M")} 
                                        remove = {() => deleteMeal(i, "M")}/>
                                    <MealGroup day = {i} 
                                        type = "Snacks"
                                        count = {snackCount} 
                                        add = {() => addMeal(i, "S")} 
                                        remove = {() => deleteMeal(i, "S")}/>

                                </div> 
                            );
                        })
                    }
    
                </Row>
            </Container>
        )
    } else {
        return (<div></div>)
    }

}
import {Container, Row, Col} from 'react-bootstrap'
 
function calculateColor(value1, value2) {
    // Calculate the variance as a percentage
    const variance = Math.abs(value1 - value2);
    
    // Clamp the variance between 0 and 10 for color calculation
    const clampedVariance = Math.min(10, Math.max(0, variance));

    // Calculate the color based on variance
    let red, green, blue = 0; // We'll only change red and green

    if (clampedVariance < 5) {
        // From Green to Yellow (0% to 5% variance)
        red = Math.floor((clampedVariance / 5) * 255); // Transition to yellow
        green = 255; // Full green
    } else {
        // From Yellow to Red (5% to 10% variance)
        red = 255; // Full red
        green = Math.floor((1 - (clampedVariance - 5) / 5) * 255); // Decrease green
    }

    return `rgb(${red}, ${green}, ${blue})`;
}

function NutrientDisplay({text, value, goal}){
    let color = calculateColor(value, goal)

    const circleStyle = {
        border: `3px solid ${color}`,
        borderRadius: '50%',
        width: '120px', // Set fixed width
        height: '120px', // Set fixed height
        display: 'flex', // Use flexbox to center content
        alignItems: 'center', // Center vertically
        justifyContent: 'center', // Center horizontally
      };
    
    return (
        <Col className = "text-center flex d-flex flex-column align-items-center">
            <div style = {circleStyle} className = "flex-column">
                <h4 style = {{fontWeight: "bolder"}}>{value}</h4>
                <div>{text}</div>
            </div>
        </Col>
    )
}

 export default function DayNutrients({nutrients, goals}){
    console.log("goals is")
    console.log(goals);
    return (
        <Container fluid style = {{backgroundColor: "#eeeeee" }}>
            <Row className = "pt-3">
                <h5 style = {{marginLeft: "15px"}}><u>Nutrients</u></h5>
            </Row>
            <Row className = "pt-3 pb-3">
                <NutrientDisplay text = "Calories" value = {nutrients.calories.toFixed(0)} goal = {goals.Nutrient.calories}/>
                <NutrientDisplay text = "Carbohydrates" value = {nutrients.carbohydrates.toFixed(0)} goal = {goals.Nutrient.carbohydrates}/>
                <NutrientDisplay text = "Fats" value = {nutrients.totalfats.toFixed(0)} goal = {goals.Nutrient.totalfats}/>
                <NutrientDisplay text = "Protein" value = {nutrients.protein.toFixed(0)} goal = {goals.Nutrient.protein}/>
            </Row>
        </Container>
    )

}                       
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom fonts */
h1 {
  font-family: "Koulen", sans-serif;
  font-size: 32px;
  font-style: normal;
  color: "whitesmoke"
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: italic;
}

h3{
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 12em;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,iBAAiB;AACjB;EACE,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB;AACF;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,iBAAiB;AACnB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n/* custom fonts */\nh1 {\n  font-family: \"Koulen\", sans-serif;\n  font-size: 32px;\n  font-style: normal;\n  color: \"whitesmoke\"\n}\n\nh2 {\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 24px;\n  font-style: italic;\n}\n\nh3{\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 20px;\n  font-weight: 600;\n}\n\np {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: 12em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

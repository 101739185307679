import { Modal, Button } from "react-bootstrap";
import EditIngredients from '../../Ingredient/Ingredients.js';


export default function IngredientsModal({ token, choose, close }) {
    return (
        <div className="modal show" style={{ display: 'block' }}>
            <Modal.Dialog style={{ maxWidth: '90vw', maxHeight: '130vh', overflow: 'hidden' }}>
                <Modal.Header>
                    <Modal.Title>Add an Ingredient</Modal.Title>
                    <img 
                        style={{ height: "20px", marginTop: "10px", marginLeft: "10px", float: "right" }}
                        alt="x"
                        src={process.env.PUBLIC_URL + "/images/x.png"} 
                        onClick={() => close()}
                    />
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => close()}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
}
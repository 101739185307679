import React, {useState} from "react";
import { Form, Button, ButtonGroup, Container, Row, Col, Card } from "react-bootstrap";
import './onboard.css';
import { validate } from "uuid";
import Icon from "../tools/Icon";

//For reference - 4'9 is 57 inches
let heightValueOptions = [
  "4'9", "4'10", "4'11",
  "5'0", "5'1", "5'2", "5'3", "5'4", "5'5", "5'6", "5'7", "5'8", "5'9", "5'10", "5'11",
  "6'0", "6'1", "6'2", "6'3", "6'4", "6'5", "6'6", "6'7", "6'8"
];

const Onboard_1 = ({ formState, setFormState, next, prev }) => {
  
  let initialHeightValue = 10;

  let [weightInError, setWeightInError] = useState(false);

  // Handlers for input changes
  const handleHeightChange = (direction) => {
    let newHeightValue = formState.BioInfo.heightValue;
    if(direction == "up"){
      newHeightValue = newHeightValue + 1;
    } else {
      newHeightValue = newHeightValue - 1;
    }
    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, heightValue: newHeightValue},
    });
  };

  const handleHeightUnitChange = (selectedUnit) => {
    
    let newHeightValue = formState.BioInfo.heightValue;
    if(selectedUnit == "cm" && formState.BioInfo.heightUnit == "in"){
      newHeightValue = newHeightValue + 57;
      newHeightValue = parseInt((newHeightValue * 2.54).toFixed(2));
    } else if(selectedUnit == "in" && formState.BioInfo.heightUnit == "cm"){
      newHeightValue = newHeightValue / 2.54;
      newHeightValue = parseInt((newHeightValue - 57).toFixed(2));
    }

    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, heightUnit: selectedUnit, heightValue: newHeightValue },
    });
  };

  const handleWeightChange = (event) => {
    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, weightValue: parseInt(event.target.value) || "" },
    });
  };

  const handleWeightUnitChange = (selectedUnit) => {
    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, weightUnit: selectedUnit },
    });
  };

  const handleAgeChange = (direction) => {
    let newAgeValue = formState.BioInfo.ageValue;
    if(direction == "up"){
      newAgeValue = newAgeValue + 1;
    } else {
      newAgeValue = newAgeValue - 1;
    }
    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, ageValue: newAgeValue},
    });
  };

  const handleGenderChange = (gender) => {
    setFormState({
      ...formState,
      BioInfo: { ...formState.BioInfo, genderValue: gender },
    });
  };

  const validateThenNext = () => {
    let error = false;
    if(!("weightValue" in formState.BioInfo) || formState.BioInfo.weightValue == "" || isNaN(formState.BioInfo.weightValue)){
        setWeightInError(true);
        error = true;
    } 

    if(!error)
        next();
  }

  return (
    <Container fluid className="onboard-container" style = {{backgroundColor: "#212121", overflowX: "hidden"}}>
      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8} lg={6} className = "flex d-flex align-items-center flex-column">
          <h1 className="text-center mb-4" style = {{color: "whitesmoke"}}><u>Personal Info</u></h1>
          <h3 className = "text-center mr-2 ml-2" style = {{fontFamily: "Koulen", color: "whitesmoke", fontWeight: "normal"}}>This information is not visible on your profile.</h3>
          <h3  className = "text-center mr-2 ml-2 mt-2" style = {{fontFamily: "Koulen", color: "whitesmoke", fontWeight: "normal"}}>However, we need to collect it in order to build a customized meal plan for you.</h3>
          <h1 className = "mt-4" style = {{color: "whitesmoke"}}>Weight</h1>
          <Form.Control
              type="text"
              placeholder="Enter a number"
              style = {{backgroundColor: weightInError ? "#e09e9d" : "whitesmoke"}}
              value={formState.BioInfo.weightValue}
              onChange={handleWeightChange}
          />
          <ButtonGroup>
            <Button variant={formState.BioInfo.weightUnit === "lbs" ? "primary" : "secondary"} onClick={() => handleWeightUnitChange("lbs")}>
              Pounds
            </Button>
            <Button variant={formState.BioInfo.weightUnit === "kg" ? "primary" : "secondary"} onClick={() => handleWeightUnitChange("kg")}>
              Kilograms
            </Button>
          </ButtonGroup>

          <h1 className = "mt-3" style = {{color: "whitesmoke"}}>Height</h1>
          {/*<Form.Control
                    type="text"
                    placeholder="Enter a number"
                    value={formState.BioInfo.heightValue}
                    onChange={handleHeightChange}
          />*/}
          <Row className = "flex d-flex justify-content-center align-items-center p-0">
            {((formState.BioInfo.heightUnit == "in" && formState.BioInfo.heightValue > 0) 
            || formState.BioInfo.heightUnit == "cm" && formState.BioInfo.heightValue > 144) && 
              <Icon image = "minus" click = {() => handleHeightChange("down")}/>}
            <h1 className = "ml-3 mr-3" style = {{color: "whitesmoke", fontSize: "70px", marginTop: "10px"}}>
              {formState.BioInfo.heightUnit == "in" ? heightValueOptions[formState.BioInfo.heightValue] : formState.BioInfo.heightValue}
            </h1>
            {((formState.BioInfo.heightUnit == "in" && formState.BioInfo.heightValue < heightValueOptions.length - 1)
            || formState.BioInfo.heightUnit == "cm" && formState.BioInfo.heightValue < 205) && 
              <Icon image = "lightplus" click = {() => handleHeightChange("up")}/>}
          </Row>
          <ButtonGroup>
            <Button variant={formState.BioInfo.heightUnit === "in" ? "primary" : "secondary"} onClick={() => handleHeightUnitChange("in")}>
              Inches
            </Button>
            <Button variant={formState.BioInfo.heightUnit === "cm" ? "primary" : "secondary"} onClick={() => handleHeightUnitChange("cm")}>
              Centimeters
            </Button>
          </ButtonGroup>

          <h1 className = "mt-3" style = {{color: "whitesmoke"}}>Age</h1>
          {/*<Form.Control
                    type="text"
                    placeholder="Enter a number"
                    value={formState.BioInfo.ageValue}
                    onChange={handleAgeChange}
          />*/}
          <Row className = "flex d-flex justify-content-center align-items-center p-0">
            {formState.BioInfo.ageValue > 16 && <Icon image = "minus" click = {() => handleAgeChange("down")}/>}
            <h1 className = "ml-3 mr-3" style = {{color: "whitesmoke", fontSize: "70px", marginTop: "10px"}}>{formState.BioInfo.ageValue}</h1>
            <Icon image = "lightplus" click = {() => handleAgeChange("up")}/>
          </Row>
          <h1 style = {{color: "whitesmoke"}} className = "mt-3">Sex (assigned at birth)</h1>
          <ButtonGroup>
            <Button
              variant={formState.BioInfo.genderValue === "M" ? "primary" : "secondary"}
              onClick={() => handleGenderChange("M")}
            >
              Male
            </Button>
            <Button
              variant={formState.BioInfo.genderValue === "F" ? "primary" : "secondary"}
              onClick={() => handleGenderChange("F")}
            >
              Female
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      <Row className="justify-content-center mb-4">
          <div className = "mt-3 flex d-flex align-items-center justify-content-center">
            <Icon image = "next" size = {"50px"} className = "mr-4" click = {() => prev()} />
          </div>
          <div style = {{transform: "rotate(180deg)"}} className = "ml-4 mt-3 flex d-flex align-items-center justify-content-center">
            <Icon image = "next" size = {"50px"} click = {() => validateThenNext()} />
          </div>
      </Row>
    </Container>
  );
};

export default Onboard_1;

import { ListGroup, Row, Col } from "react-bootstrap";
import Icon from "../tools/Icon";
import ListAttribute from "./ListAttribute";

const attributeExclusions = [
    {
        primary: "alcoholic beverages", 
        secondary: ["contains_alcohol"]
    },
    {
        primary: "vegan",
        secondary: ["vegetarian"]
    },
    {
        primary: "meat",
        secondary: ["contains_meat"]
    },
    {
        primary: "egg",
        secondary: ["contains_eggs"]
    },
    {
        primary: "eggs",
        secondary: ["egg", "contains_eggs"]
    },
    {
        primary: "poultry",
        secondary: ["contains_meat"]
    },
    {
        primary: "contains_wheat",
        secondary: ["contains_gluten"]
    }
]

export default function ListIngredient({ingredient, select, selected, selectTwo = null, inverse = false, occurrences = false}){
    let listGroupItemStyle = inverse ? {
        height: "75px", border: "2px solid black", marginTop: "10px", marginBottom: "5px", boxShadow: "0px 4px 4px 2px rgba(255, 255, 255, 0.3)", backgroundColor: "#157115", fontFamily: "Koulen", color: "whitesmoke", position: "relative"
    } : {
        height: "75px", border: "2px solid black", marginBottom: "5px", boxShadow: "0px 4px 4px 2px rgba(255, 255, 255, 0.3)", backgroundColor: "whitesmoke", fontFamily: "Koulen", position: "relative"
    };
    

    return (
            <ListGroup.Item key = {ingredient.ingredientKey} style = {listGroupItemStyle} className = "ingredient-container" onClick = {() => select(ingredient.ingredientKey, ingredient.ingredientName)}
            >
                <div 
                    className="flex d-flex flex-direction-row ingredient" 
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                    {/* Uncomment and modify the following lines if the selectTwo logic is needed */}
                    {/* {selectTwo != null && !selected.some(item => item.ingredientKey === ingredient.ingredientKey) && 
                        <Icon 
                            image="circle" 
                            className="ml-0 mt-0 mr-2" 
                            click={() => selectTwo(selected.filter(item => item.ingredientKey !== ingredient.ingredientKey))} 
                        />
                    }
                    {selectTwo != null && selected.some(item => item.ingredientKey === ingredient.ingredientKey) && 
                        <Icon 
                            image="check" 
                            className="ml-0 mt-0 mr-2" 
                            click={() => selectTwo([...selected, { ingredientName: ingredient.ingredientName, ingredientKey: ingredient.ingredientKey }])} 
                        />
                    } */}
                    
                    <h6 style={{ fontFamily: "Koulen", fontSize: "16px", marginLeft: "5px", marginBottom: 0, flex: "1 1 auto", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {ingredient.ingredientName}
                    </h6>
                    {occurrences !== false && (
                        <h6 className="mr-3 ml-auto" style={{ marginBottom: 0, flex: "0 0 auto" }}>
                        Used in {occurrences} meals
                        </h6>
                    )}
                    </div>
                {/* Icons */}
                <Row style = {{marginLeft: "5px"}}>
                    {ingredient.IngredientAttributes.map(item => {
                        const attributeName = item.Attribute.attributeName;

                        // Find the exclusion where attributeName matches a secondary
                        const matchedExclusion = attributeExclusions.find(exclusion =>
                            exclusion.secondary.includes(attributeName)
                        );

                        // Check if the corresponding primary is present in attributeData
                        const hasMatchingPrimary = matchedExclusion
                            ? ingredient.IngredientAttributes.some(attr => attr.Attribute.attributeName === matchedExclusion.primary)
                            : false;

                        // Render only if both the secondary match and the corresponding primary exist
                        if (matchedExclusion && hasMatchingPrimary) {
                            return;
                        } else {
                            return (
                                <ListAttribute
                                    image={attributeName}
                                    color={item.Attribute.color}
                                    size = "small"
                                />
                            );
                        }
                    })}
                </Row>
                <div style={{position: "absolute", bottom: 0, left: 0, right: 0, height: "3px", backgroundColor: "#3779BB"}}></div>
            </ListGroup.Item>
    )

}


import { Row } from "react-bootstrap";
import Icon from "../../tools/Icon";
import Placeholder from 'react-bootstrap/Placeholder';
import { Card } from "react-bootstrap";

export default function Options({text, opts, optState, optSet, spaceLastOption = false, completionIndicator = null, placeholder = false}){
    return (
        <div>
            {placeholder &&
                <h3>
                    <Placeholder className = "dill-placeholder" as={Card.Title} animation="glow" style={{ height: "20px", backgroundColor: '#eeeeee' }}>
                        <Placeholder xs={6} />
                    </Placeholder>  
                </h3>

            }
            {!placeholder && <h3 className = "mt-3" style = {{fontFamily: "Koulen", textAlign: "center", fontWeight: "normal"}}>{text}</h3>}
            <Row className = "flex d-flex align-items-center justify-content-center mt-3">
                {opts.map((item, index) => {
                    let conditionalBorder = item === optState ? "5px solid #90C1B0" : "1px solid #cccccc"
                    if(spaceLastOption && item == opts[opts.length - 1])
                        return;
                    if(item !== optState || completionIndicator == null || (completionIndicator != null && !completionIndicator.some(item => item.position === index))){
                        return (
                            <div style = {{height: "100px",  background: 'linear-gradient(to bottom right, #212121, #343434)', maxWidth: "250px", minWidth: "75px", marginRight: "20px", marginLeft: "20px", border: conditionalBorder, borderRadius: "4px", padding: "10px"}} 
                                className = "d-flex justify-content-center align-items-center ml-3 mr-3"
                                onClick = {() => optSet(item)}>
                                {placeholder && <p>
                                        <Placeholder className = "dill-placeholder" as={Card.Title} animation="glow" style={{ height: "20px", backgroundColor: '#eeeeee' }}>
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                </p>}
                                {!placeholder && 
                                    <p style = {{textAlign: "center", fontFamily: "Koulen"}} className = "text-align-center">{item}</p>
                                }
                            </div>
                        )
                    } else {
                        let thisCompletionIndicator = completionIndicator.find(item => item.position == index);
                        let background = "#212121"
                        if(thisCompletionIndicator.icon == "x"){
                            background = 'linear-gradient(to bottom right, #cc0000, #770000)' 
                            conditionalBorder=  '2px solid #ff7777'
                        }
                            
                                           
                        return (
                            <div style = {{padding: 0, margin: 0}} className = "flex d-flex flex-column align-items-center">
                                <div style = {{height: "100px", maxWidth: "250px", minWidth: "75px", border: conditionalBorder, borderRadius: "4px", padding: "10px", background: background}} 
                                    className = "d-flex justify-content-center align-items-center ml-3 mr-3"
                                    onClick = {thisCompletionIndicator.icon !== "x" ? () => optSet(item) : () => thisCompletionIndicator.open()}>
                                    <p style = {{textAlign: "center", fontFamily: "Koulen"}} className = "text-align-center">{item}</p>
                                </div>
                                <Icon image = {thisCompletionIndicator.icon} className = "mt-3" click = {() => thisCompletionIndicator.open()}/>
                            </div>
                            
                        )
                    }


                })}
            </Row>
            {spaceLastOption && (
                <Row className = "flex d-flex align-items-center justify-content-center mt-3">
                    <div style = {{height: "100px", maxWidth: "300px", minWidth: "300px", marginRight: "20px", 
                        border:  (opts[opts.length - 1] === optState || (opts[opts.length - 1] == "1 week" && optState == 7)) ? "3px solid black" : "1px solid #cccccc", 
                        marginLeft: "20px", 
                        borderRadius: "4px", 
                        padding: "10px"}} 
                            className = "d-flex justify-content-center align-items-center ml-3 mr-3"
                            onClick = {() => 
                            {
                                if(opts[opts.length - 1] == "1 week")
                                    optSet(7)
                                else
                                    optSet(opts[opts.length - 1])
                            }}>
                        <p style = {{textAlign: "center", fontFamily: "Koulen"}} className = "text-align-center">{opts[opts.length - 1]}</p>
                    </div>
                </Row>
            )}
        </div>

    )
}
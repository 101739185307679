import {Container, Row, Col, Modal, Button} from 'react-bootstrap'
import {CustomModal} from '../Components/CustomModal.js'
import MealList from './MealList';
import SingleMealDetail from './SingleMealDetail';

import {useState, useEffect, useContext} from 'react';
import SessionContext from '../utils/SessionContext';

export default function Meals(){
    let [selectedTab, setSelectedTab] = useState("Favorites");

    const {userKey, goals} = useContext(SessionContext);

    let [selectedMealKey, setSelectedMealKey] = useState(null);
    let [reloadMealList, setReloadMealList] = useState(false);
    let [mode, setMode] = useState("");

    let [addable, setAddable] = useState(true);
    useEffect(() => {
        if(reloadMealList){
            setReloadMealList(false);
            setSelectedMealKey(null);
        }
    }, [reloadMealList]);

    return (
        <Container fluid style = {{backgroundColor: "#212121",  height: "100%"}}>
            <MealList 
                userKey={userKey}
                selected = {selectedTab} 
                select = {(i) => setSelectedMealKey(i)} 
                reload = {reloadMealList} 
                addable = {addable}
                mode = {"view"}
                setMode = {setMode}/>
            {selectedMealKey != null && 
                <CustomModal title = {selectedMealKey} close = {() => setSelectedMealKey(null)} modalBody = {<SingleMealDetail userKey = {userKey} mealKey = {selectedMealKey} reload = {() => setReloadMealList(true)} goals = {goals}/>} />
            }
        </Container>
    )
}
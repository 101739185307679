// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboard-container {
    min-height: 80vh;
    padding: 0;
    overflow-y: auto;
}

.progress {
    min-height: 2vh;
}

.card {
    margin-right: 25%;
    margin-left: 25%;
}

@media (max-width: 1200px) {
    .card {
        margin-right: 10%;
        margin-left: 10%;
    }
}

@media (max-width: 768px) {
    .card {
        margin-right: 5%;
        margin-left: 5%;
    }
}

@media (max-width: 576px) {
    .card {
        margin-right: 2%;
        margin-left: 2%;
    }
}

.summary-container {
    opacity: 0;
    animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.summary-text {
    font-size: 18px;
    /* Adjust font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/onboard/onboard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,eAAe;IACnB;AACJ;;AAEA;IACI,UAAU;IACV,4CAA4C;AAChD;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC","sourcesContent":[".onboard-container {\n    min-height: 80vh;\n    padding: 0;\n    overflow-y: auto;\n}\n\n.progress {\n    min-height: 2vh;\n}\n\n.card {\n    margin-right: 25%;\n    margin-left: 25%;\n}\n\n@media (max-width: 1200px) {\n    .card {\n        margin-right: 10%;\n        margin-left: 10%;\n    }\n}\n\n@media (max-width: 768px) {\n    .card {\n        margin-right: 5%;\n        margin-left: 5%;\n    }\n}\n\n@media (max-width: 576px) {\n    .card {\n        margin-right: 2%;\n        margin-left: 2%;\n    }\n}\n\n.summary-container {\n    opacity: 0;\n    animation: fade-in 0.5s ease-in-out forwards;\n}\n\n@keyframes fade-in {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n.summary-text {\n    font-size: 18px;\n    /* Adjust font size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState, useEffect, useContext } from "react";
import {   ListGroup,   Tab,   Tabs,   Col,   Row, Form,   Spinner} from "react-bootstrap";
import { MDBSwitch } from "mdb-react-ui-kit";
import Icon from "../tools/Icon";
import executeCall from "../utils/APICall";
import {ListMeal} from '../Components/ListMeal.js'

import ErrorContext from "../utils/ErrorContext";

function evaluationEmoji(num) {
  switch (num) {
    case 3:
      return "🌟";
    case 2:
      return "✅";
    case 1:
      return "⚠️";
    case 0:
      return "❌";
  }
}

function ServingsOption({ servingsAmount, click }) {
  return (
    <Col xs={4} className="d-flex align-items-center justify-content-center">
      <div
        style={{ border: "1px solid black", borderRadius: "4px" }}
        className="p-3 pr-5 pl-5"
        onClick={() => click()}
      >
        {servingsAmount}
      </div>
    </Col>
  );
}


function List({ allMeals, addable, select, mode, filter }) {
  const [selectedKey, setSelectedKey] = useState(false);
  const [selectedNutritionInfo, setSelectedNutritionInfo] = useState(false);

  const printArrayLimited = (array, limit) => {
    let output = "";
    for (let i = 0; i < Math.min(array.length, limit); i++) {
      output += array[i];
      if (i < Math.min(array.length, limit) - 1) {
        output += ", ";
      }
    }
    return output;
  };

  const setActive = (key, name) => {
    if (mode == "add") {
      if (selectedKey == key) setSelectedKey(false);
      else setSelectedKey(key);
    } else if (mode == "view") select(key);
  };

  const setNutritionInfo = (key) => {
    if (selectedNutritionInfo == key) setSelectedNutritionInfo(false);
    else setSelectedNutritionInfo(key);
  };

  return (
    <>
      <ListGroup
        style={{ maxHeight: "70vh", overflowY: "scroll" }}
        className="mt-1"
      >
        {addable && <ListMeal meal= {{mealName: "Add a New Meal"}} setActive = {() => select(false)} inverse = {true}/>}
        {allMeals != null &&
          allMeals.map((meal, index) => {
            let backgroundColor =
              meal.mealKey == selectedKey ? "#dddddd" : "#ffffff";
            if (
              filter !== undefined &&
              !meal.mealName.toLowerCase().includes(filter.toLowerCase())
            ) {
              return;
            } else {
              return (
                <>
                  <ListMeal meal = {meal} index = {index} setActive = {setActive}/>
                  {selectedNutritionInfo == meal.mealKey && (
                    <ListGroup.Item key={index} style={{border: "1px solid black",marginBottom: "20px"}} onClick={() => setActive(meal.mealKey, meal.mealName)}
>
                      <Row style={{ width: "100%" }}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                          Nutritional Explanation
                        </div>
                      </Row>
                      <Row style={{ width: "100%" }}>
                        <Col>
                            <p>Carbohydrates: {evaluationEmoji(meal.evaluation.carbs.num)}</p>
                            <p>{meal.evaluation.carbs.message}</p>
                            <p>Fats: {evaluationEmoji(meal.evaluation.fats.num)}</p>
                            <p>{meal.evaluation.fats.message}</p>
                            <p>Protein: {evaluationEmoji(meal.evaluation.protein.num)}</p>
                            <p>{meal.evaluation.protein.message}</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  {selectedKey == meal.mealKey && (
                    <ListGroup.Item
                      key={index}
                      style={{ height: "125px", border: "1px solid black" }}
                      onClick={() => setActive(meal.mealKey, meal.mealName)}
                    >
                      <Row style={{ width: "100%" }}>
                        <div style={{ width: "100%", textAlign: "center" }}>Choose Servings</div>
                      </Row>
                      <Row className="mt-3">
                        <ServingsOption servingsAmount={meal.defaultServings}  click={() => select(meal.mealKey,  meal.defaultServings, meal.mealName)}/>
                        <ServingsOption
                          servingsAmount={meal.defaultServings * 2}
                          click={() =>
                            select(
                              meal.mealKey,
                              meal.defaultServings * 2,
                              meal.mealName
                            )
                          }
                        />
                        <ServingsOption
                          servingsAmount={meal.defaultServings * 3}
                          click={() =>
                            select(
                              meal.mealKey,
                              meal.defaultServings * 3,
                              meal.mealName
                            )
                          }
                        />
                      </Row>
                    </ListGroup.Item>
                  )}
                </>
              );
            }
          })}
      </ListGroup>

    </>
  );
}

export default function MealList({
  token,
  userKey,
  select,
  reload,
  addable,
  mode,
  evaluate = false,
  setMode = null,
}) {
  let [allMeals, setAllMeals] = useState(null);
  let [selected, setSelected] = useState("Default");

  let [loadingMeals, setLoadingMeals] = useState(true);

  let [mealFilter, setMealFilter] = useState("");

  const { errorText, setErrorText } = useContext(ErrorContext);

  useEffect(() => {
    loadMeals();
  }, [selected]);

  useEffect(() => {
    if (reload) loadMeals();
  }, [reload]);

  useEffect(() => {
    console.log(mealFilter);
  }, [mealFilter]);

  const loadMeals = async () => {
    let apiCall = "";
    if (selected === "UserMeals") apiCall = "foods/userMeals/" + userKey;
    else if (selected == "Default") {
      apiCall = "foods/userMeals/00000000-0000-0000-0000-000000000000";
      if (setMode != null) setMode("Default");
    } else if (selected == "Favorites") apiCall = "foods/favorites/ " + userKey;
    else if (selected == "AllMeals") apiCall = "foods/mealList";

    if (evaluate) {
      apiCall = apiCall + "/" + userKey;
    }

    const response = await executeCall(apiCall, "GET", setErrorText);
    if ("error" in response) {
      return;
    }

    if (selected != "Default") {
      if (setMode != null) setMode("User");
    }
    let mealList = Array.from(response);
    console.log(mealList);
    setAllMeals(mealList);
    setLoadingMeals(false);
  };

  return (
    <div className="pt-4 px-0 px-sm-4" style = {{height: "100%"}}>
      <Form.Group controlId="filterInput">
        <Form.Control
          type="text"
          placeholder="Find a meal..."
          value={mealFilter ?? ""}
          onChange={(e) => setMealFilter(e.target.value)}
          style={{ height: "50px", borderBottom: "7px solid green" }}
        />
      </Form.Group>
      <Tabs fill justify onSelect={(tab) => setSelected(tab)} className="mt-3">
        <Tab style={{ width: "100%", height: "100%" }} className="text-align-center" eventKey="Default" title={<span style={{ width: "100%", flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>⚙️ Default Meals</span>}>
            {loadingMeals ? (
            <div className="flex d-flex align-items-center justify-content-center" style={{ width: "100%", height: "100vh", position: "absolute", top: 0, left: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spinner style={{ color: "whitesmoke" }} />
            </div>
            ) : (
            <List allMeals={allMeals} addable={addable} select={select} mode={mode} filter={mealFilter} />
            )}
        </Tab>
        <Tab eventKey="UserMeals" title={<p>👤 Your Meals</p>}>
            <List allMeals={allMeals} addable={addable} select={select} mode={mode} filter={mealFilter} />
        </Tab>
        <Tab eventKey="AllMeals" title={<p>📚 All Meals</p>}>
            <List allMeals={allMeals} addable={addable} select={select} mode={mode} />
        </Tab>
    </Tabs>
    </div>
  );
}

import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ErrorContext from '../utils/ErrorContext';

export const ErrorModal = () => {
  const {errorText, setErrorText} = useContext(ErrorContext);

  return (
        <Modal show={errorText !== "" && errorText != null} onHide={() => setErrorText("")}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
        </Modal.Header>
          <Modal.Body>{errorText}</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setErrorText("")}>
              Close
              </Button>
          </Modal.Footer>
         </Modal>


  );
};

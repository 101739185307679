import Icon from "../../tools/Icon";
import DayMealModel from "./DayMealModel";
import DayOptions from "./DayOptions";

import {useState, useEffect} from 'react';
import { Row } from "react-bootstrap";

function MealGroup({day, mode, type, groupType, count, add, remove}){
    return (
        <div style = {{width: "100%"}}>
            <Row className = "flex d-flex align-items-center justify-content-center">
                <Icon image = "minus" click = {() => remove(day)} className = "mr-3"/>
                <h1 className = "pt-2">{count}</h1>
                <Icon image = "lightplus" click = {() => add(day)} className = "ml-3"/>
            </Row>
            <Row className="flex d-flex align-items-center justify-content-center">
                <h1>{type}</h1>
            </Row>
        </div>
    )

}

export default function TypicalScheduleSelector({dayCount, optState, optSet, exceptions, close, beginDay = null}){
    
    const [exceptionDays, setExceptionDays] = useState("0".repeat(dayCount));
    const [typicalDayMeals, setTypicalDayMeals] = useState(3);
    const [typicalDaySnacks, setTypicalDaySnacks] = useState(1);

    useEffect(() => {
        let days = optState.split('|');
        for(let x of days){
            let newDay = "";
            if(!x.includes("?")){
                for(let i = 0; i < typicalDayMeals; i++){
                    newDay += "M";
                }
                for(let i = 0; i < typicalDaySnacks; i++){
                    newDay += "S";
                }
                days[days.indexOf(x)] = newDay;
            }
        }
        optSet(days.join("|"));
    }, [typicalDayMeals, typicalDaySnacks])

    const addMeal = (i, type) => {        
        let newDayMeals = optState.split('|');
        newDayMeals[i] = newDayMeals[i] + type;
        newDayMeals = newDayMeals.join("|")

        optSet(newDayMeals);
    }

    useEffect(() => {
        if(exceptionDays == "0".repeat(dayCount) && optState.includes("?")){ 
            let newExceptionDays = "";
            let days = optState.split('|');
            for(let x of days){
                if(x.includes("?")){
                    newExceptionDays = newExceptionDays + "1";
                } else {
                    newExceptionDays = newExceptionDays + "0";
                }
            }
            setExceptionDays(newExceptionDays);
        }
    }, [optState])

    const deleteMeal = (i, type) => {
        let newDayMeals = optState.split('|')
        if (i >= 0 && i < newDayMeals.length) {
            const index = newDayMeals[i].lastIndexOf(type);
            if (index !== -1) {
                newDayMeals[i] = newDayMeals[i].slice(0, index) + newDayMeals[i].slice(index + 1);
            }
        }
        newDayMeals = newDayMeals.join('|');
        optSet(newDayMeals);
    }

    useEffect(() => {
        console.log(exceptionDays);
        const groups = optState.split("|"); // Split the string by '|'
        const replaced = groups.map((group, index) =>
            exceptionDays[index] === '0' ? ('M'.repeat(typicalDayMeals) + 'S'.repeat(typicalDaySnacks)) : (!group.includes("?") ? (group + '?') : group)
        );
        optSet(replaced.join("|"));
    }, [exceptionDays])
    
    return (
        <div className = "flex d-flex flex-column align-items-center pb-4">
            <h1>How many meals do you eat on a typical day?</h1>
            <div className = "flex d-flex justify-content-center">
                {typicalDayMeals > 0 && <Icon className = "mr-3 mt-2" image = "minus" click = {() => setTypicalDayMeals(typicalDayMeals - 1)} />}
                <h1>{typicalDayMeals}</h1>
                <Icon  className = "ml-3 mt-2" image = "lightplus" click = {() => setTypicalDayMeals(typicalDayMeals + 1)} />

            </div>
            <h1 className = "mt-4">How many snacks do you eat on a typical day?</h1>
            <div className = "flex d-flex justify-content-center">
                <Icon className = "mr-3 mt-2" image = "minus" click = {() => setTypicalDaySnacks(typicalDaySnacks - 1)} />
                <h1>{typicalDaySnacks}</h1>
                <Icon  className = "ml-3 mt-2" image = "lightplus" click = {() => setTypicalDaySnacks(typicalDaySnacks + 1)} />

            </div>
            {exceptions && <DayOptions dayCount={dayCount}
                type = "exceptions"
                question = "Which days do you not have a normal eating schedule?"
                optState = {exceptionDays}
                optSet = {(e) => setExceptionDays(e)} 
                beginDay = {beginDay} />}

            {exceptions && optState.split('|').map((item, index) => {
                if (!item.includes("?")) {
                    return;
                }
                const displayMeals = optState.split('|')[index] ?? "";
                const mealCountMatches = displayMeals.match(/M/g);
                const mealCount = mealCountMatches ? mealCountMatches.length : 0

                const snackCountMatches = displayMeals.match(/S/g);
                const snackCount = snackCountMatches ? snackCountMatches.length : 0;

                return (
                    <div className = "d-flex flex-column mt-3" key = {index} style = {{border: "1px solid black", width: "90%"}}>
                        <h4 style = {{margin: "10px"}}>Day {index + 1}</h4>
                        <MealGroup day = {index} 
                            type = "Meals"
                            count = {mealCount} 
                            add = {() => addMeal(index, "M")} 
                            remove = {() => deleteMeal(index, "M")}/>
                        <MealGroup day = {index} 
                            type = "Snacks"
                            count = {snackCount} 
                            add = {() => addMeal(index, "S")} 
                            remove = {() => deleteMeal(index, "S")}/>


                    </div> 
                )
            })}
            <Icon image = "lightCheck" size = {"50px"} className = "mt-4" click = {() => close()} />
            
        </div>
    )
}
import React, { useState, useContext } from 'react';
import { Container, Col, Form, Row, Button, Alert } from 'react-bootstrap';
import Icon from '../tools/Icon';
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export default function Onboard_Basics({ formState, setFormState, errorMessage, next }) {

    let [emailInError, setEmailInError] = useState(false);
    let [passwordInError, setPasswordInError] = useState(false);
    let [usernameInError, setUsernameInError] = useState(false);
    const {errorText, setErrorText} = useContext(ErrorContext);

    async function validatePotentialSignup(email, username){
        let x = await executeCall(`onboard/validatePotentialSignup`, 'POST', setErrorText, { "email": email, "username": username });
        if(x.message == "username_exists"){
            setUsernameInError(true);
            return "FAIL";
        } else if(x.message == "email_exists"){
            setEmailInError(true);
            return "FAIL";
        } else {
            return "SUCCESS";
        }
    }

    const handleEmailChange = (e) => {
        setFormState({
            ...formState,
            UserInfo: { ...formState.UserInfo, email: e.target.value },
        });
        if(emailInError && e.target.value != ""){
            setEmailInError(false);
        }
    };

    const handlePasswordChange = (e) => {
        setFormState({
            ...formState,
            UserInfo: { ...formState.UserInfo, password: e.target.value },
        });
        if(passwordInError && e.target.value != ""){
            setPasswordInError(false);
        }
    };

    const handleUsernameChange = (e) => {
        setFormState({
            ...formState,
            UserInfo: { ...formState.UserInfo, username: e.target.value },
        });
        if(usernameInError && e.target.value != ""){
            setUsernameInError(false);
        }
    };

    const validateThenNext = async () => {
        let error = false;
        if(!("username" in formState.UserInfo) || formState.UserInfo.username == ""){
            setUsernameInError(true);
            error = true;
        } if(!("password" in formState.UserInfo) || formState.UserInfo.password == ""){
            setPasswordInError(true);
            error = true;
        }
        if(!("email" in formState.UserInfo) || formState.UserInfo.email == "" || !isValidEmail(formState.UserInfo.email)){
            setEmailInError(true);
            error = true;
        }

        let potentialSignupValid = await validatePotentialSignup(formState.UserInfo.email, formState.UserInfo.username);
        if(potentialSignupValid == "FAIL")
            error = true;

        if(!error)
            next();
    }



    return (
        <Container fluid style={{backgroundColor: "#212121" }} className = "flex-grow">
            <Row className="justify-content-center align-items-center" style = {{height: "90vh"}}>
                <Col xs={12} md={6} lg={4}>
                    <Form>
                        <h1 className="text-center mb-4" style = {{color: "whitesmoke"}}><u>Sign Up</u></h1>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <Form.Group>
                            <Form.Label style = {{fontFamily: "Koulen", color: "whitesmoke", fontSize: "32px"}}>Email</Form.Label>
                            <Form.Control
                                type="text"
                                style = {{backgroundColor: emailInError ? "#e09e9d" : "whitesmoke"}}
                                value={formState.UserInfo.email || ''}
                                onChange={handleEmailChange}
                                autoComplete="off"
                                placeholder="Enter your email"
                            />
                        </Form.Group>

                        <Form.Group className = "mt-3">
                            <Form.Label style = {{fontFamily: "Koulen", color: "whitesmoke", fontSize: "32px"}}>Username</Form.Label>
                            <Form.Control
                                type="text"
                                style = {{backgroundColor: usernameInError ? "#e09e9d" : "whitesmoke"}}
                                value={formState.UserInfo.username || ''}
                                onChange={handleUsernameChange}
                                autoComplete="off"
                                placeholder="Enter your username"
                            />
                        </Form.Group>
                        
                        <Form.Group className = "mt-3">
                            <Form.Label style = {{fontFamily: "Koulen", color: "whitesmoke", fontSize: "32px"}}>Password</Form.Label>
                            <Form.Control
                                type="password"
                                style = {{backgroundColor: passwordInError ? "#e09e9d" : "whitesmoke"}}
                                value={formState.UserInfo.password || ''}
                                onChange={handlePasswordChange}
                                autoComplete="off"
                                placeholder="Enter your password"
                            />
                        </Form.Group>
                    </Form>
                    <div style = {{transform: "rotate(180deg)"}} className = "mt-4 flex d-flex justify-content-center align-items-center mt-3">
                        <Icon className = "mt-4" image = "next" size="50px" click = {() => validateThenNext()}/>
                    </div>
                   
                </Col>
            </Row>
        </Container>
    );
}

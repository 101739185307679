import {ListGroup, Form, Pagination, Row,Col, Container} from 'react-bootstrap'
import {useState, useEffect, useContext} from 'react'
import executeCall from '../utils/APICall';
import ErrorContext from '../utils/ErrorContext';
import Icon from '../tools/Icon';
import ListIngredient from '../Components/ListIngredient';


export default function IngredientListView({changeIngredient, allowChecked = false, setParentSelected = null, parentSelected = null}){
    
    const [ingredients, setIngredients] = useState(null);
    let [ingredientFilter, setIngredientFilter] = useState("");

    let [popularIngredients, setPopularIngredients] = useState(null);

    let [selected, setSelected] = useState(parentSelected ?? []);

    const {errorText, setErrorText} = useContext(ErrorContext);

    // useEffect(() => {
    //     loadIngredients();
    // }, [changeIngredient]);

    useEffect(() => {
        if(allowChecked){
            changeIngredient(selected);
            setParentSelected(selected);
        }

    }, [selected])

    useEffect(() => {
        if(ingredientFilter.length > 2){
            ingredientSmartSearch();
        } else {
            loadIngredients();
        }
    }, [ingredientFilter])

    const ingredientSmartSearch = async() => {
        const response = await executeCall(`foods/ingredientSmartSearch/${ingredientFilter}`, "GET", setErrorText)
        if("error" in response){
           return;
        }

        let allIngredients = response.ingredients;
        console.log(allIngredients[0]);
        allIngredients.sort((a, b) => {
            // Convert both names to lowercase for case-insensitive comparison
            const nameA = a.ingredientName.toLowerCase();
            const nameB = b.ingredientName.toLowerCase();
        
            if (nameA < nameB) {
                return -1; // Name A comes before Name B
            } else if (nameA > nameB) {
                return 1; // Name B comes before Name A
            } else {
                return 0; // Names are equal
            }
        });
        setIngredients(allIngredients);
        setPopularIngredients(response.popularIngredients);
    }

    const loadIngredients = async (reload = false) => {
        const response = await executeCall("foods/ingredientList", "GET", setErrorText)
        if("error" in response)
            return;

        let allIngredients = response.ingredients;
        allIngredients.sort((a, b) => {
            return b.occurrences - a.occurrences;
        });
        setIngredients(allIngredients);
        console.log(allIngredients);
    }


    function setIngredient(ingredientKey, ingredientName){
        if(!allowChecked)
            changeIngredient(ingredientKey, ingredientName)
        else {
            if(selected.some(item => item.ingredientKey === ingredientKey)){
                setSelected(selected.filter(item => item.ingredientKey != ingredientKey));
            } else {
                setSelected([...selected, {ingredientName: ingredientName, ingredientKey: ingredientKey}]);
            }
        }
    }

    return (
        <Container fluid style = {{height: "100%", margin: 0, padding: 0, position: "relative"}}>
            <Row className = "flex d-flex flex-column ml-2 mr-2">
                <Form.Group controlId="filterInput">
                    <Form.Control
                    type="text"
                    placeholder="Find an ingredient"
                    value={ingredientFilter ?? ""}
                    className = "mt-4"
                    onChange={(e) => setIngredientFilter(e.target.value)}
                    style={{ height: "50px", borderBottom: "7px solid #3779BB" }}
                    />
                </Form.Group>
            </Row>
            <Row className = "pl-3 pr-3" style = {{backgroundColor: "#212121"}}>
                <Col className='flex flex-column justify-content-center align-items-center' style = {{width: '100vw', overflowY: "auto", margin: 0, padding: 0, backgroundColor: "#212121"}}>
                    
                    {popularIngredients != null && (ingredientFilter != null && ingredientFilter != "" && ingredientFilter.length > 2) && (
                        <ListGroup className = "mt-2 mb-2" style = {{backgroundColor: "#212121"}}>
                            {ingredients != null && (<h1 style = {{color: "whitesmoke"}}>Popular Items</h1>)}
                            {popularIngredients != null && popularIngredients.map((ingredient) => (
                                <ListIngredient ingredient={ingredient} selected = {selected} select = {(a, b) => setIngredient(a, b)} selectTwo={(e) => setSelected(e)} occurrences = {ingredient.occurrences}/>
                            ))}
                        </ListGroup>                    
    
                    )}
                    <ListGroup className = "mt-2 mb-2" style = {{backgroundColor: "#212121"}}>
                        {ingredients != null && (ingredientFilter == null || ingredientFilter == "") && (<h1 style = {{color: "whitesmoke"}}>Popular Items</h1>)}
                        {ingredients != null && (ingredientFilter != null && ingredientFilter != "") && (<h1 className = "mt-2" style = {{color: "whitesmoke"}}>All Ingredients</h1>)}
                        {ingredients != null && ingredients.map((ingredient) => (
                            <ListIngredient ingredient={ingredient} selected = {selected} select = {(a, b) => setIngredient(a, b)} selectTwo={(e) => setSelected(e)} occurrences = {ingredient.occurrences}/>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
            
        </Container>
    )
}
import {Modal, Button} from 'react-bootstrap';
import Icon  from "../tools/Icon.js"

export function CustomModal({title, modalBody, close}){
    return (
        <div className="modal show" style={{ display: 'block', overflowX: 'hidden' }}>
            <Modal.Dialog style={{ maxWidth: window.matchMedia('(max-width: 768px)').matches ? '95vw': "80vw", backgroundColor: "#212121", borderRadius: "6px", border: "5px solid #90C1B0", position: "relative"}} scrollable>
                <Modal.Header style = {{margin: 0, padding: 0, height: "25px", backgroundColor: "#212121", border: 0}}>
                    <Icon className = "mt-1 mr-1 ml-auto" image = "x" click = {() => close()}></Icon>

                </Modal.Header>
                <Modal.Body style = {{backgroundColor: "#212121", padding: 0, overflowX: 'hidden'}}>
                    {modalBody}
                </Modal.Body>
            </Modal.Dialog>
        </div>
    )
}
import {useEffect, useState, useContext} from 'react'
import Icon from '../tools/Icon';
import {Button, Row, Col} from 'react-bootstrap'
import MealSelectModal from './Modals/MealSelectModal';
import MealModal from './Modals/MealModal';
import ErrorContext from '../utils/ErrorContext';
import executeCall from '../utils/APICall';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PathConstants from '../tools/PathConstants';
import {Link, useNavigate} from 'react-router-dom';
import SessionContext from '../utils/SessionContext';

function ServingsDisplay({servings, mealName, edit, select}){
    return (
        <Row className = "m-5" style = {{width: "50%"}}>
            <Col xs = {4} style = {{border: "2px solid black"}} 
                className = "d-flex justify-content-center"
                onClick = {() => select()}>{mealName}</Col>
            <Col xs = {4} className = "d-flex justify-content-center">X</Col>
            <Col xs = {4} className = "d-flex justify-content-center">
                {servings}
                <Icon image = "plus" click = {() => edit(servings + 1)}/>
                {servings !== 1 && (
                    <Icon image = "minus" click = {() => edit(servings - 1)}/>
                )}
                <Icon image = "remove" click = {() => edit(0)}/>
            </Col>
        </Row>
    )
}

function calculateDate(schedules){
    let dates = [];
    console.log(schedules);
    for(let schedule of schedules){
        //Good example of date formatting
        let formattedDate = moment(schedule.endDate).format('MMMM Do YYYY');
        dates.push(formattedDate);
    }
    console.log(dates);
    return dates;
}

function DateInfo({latestDate, selectedDate, setSelectedDate}){
    const [minDate, setMinDate] = useState(latestDate);

    useEffect(() => {
        let newDate = moment(latestDate[0], "'MMMM Do YYYY");
        setMinDate(newDate.toDate());
    }, [latestDate]);

    return (
        <>
            {(latestDate != null && latestDate != "") && <h4>Your current plan ends on {latestDate}. When would you like to begin your next meal cycle?</h4>}
            {(latestDate == null || latestDate == "") && <h4>Congrats on choosing to set up a plan! What date would you like to start?</h4>}
            <DatePicker
                selected={selectedDate}
                onChange={(e) => setSelectedDate(e)}
                minDate = {minDate}
                dateFormat="MMMM d, yyyy"
                placeholderText="Click to select a date"
                className = "mt-3 mb-3"
            />
        </>
    )
}

export default function CreateSchedule(){

    const navigate = useNavigate();

    const {errorText, setErrorText} = useContext(ErrorContext)

    let [totalMeals, setTotalMeals] = useState(0);
    let [totalSnacks, setTotalSnacks] = useState(0);
    let [remainingMeals, setRemainingMeals] = useState(0);
    let [remainingSnacks, setRemainingSnacks] = useState(0);

    let [mealModal, setMealModal] = useState(false);
    let [snackModal, setSnackModal] = useState(false);
    let [meals, setMeals] = useState([]);
    let [snacks, setSnacks] = useState([]);

    let [latestDate, setLatestDate] = useState(null);
    let [selectedDate, setSelectedDate] = useState(null);

    const [selectedMeal, setSelectedMeal] = useState(false);

    const {goals, userKey} = useContext(SessionContext);

    async function loadScheduleSettings(){
        console.log(userKey);
        const response = await executeCall(`plans/scheduleSettings/${userKey}`, "GET", setErrorText);
        if("error" in response) return;
        let setup = response.scheduleSetup;

        const response2 = await executeCall(`plans/schedules/${userKey}`, "GET", setErrorText);
        if("error" in response2) return;
        let currentDate = calculateDate(response2.schedules);
        setLatestDate(currentDate);

        setTotalMeals(setup.split('M').length - 1);
        setTotalSnacks(setup.split('S').length - 1);
        setRemainingMeals(setup.split('M').length - 1);
        setRemainingSnacks(setup.split('S').length - 1);
    }

    async function createScheduleFromMeals(){
        const response = await executeCall(`plans/scheduleFromMeals/${userKey}`, "POST", setErrorText, {meals: meals, snacks: snacks, selectedDate: selectedDate});
        if("error" in response) return; 
        
        if(response.message === "SUCCESS")
            navigate("/schedules")
    }

    useEffect(() => {
        let currentMeals = 0;
        for(let x of meals)
            currentMeals = currentMeals + x.servings;
        setRemainingMeals(totalMeals - currentMeals);
    }, [meals]);

    useEffect(() => {
        let currentMeals = 0;
        for(let x of snacks)
            currentMeals = currentMeals + x.servings;
        setRemainingSnacks(totalSnacks - currentMeals);
    }, [snacks]);


    useEffect(() => {
        loadScheduleSettings(userKey);
    }, [userKey])

    async function addItem(key, servings, name, type){
        console.log("adding " + name);
        const response = await executeCall(`foods/mealByKey/${key}`, "GET", setErrorText);
        if("error" in response) return;
        
        let newMeal = {"name": response.meal.mealName, "key": key, "servings": servings}
        if(type === "meal"){
            setMeals([...meals, newMeal]);
        }
        else if(type === "snack"){
            setSnacks([...snacks, newMeal]);
        }
        
        const result = await executeCall(`plans/evaluate/${goals.goalKey}/${key}`, "GET", setErrorText);
        if("error" in result) return;
    }

    function edit(mealKey, servings, type){
        let newMeals = null;
        if(type === "meal")
            newMeals = [...meals];
        else
            newMeals = [...snacks];
        
        if(servings !== 0){
            let toEdit = newMeals.find(meal => meal.key === mealKey);
            toEdit.servings = servings;
            newMeals = newMeals.filter(meal => meal.key !== mealKey);
            newMeals.push(toEdit);
        } else 
            newMeals = newMeals.filter(meal => meal.key !== mealKey);

        if(type === "meal")
            setMeals(newMeals);
        else
            setSnacks(newMeals);
    }

    useEffect(() => {
        console.log(selectedDate);
    }, [selectedDate]);

    return (
        <div>
            <Link to = {PathConstants.HOME}>
                <Icon image = "back" click = {() => {return}} />
            </Link>
            <div className = "d-flex align-items-center flex-column">
                {latestDate != null && <DateInfo latestDate={latestDate} selectedDate={selectedDate} setSelectedDate={(e) => setSelectedDate(e)} />}
                <h4 className = "mt-3">Let's begin building your schedule!</h4>
                <h5>You still have to plan for {remainingMeals} meals.</h5>
                <Button onClick = {() => setMealModal(true)}>Find new foods</Button>
                {mealModal && 
                    <MealSelectModal 
                        userKey = {userKey}
                        close = {() => setMealModal(false)} 
                        addMeal = {(key, servings, name) => addItem(key, servings, name, "meal")}/>
                    }
                {meals.map((meal) => (
                    <ServingsDisplay 
                        servings = {meal.servings} 
                        mealName = {meal.name} 
                        edit = {(servings) => edit(meal.key, servings, "meal")}
                        select = {() => setSelectedMeal(meal.key)}/>
                ))}
                <h5>You still have to plan for {remainingSnacks} snacks.</h5>
                <Button onClick = {() => setSnackModal(true)}>Find new foods</Button>
                {snackModal && 
                    <MealSelectModal 
                        userKey = {userKey}
                        close = {() => setSnackModal(false)}
                        addMeal = {(key, servings, name) => addItem(key, servings, name, "snack")}/>}
                {snacks.map((meal) => (
                    <ServingsDisplay 
                        servings = {meal.servings} 
                        mealName = {meal.name} 
                        edit = {(servings) => edit(meal.key, servings, "snack")}
                        select = {() => setSelectedMeal(meal.key)}/>
                ))}

                {selectedMeal != false && <MealModal mealKey={selectedMeal} close = {() => setSelectedMeal(false)}/>}

                {(remainingMeals == 0 && remainingSnacks === 0) && (
                    <Button onClick = {() => createScheduleFromMeals()}>Submit</Button>
                )}
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './onboard.css'; // CSS file for animations
import { decodeGoal, decodeProtein, decodeMacroBalance } from './constants';
import Icon from '../tools/Icon';
import { transform } from 'framer-motion';
import PrimaryButton from '../Components/PrimaryButton.js';


const activityLevels = ["Sedentary", "Low Activity", "Moderately Active", "Active", "Extremely Active"];

const Onboard_Summary = ({ formState, onSubmit, prev }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulating a delay for loading animation
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container className={`summary-container p-0 m-0${isLoaded ? 'loaded' : ''}`} style = {{backgroundColor: "#212121", color: "whitesmoke", overflowX: "hidden"}} fluid>
      <h1 className="text-center mt-4 mb-4">Summary</h1>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header style = {{backgroundColor: "#212121"}}>
              <h3 className="mb-0" style = {{fontFamily: "Koulen", fontWeight: "normal"}}>Bio Information</h3>
            </Card.Header>
            <Card.Body style = {{backgroundColor: "#212121"}}>
              <p className="mb-1 summary-text"><strong>Height:</strong> {formState.BioInfo.heightUnit == "in" ? formState.BioInfo.heightValue + 57 : formState.BioInfo.heightValue} {formState.BioInfo.heightUnit}</p>
              <p className="mb-1 summary-text"><strong>Weight:</strong> {formState.BioInfo.weightValue} {formState.BioInfo.weightUnit}</p>
              <p className="mb-1 summary-text"><strong>Age:</strong> {formState.BioInfo.ageValue}</p>
              <p className="mb-0 summary-text"><strong>Gender:</strong> {formState.BioInfo.genderValue === "M" ? "Male" : "Female"}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header style = {{backgroundColor: "#212121"}}>
                <h3 className="mb-0" style = {{fontFamily: "Koulen", fontWeight: "normal"}}>Lifestyle Information</h3>
            </Card.Header>
            <Card.Body style = {{backgroundColor: "#212121"}}>
              <p className="mb-1 summary-text"><strong>Activity Level:</strong> {activityLevels[formState.LifestyleInfo.activity]}</p>
              <p className="mb-0 summary-text"><strong>Variety:</strong> {formState.LifestyleInfo.variety ? "Yes" : "No"}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {process.env.FEATURE_SCHEDULE && (
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Header style = {{backgroundColor: "#212121"}}>
                    <h3 className="mb-0" style = {{fontFamily: "Koulen", fontWeight: "normal"}}>Schedule Information</h3>
                </Card.Header>
                <Card.Body style = {{backgroundColor: "#212121"}}>
                  <p className="mb-1 summary-text"><strong>Days between meal prep days:</strong> {formState.ScheduleInfo.customMeals.split("|").length + 1}</p>
                  <p className="mb-0 summary-text"><strong>Meals per day:</strong> {formState.ScheduleInfo.customMeals.split("|").every((element, _, arr) => element === arr[0]) ? formState.ScheduleInfo.customMeals.split("|")[0].split("M").length - 1 : "Varies"}</p>            
                </Card.Body>
              </Card>
            </Col>
          </Row>
      )}

      {/*
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Header className="bg-info text-white">
                  <h4 className="mb-0">Goals Information</h4>
                </Card.Header>
                <Card.Body>
                  <p className="mb-1 summary-text"><strong>Goal:</strong> {decodeGoal(formState.GoalsInfo.goal)}</p>
                  <p className="mb-1 summary-text"><strong>Protein:</strong> {decodeProtein(formState.GoalsInfo.protein)}</p>
                  <p className="mb-0 summary-text"><strong>Macro Balance:</strong> {decodeMacroBalance(formState.GoalsInfo.macroBalance)}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
      */}

      <div className="text-center">
        <div className = {{transform: "rotate(180deg)"}} className="flex d-flex align-items-center justify-content-center">
          <Icon image="next" size="50px" click={prev} />
          <PrimaryButton className = "ml-3" onClick={onSubmit} text = "SUBMIT">Submit</PrimaryButton>

        </div>
      </div>
    </Container>
  );
};

export default Onboard_Summary;

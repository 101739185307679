import { Button } from "react-bootstrap";
import {useState} from 'react';

export default function PrimaryButton({className, text, onClick, active = false}) {
    const [hover, setHover] = useState(false);
    
    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    return (
        <Button className = {className + " montserrat-font"} 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick} 
            style = {{backgroundColor: '#F5F5F5',
                color: "#000000", 
                borderRadius: "10px", 
                border: !hover ? "5px solid #F5F5F5" : "5px solid #7DB6A3",
                boxShadow: !hover ? "" : "0px 4px 10px 4px #F5F5F5"}}>
            {text}
        </Button>
    )
}
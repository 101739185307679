//import SingleSchedule from "./SingleSchedule"
import {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Modal} from 'react-bootstrap';
import ChangeScheduleSettings from './ChangeScheduleSettings.js';
import moment from "moment";
import Icon from "../tools/Icon";
import ScheduleSettingsDisplay from "./ScheduleSettingsDisplay.js";
import PlannedDay from "./PlannedDay.js";
import ScheduleList from './ScheduleList.js';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import AlertModal from "../tools/AlertModal.js";

import ErrorContext from '../utils/ErrorContext.js';
import executeCall from '../utils/APICall.js';
import PathConstants from '../tools/PathConstants.js';
import CreateSchedule from './CreateSchedule.js';
import SessionContext from '../utils/SessionContext.js';
import PrimaryButton from '../Components/PrimaryButton.js';
import SecondaryButton from '../Components/SecondaryButton.js';
import { CustomModal } from '../Components/CustomModal.js';
import ShoppingList from '../shopping/ShoppingList.js';

function SettingsModal({userKey, close, setSettingsKey}) {
    return (
        <div className="modal show" style={{ display: 'block' }}>
            <Modal.Dialog style={{ maxWidth: '90vw' }} scrollable>
                <Modal.Header>
                    <Modal.Title>Schedule Settings</Modal.Title>
                    <img style = {{height: "20px", marginTop: "10px", marginLeft: "10px", float: "right"}}
                        alt = "x" 
                        src={process.env.PUBLIC_URL + "/images/x.png"} onClick={() => close()}/>
                </Modal.Header>

                <Modal.Body>
                    <ChangeScheduleSettings signup = {false} userKey = {userKey} setSettingsKey={(e) => {setSettingsKey(e); close()}}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => close()}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}

function CreateScheduleModal({close}){
    return (
        <div className="modal show" style={{ display: 'block' }}>
            <Modal.Dialog style={{ maxWidth: '90vw' }} scrollable>
                <Modal.Header>
                    <Modal.Title>Schedule Settings</Modal.Title>
                    <img style = {{height: "20px", marginTop: "10px", marginLeft: "10px", float: "right"}}
                        alt = "x" 
                        src={process.env.PUBLIC_URL + "/images/x.png"} onClick={() => close()}/>
                </Modal.Header>

                <Modal.Body>
                    <CreateSchedule />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => close()}>Cancel</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}

function isToday(date) {
    const useDate = new Date(date)
    const today = new Date()
    return useDate.getFullYear() === today.getUTCFullYear() &&
    useDate.getMonth() === today.getUTCMonth() &&
    useDate.getDate() === today.getUTCDate();
}

export default function Schedules({username, goals = null}){
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [settings, setSettings] = useState(null);

    const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);
    
    const [showingDayKey, setShowingDayKey] = useState(null);
    const [dayIndex, setDayIndex] = useState(null);

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const [db_days, setDB_days] = useState(null);
    const [days, setDays] = useState(null);

    const [confirmMessage, setConfirmMessage] = useState(null);
    const [confirmFunction, setConfirmFunction] = useState(null);

    const [showShopping, setShowShopping] = useState(false);

    const {errorText, setErrorText} = useContext(ErrorContext);
    const {userKey} = useContext(SessionContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(confirmMessage === null)
            setConfirmFunction(null);
    }, [confirmMessage])

    useEffect(() => {
        console.log("settings changed " + settings);
    }, [settings])

    useEffect(() => {

        if(db_days != null){
            let newDays = db_days.sort((a, b) => a.date - b.date);
            newDays = newDays.map((item, index) => ({
                ...item, // Spread operator to keep existing properties
                index    // Add index property
            }));

            if(currentSchedule == null){
                for(let day of newDays){
                    if(isToday(day.date)){
                        setDayIndex(day.index - 1);
                        setShowingDayKey(day.dayKey);
                    }
                }
            } else {
                setShowingDayKey(db_days[0].dayKey)
            }

            console.log(newDays);
            setDays(newDays)
        }
    }, [db_days])

    useEffect(() => {
        if(dayIndex != null){
            console.log(days);
            let newDayKey = days.find(item => item.index === dayIndex);
            if(newDayKey != null)
                setShowingDayKey(newDayKey.dayKey);
        }
    }, [dayIndex])

    const getSchedule = async(key = null) => {
        let response;
        if(key == null){
            response = await executeCall(`plans/currentSchedule/${userKey}`, "GET", setErrorText);
        } else {
            response = await executeCall(`plans/schedule/${currentSchedule}`, "GET", setErrorText);
        }
        if("error" in response) return;

        if(response.message === "NOSCHEDULE"){
             setCurrentSchedule(null);
        } else if (response.message == "SCHEDULEFOUND"){
            console.log(response);
            setDB_days(response.days);
        }
    }

    function resetSchedule(){
        const reset_schedule = () => {
            return () => navigate("/createSchedule")
        }
        setConfirmFunction(reset_schedule);
        setConfirmMessage("Are you sure you want to reload your schedule?")
    }


    useEffect(() => {
        console.log("new schedule clicked");
        getSchedule(currentSchedule);
    }, [currentSchedule])
    

    return (
        <Container fluid className = "" style = {{backgroundColor: "#212121", height: "100%"}}>
            <Row className = "flex d-flex flex-direction-column align-items-center justify-content-center">
                <Col className = "flex d-flex flex-column align-items-center justify-content-center">
                    <div className = "flex d-flex flex-direction-row justify-content-center align-items-center">
                        {currentSchedule == null && <h2 className = "mb-3 mt-3 montserrat-font" style = {{color: "whitesmoke"}} >YOU DO NOT CURRENTLY HAVE A MEAL PLAN.</h2>}
                        {currentSchedule != null && <h2 className = "mb-3 mt-3 montserrat-font" style = {{color: "whitesmoke"}} >YOUR CURRENT SCHEDULE ENDS ON <u>SAMPLE DATE</u></h2>}
                        {currentSchedule != null && <Icon image = "refresh"  className = "mt-3 mb-3 ml-3" size = "25px" click = {() => resetSchedule(resetSchedule)} />}
                    </div>
                </Col>
            </Row>
            <Container>
                {currentSchedule == null && (
                    <Row className = "flex d-flex flex-direction-column align-items-center justify-content-center">
                        <Col className = "flex d-flex flex-column align-items-center justify-content-center">
                            {settings != null && (
                                <PrimaryButton>
                                        Create a new schedule
                                </PrimaryButton>
                            )}
                            <PrimaryButton className = {"w-100"} text = {"SET UP A NEW MEAL SCHEDULE"} onClick = {() => setShowCreateScheduleModal(true)}/>
                            <SecondaryButton className = {"w-100"} text = {"CALENDAR"} />
                            <PrimaryButton className = {"w-100"} text = {"SHOPPING LIST"} onClick={() => setShowShopping(true)}/>
                            <SecondaryButton className = {"w-100"}text = {"COOKBOOK"} />
                        </Col>
        
                    </Row>

                )}
                <Row>
                    <ScheduleSettingsDisplay userKey = {userKey} scheduleSettingsKey = {settings} setShowSettingsModal={() => setShowSettingsModal(true)}/>
                </Row>
            </Container>


            {(currentSchedule !== null && days !== null) && (
                <>
                    <PlannedDay dayKey = {showingDayKey} goals = {goals}/>
                    <Row className = "d-flex justify-content-center">
                        {dayIndex !== 0 && <Icon className = "ml-3 mr-3" image = "back" click = {() => setDayIndex(dayIndex - 1)}></Icon>}
                        {dayIndex !== days.length - 1 && <Icon image = "forward" click = {() => setDayIndex(dayIndex + 1)} className = "transform rotate-180" />}
                    </Row>

                </>

            )}
            {showSettingsModal && <CustomModal modalBody = {
                <ChangeScheduleSettings signup = {false} userKey = {userKey} setSettingsKey={(e) => {setSettings(e); setShowSettingsModal(false)}} reload = {(e) => setSettings(e)}/>
            } userKey = {userKey} close = {() => setShowSettingsModal(false)} setSettingsKey = {(e) => setSettings(e)}/>}
            {confirmMessage !== null && <AlertModal alertType = "Warning" text = {confirmMessage} confirm = {confirmFunction} close = {() => setConfirmMessage(null)}/>}
            {showCreateScheduleModal && <CreateScheduleModal close = {() => setShowCreateScheduleModal(false)}/>}
            {showShopping && <CustomModal modalBody = {
                <ShoppingList userKey = {userKey}/>} close = {() => setShowShopping(false)}/>
            }
        </Container>
    )

}
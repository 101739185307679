// formDataDefaults.js

export const UserInfo = {
  userKey: "",
  email: "",
  password: ""
};

export const BioInfo = {
  heightValue: 10,
  heightUnit: "in",
  weightValue: "",
  weightUnit: "lbs",
  ageValue: 25,
  genderValue: 'M'
};

export const LifestyleInfo = {
  activity: 1,
  variety: false
};

export const GoalsInfo = {
  goal: 0, // -3 to 3
  protein: 1, // 1 to 3
  macroBalance: 2 // 0 to 4
};

export const initialFormData = {
  UserInfo,
  BioInfo,
  LifestyleInfo,
  GoalsInfo
};
import {useState, useEffect, useContext} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import MealModal from './Modals/MealModal.js'
import {ScheduledMeal} from './ScheduledMeal.js'
import DayNutrients from './DayNutrients.js';

import executeCall from '../utils/APICall.js'
import ErrorContext from '../utils/ErrorContext.js'
import SessionContext from '../utils/SessionContext.js';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];


export default function PlannedDay({dayKey}){
    const [meals, setMeals] = useState(null);
    const [date, setDate] = useState(null);
    const [nutrients, setNutrients] = useState(null);

    const [openMeal, setOpenMeal] = useState(null);

    const {goals, setGoals} = useContext(SessionContext);

    const {errorText, setErrorText} = useContext(ErrorContext);

    const getDay = async() => {
        const response = await executeCall(`plans/day/${dayKey}`, "GET", setErrorText);

        const unformatted_date = new Date(response.day.date);
        const month = months[unformatted_date.getMonth()];
        const day = unformatted_date.getDate();
        const year = unformatted_date.getFullYear();
        console.log(response);
        setDate(`${month} ${day}, ${year}`);
        setMeals(response.dayMeals);
        setNutrients(response.nutrients);
    }

    useEffect(() => {
        if(dayKey != null && dayKey !== "")
            getDay()
    }, [dayKey])
    
    return (
        <Container style = {{height: "100%", border: "3px solid black", borderRadius: "5px"}}>
                <Row className = "flex d-flex flex-direction-row align-items-center" style = {{backgroundColor: "#dddddd",borderBottom: "2px solid gray" }}>
                    <h3 style = {{paddingLeft: "15px", paddingTop: "15px", marginRight: "15px"}} >
                        {date != null && date}
                    </h3>
                </Row>

                
                <Row className = "pb-3" style = {{backgroundColor:"#eeeeee"}}>
                    <Col>
                        {/** Meals **/}
                        <h4 className = "mt-3"><u>Meals</u></h4>
                        {meals !== null && meals.filter(item => item.mealType === "meal").map((item, index) => (
                            <ScheduledMeal meal = {item.Meal} servings = {item.servings} setOpenMeal = {() => setOpenMeal(item.mealKey)}/>
                        ))}
                         <h4 className = "mt-3"><u>Snacks</u></h4>
                        {meals !== null && meals.filter(item => item.mealType === "snack").map((item, index) => (
                            <ScheduledMeal meal = {item.Meal} servings = {item.servings} setOpenMeal = {() => setOpenMeal(item.mealKey)} />
                        ))}
                        
                    </Col>
                </Row>
                {nutrients !== null && (
                    <Row>
                        <DayNutrients nutrients = {nutrients} goals = {goals} />

                    </Row>
                )}
            {openMeal != null && <MealModal mealKey = {openMeal} close = {() => setOpenMeal(null)} />}
            {/*openSelection != null && <MealSelectModal token = {token} userKey = {userKey} close = {() => setOpenSelection(null)} addMeal = {(key, name) => addMeal(key, name)}/>*/}
    
            </Container>
    )
}
const PathConstants = {
    HOME: "/",
    INGREDIENTS: "/ingredients",
    MEALS: "/meals",
    SCHEDULES: "/schedules",
    SHOPPING: "/shopping",
    PRODUCTS: "/products",
    LOGIN: "/login",
    CREATESCHEDULE: "/createSchedule",
    SIGNUP: "/signup",
    PROFILE: "/profile"
}

export default PathConstants;